.with-sale-banner {
  .home-banner-container {
    top: @header-height-sm;
  }
  .home-banner {
    background: @new-light-gray;
  }
}
.home-banner-container {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 27;
  top: 0;
  height:@header-message-height;
  width: 100%;
}
.home-banner {
  text-align: center;
  vertical-align: middle;
  background: #F4F4F4;
  color: white;
  height:@header-message-height;
  min-height:@header-message-height;
  @media (max-width:768px) {
    height: auto;
  }
  p {
    display: inline-block;
    padding: 6px;
    margin: 0;
    vertical-align: middle;
    .font-sans-serif(@font-size-small);
    // .make-responsive-font-size(@font-size-small,@font-size-small,@font-size-small,@font-size-x-small);
    text-transform: uppercase;
    letter-spacing: 0.1em;

    a,
    .a:link,
    .a:visited,
    .a:hover,
    .a:active {
      text-decoration: none;
      color: #7E7A73;
    }
  }
}