.ui-view-container {
  z-index: auto;
  text-rendering: geometricPrecision;
}
.influencer-page {
  .show-on-click {
    position: absolute;
    z-index: 2;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }
  .influencer-container {
    background-position: top;
    background-size: cover;
    height: 673px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .influencer-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    width: 450px;
    background: #ffffff9e;
    margin-bottom: 20px;
    @media (max-width: 480px) {
      margin-bottom: 0;
      h1 {
        margin-bottom: 0;
      }
    }

  }
  .vip-icon {
    position: relative;
    img.vip {
      position: absolute;
      right: -5px;
      top: 10px;
    }
  }
  #giveaway-modal {
    position: fixed;
    top: 20vh;
    right: 0;
    bottom: 20vh;
    left: 0;
    margin: auto;
    .modal-dialog {
      margin: 0 auto;
    }
    .modal-body {
      position: relative;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      height: calc(60vh - 2rem);
      h1 {
        color: @diamond-blue-dark;
        font-style: italic;
      }
      .container-options {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
        .options {
          text-align: center;
          font-size: 14px;
          line-height: 1.5;
          width: 50%;
          position: relative;
          align-self: flex-start;
          padding: 0 15px;
          img {
            left: 0;
            right: 0;
            position: absolute;
            margin: auto;
            top: -55px;
            opacity: 0.8;
          }
        }
      }
    }
    p {
      line-height: 1;
      small {
        font-size: 75%;
      }
    }
    button {
      background-color: @brand-main-color;
      margin: 10px;
    }
  }
  .social-wrapper {
    img {
      margin: 12px 5px;
    }
  }
  button.the-mile {
    border-radius: 3rem;
    padding: 5px 30px;
    background-color: @brand-gray-color;
    margin: 10px;
    color: #333;
  }
  h1 {
    font-size: 45px;
    margin-top: 0;
  }
  .avatar {
    border-radius: 50%;
    border: 1px solid white;
    margin-top: 15px;
    height: 70px;
    width: 70px;
  }
  .brand-stitch-the-mile {
    display: none;
    // margin: 5px !important;
  }
  .influencer-show,
  .influencer-show-interview {
    display: flex;
    margin: 0;
    justify-content: space-between;
    margin-top: 7rem;
    .show-detail {
      width: 98%;
      display: flex;
      img {
        width: 100%;
      }
    }
    .show-info {
      .make-responsive-width(60%, 60%, 55%, 100%);
      overflow: auto;
      height: 100%;
      padding: 2.5rem 1rem 2rem 2.5rem;
      background-color: @brand-gray-color;
      @media (max-width: 767px) {
        background-color: @brand-gray-light;
      }
      h5 {
        padding-top: 15px;
        .font-sans-serif-bold;
      }
      h6 {
        .font-sans-serif;
        color: @brand-main-color;
        margin-top: 0;
      }
      p {
        .font-sans-serif;
        font-style: italic;
        color: @brand-watermark;
        line-height: 1;
      }
      .product-list {
        height: auto;
        margin-right: 0;
        margin-left: 0;
      }
      .product-container {
        margin: 5px 0;
        display: flex;
        align-items: center;
      }
      .product-img {
        width: 55px;
        height: 72px;
        overflow: hidden;
        border-radius: 4px;
        margin-right: 8px;
        img {
          min-height: auto;
          width: 100%;
          margin-top: -5px;
          cursor: pointer;
        }
      }
      .product-detail {
        width: 68%;
        line-height: 1.2;
        h4 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 15px;
          font-style: bold;
          cursor: pointer;
        }
        span {
          .font-sans-serif;
          font-style: normal;
          color: @brand-gray-dark;
          font-size: 78%;
        }
        a p {
          .font-sans-serif-bold;
          font-size: 75%;
          letter-spacing: 1px;
          color: @brand-main-color;
        }
      }
      /* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: #f3f3f3;
      }

      &::-webkit-scrollbar:vertical {
        width: 7px;
      }

      &::-webkit-scrollbar-button:increment,
      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar:horizontal {
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e6e6e6;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
    }
  }
  .the-mile-shows-carousel .show-info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .product-list {
      max-height: 80%;
      height: inherit;
      overflow: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        background-color: #f3f3f3;
      }

      &::-webkit-scrollbar:vertical {
        width: 7px;
      }

      &::-webkit-scrollbar-button:increment,
      &::-webkit-scrollbar-button {
        display: none;
      }

      &::-webkit-scrollbar:horizontal {
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e6e6e6;
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
    }
  }
  .profile-img {
    width: 20%;
    background-size: cover;
    background-position: center;
  }
  .profile-info {
    width: 79%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 5rem;
    z-index: 1;
    p {
      color: @brand-gray-dark;
      text-align: center;
      z-index: 1;
    }
    img {
      position: absolute;
      z-index: 0;
      &.top {
        top: 1rem;
        left: 1rem;
      }
      &.bottom {
        bottom: 1rem;
        right: 1rem;
      }
    }
  }
  h5 {
    .font-sans-serif-bold;
    margin-bottom: 2rem;
  }
  .status.influencer-page {
    top: 0;
    left: 0;
    font-size: 75%;
    position: relative;
    width: fit-content;
  }
  .influencer-product-carousel {
    color: @new-light-gray;
    padding: 25px 0;
    h5 {
      text-transform: uppercase;
      margin-bottom: 1rem;
      letter-spacing: 1.5px;
    }
    p {
      .font-sans-serif-italic;
      color: @brand-watermark;
    }
  }
  .influencer-products-carousel {
    margin-top: 0;
    .quick-view-band {
      display: none;
    }
    .product-list-item .product-info {
      line-height: 1.2;
      padding-top: 8px;
    }
  }

  .slick-list {
    box-sizing: border-box;
    a:focus {
      outline: 0 auto transparent !important;
    }
    .slick-slide .influencer-check img {
      width: 24px;
    }
  }

  @media (max-width: 767px) {
    .influencer-show,
    .influencer-show-interview {
      flex-direction: column;
      .show-detail {
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        flex-direction: column;
        padding: 12px;
      }
    }
    .profile-img {
      width: 100%;
      height: 550px;
      background-position: center;
    }
    .influencer-show,
    .influencer-show-interview {
      margin-top: 4rem;
    }
    .profile-info {
      width: 100%;
    }
    .btn-product-show {
      position: absolute;
      z-index: 3333333;
      background: #ffffffd6;
      bottom: 0;
      right: 10px;
      padding: 10px 10px 2px;
      line-height: 0.9;
      color: @brand-gray-dark;
      width: 100px;
      border-radius: 8px 8px 0 0;
      .font-sans-serif;
      font-size: 11px;
      span.icon:before {
        content: "\e595";
        font-family: 'Flaticons Stroke';
        speak: none;
        font-style: normal;
        font-weight: normal;
        line-height: 1.2;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
      }
      // ::before {
      //   font-family: "Flaticons Solid";
      //   speak: none;
      //   font-style: normal;
      //   line-height: 1;
      //   -webkit-font-smoothing: antialiased;
      //   font-size: 15px;
      //   font-weight: normal;
      //   position: relative;
      //   content: "\e1c1";
      // }
      span.number{
        width: 20px;
        height: 20px;
        letter-spacing: 0px;
        background-color: @brand-main-color;
        border-radius: 16px;
        text-align: center;
        font-family: 'OMBR',Arial,sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
        display: inline-block;
        position: absolute;
        right: -6px;
        top: -6px;
      }
      span:focus-visible {
        outline-color: red;
        outline-style: auto;
        outline-width: 5px;
      }
    }
  }

  // Tablet View
  @media (min-width: 480px) and (max-width: 768px) {
    .influencer-show-interview {
      margin: 4rem auto;
      max-width: 450px;
      .show-detail {
        max-width: 450px;
        margin: 15px auto;
      }
    }
    .the-mile-shows-carousel {
      max-width: 500px;
    }
    .the-mile-dashboard.the-mile-shows-carousel {
      max-width: 90%;
    }
  }

  .giveaway {
    position: relative;
    margin-top: 5.5rem;
    img {
      position: absolute;
      &.giveaway-img {
        left: -50px;
        top: -50px;
        max-width: 320px;
        &.img02 {
          right: 0;
          left: auto;
        }
      }
    }
    h3 {
      color: @brand-main-color;
      .font-sans-serif-bold;
    }
    .bg-gray {
      background-color: @brand-gray-color;
      padding: 3rem;
      margin-bottom: 20px;
      p {
        font-size: 14px;
      }
    }
    @media (max-width: 1199px) {
      img.giveaway-img {
        left: 0;
        right: auto;
        position: relative;
        width: 49%;
        margin: auto;
        margin-top: -90px !important;
        top: 0;
      }
      .bg-gray {
        padding: 2rem;
        margin-top: 3rem;
      }
    }
    a:hover {
      color: inherit;
      h5:hover {
        color: @brand-main-color;
      }
    }
  }

  .brand-container {
    margin-top: 5rem;
    background-color: @brand-gray-color;
    display: flex;
    position: relative;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);

    .image-wrapper {
      flex: 1;
    }
    .brand-wrapper {
      padding: 20px;
      position: relative;
      flex-direction: column;
      display: flex;
      padding: 15px;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      .brand-text {
        width: 100%;
        .font-sans-serif;
        .small;
      }
      button {
        cursor: pointer;
      }
      brand-logo svg {
        width: 50%;
        height: auto;
        path {
          fill: black;
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  //SLIDER ARROWS
  .prevArrow,
  .nextArrow {
    color: @brand-gray;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    text-shadow: none;
    width: 15px;
    height: 15px;
    .make-responsive-font-size(57px, 57px, 40px, 40px);
    opacity: 0.7;
    text-align: center;

    &::before {
      border-top: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      position: absolute;
      width: 100%;
      left: 0px;
    }

    &::after {
      border-left: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      height: 100%;
      width: 0px;
      position: absolute;
      top: 0px;
    }

    &:hover {
      color: #b8b8b8;
      opacity: 0.9;
    }
  }

  .prevArrow {
    right: auto;
    left: -60px;
    -webkit-transform: rotate(-45deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(-45deg);
    /* IE 9 */
    transform: rotate(-45deg);

    /* Firefox 16+, IE 10+, Opera */
    @media (max-width: @screen-xs-max) {
      left: -30px;
    }
  }

  .nextArrow {
    left: auto;
    right: -60px;
    -webkit-transform: rotate(135deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(135deg);
    /* IE 9 */
    transform: rotate(135deg);

    /* Firefox 16+, IE 10+, Opera */
    @media (max-width: @screen-xs-max) {
      right: -30px;
    }
  }

  .show-quick-view-modal .modal-dialog {
    width: 100vw;
    height: 100% !important;
    margin: 0;
  }
  // Accordion section - Influencer product list
  .influencer-accordion {
    background-color: @brand-gray;
    display: flex;
    cursor: pointer;
    flex-flow: wrap;
    align-items: flex-start;
    margin: 28px auto 70px auto;
    height: 254px;
    cursor: pointer;
    @media (max-width: @screen-xs-max) {
      flex-flow: wrap;
      .brand-stitch.center {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
    & > div {
      display: flex;
      flex: 1 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      &.title {
        width: 28%;
        padding: 0 42px;
        @media (max-width: @screen-xs-max) {
          width: 50%;
          height: 55%;
          padding: 30px 10px 10px 25px;
        }
      }
      &.product {
        width: 25%;
        padding: 0;
        justify-content: flex-start;
        img {
          width: auto;
          height: 327px;
        }
      }
      &.image {
        width: 29%;
        padding: 109px 19px 0;
        @media (max-width: @screen-xs-max) {
          width: 50%;
          height: 55%;
        }
        img {
          width: 100%;
        }
      }
      &.icon {
        width: 18%;
        padding: 10px;
        @media (max-width: @screen-xs-max) {
          width: 50%;
          height: auto;
          padding: 30px 10px 10px 25px;
          flex: inherit;
        }
      }
      span {
        .font-sans-serif-bold;
        color: #5d5d5d;
        word-spacing: 1px;
        font-size: 10px;
        min-width: 100px;
        text-align: center;
      }
    }
    .icons {
      min-width: 30px;
      height: 30px;
      border-radius: 55px;
      display: flex;
      border: 1px solid #5d5d5d;
      align-items: center;
      justify-content: center;
      &::before {
        font-family: "Flaticons Solid";
        speak: none;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
        font-weight: normal;
        position: relative;
        content: "\e1c0";
      }
    }
    .click-title {
      color: #919191;
      &::before {
        position: relative;
      }
    }
    .brand-stitch.center {
      min-height: 8px;
    }
    &#accordion-set2,
    &#accordion-set4 {
      justify-content: flex-end;
    }
  }
  .influencer-accordion.collapsed{
    .icons {
      min-width: 30px;
      height: 30px;
      border-radius: 55px;
      display: flex;
      border: 1px solid #5d5d5d;
      align-items: center;
      justify-content: center;
      &::before {
        font-family: "Flaticons Solid";
        speak: none;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 15px;
        font-weight: normal;
        position: relative;
        content: "\e1c1";
      }
      &.close {
        &::before {
          content: "\e1c0";
        }
      }
    }
    .click-title {
      color: #919191;
      &::before {
        position: relative;
      }
    }
  }
  .influencer-accordion-list {
    .product-list {
      margin-right: 0;
      margin-left: 0;
    }
    width: 98%;
    margin: 0 auto;
    .product-list .product-item-container {
      .make-responsive-width(25%,25%, 33.33%, 50%);
      display: inline-block;
      .product-list-item {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .product-info {
        line-height: 1;
        @media (max-width: 767px) {
          line-height: 1.2;
          padding-top: 6px;
        }
      }
    }
  }
  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 2rem;
    margin-top: 20px;
    position: relative;
    .bg-video {
      background-color: @button-color-hover;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30vh;
      color: white;
      display: flex;
      flex-direction: row;
      align-items: end;
      justify-content: center;
      .left {
        text-align: right;
        img {
          margin-top: -20vh;
          position: relative;
          width: 90px;
        }
        h3 {
          .font-sans-serif-bold;
        }
        span.name {
          font-weight: lighter;
          .font-serif-italic(21px);
          letter-spacing: 1px;
          display: block;
          margin-bottom: 36px;
        }
      }
      .middle {
      }
      .right {
        text-align: left;
        p {
          font-style: italic;
          margin-bottom: 36px;
          padding-right: 15px;
        }
      }
      .left,
      .right,
      .middle {
        flex: 1;
        padding: 10px;
      }
      @media (min-width: 1200px) {
        width: 87%;
      }
      @media (max-width: 991px) {
        flex-direction: column;
        justify-items: end;
        justify-content: space-between;
        align-items: flex-start;
        bottom: -4vh;
        height: auto;
        position: relative;

        .left,
        .right,
        .middle {
          text-align: left;
          padding: 0 20px;
          img {
            margin-top: -6vh;
          }
        }
        span.name {
          margin-bottom: 0;
        }
      }
    }
    video {
      height: 70vh;
      max-height: 550px;
      cursor: pointer;
      font-family: "Flaticons Solid";
      z-index: 2;
      &::before {
        content: "\e029";
      }
    }
    .mobile-title {
      text-align: center;
      img {
        margin-top: 0;
        position: relative;
        width: 90px;
      }
      h3 {
        .font-sans-serif-bold;
      }
      span.name {
        font-weight: lighter;
        .font-serif-italic(21px);
        letter-spacing: 1px;
        display: block;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      padding-top: 0;
      .name {
        position: relative;
        top: 0;
        margin-bottom: 10px;
      }
    }
  }
  #influencer-pages {
    .slick-track {
      margin: 0 auto;
    }
    .container-btn {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 23;
      @media (max-width: 480px) {
        height: 79vh;
        bottom: 0;
      }
      &.big-play-button .action-button {
        z-index: 4444;
        width: 50px;
        position: absolute;
        height: 50px;
        border-radius: 50%;
        background: url("../img/icons/play.svg") center no-repeat #ffffff54;
      }
      &.big-pause-button .action-button {
        z-index: 4444;
        width: 50px;
        position: absolute;
        height: 50px;
        border-radius: 50%;
        background: url("../img/icons/pause.svg") center no-repeat #ffffff54;
        animation: 2s ease 2s normal forwards 1 fadein;
        -webkit-animation: 2s ease 2s normal forwards 1 fadein;
      }

      @keyframes fadein {
        0% {
          opacity: 0.9;
        }
        50% {
          opacity: 0.3;
        }
        100% {
          opacity: 0;
        }
      }

      @-webkit-keyframes fadein {
        0% {
          opacity: 0.9;
        }
        50% {
          opacity: 0.3;
        }
        100% {
          opacity: 0;
        }
      }
    }
    .the-mile-shows-carousel {
      width: 100%;
      img.show-img {
        aspect-ratio: .5625;
        object-fit: cover;
        height: auto;
        min-height: auto;
        width: 100%;
      }
      .show-info {
        padding: 1rem;
        position: relative;
        .product-list {
          max-height: 100%;
          height: 270px;
          flex: 0 auto;
          position: relative;
          @media (min-width: @screen-sm-min) {
            overflow: auto;
            max-height: 53%;
            flex: 0 auto;
            position: absolute;
            width: 93%;
            bottom: 10px;
          }
        }
      }
      .play-wrapper {
        position: relative;
        .make-responsive-width(45%, 45%, 50%, 100%);
        .play-icon {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background: #0000005c;
          border-radius: 50px;
          height: 50px;
          width: 50px;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          img.play {
            width: 17px;
            height: 17px;
            object-fit: contain;
            opacity: 0.8;
          }
        }
        @media (max-width: @screen-xs-max) {
          height: 500px;
          img.show-img {
            height: 500px;
          }
        }
      }
      @media (max-width: 767px) {
        .prevArrowCarouselItems, .nextArrowCarouselItems {
          top: 27%;
        }
      }
    }
  }
}
