.with-sale-banner {
  .search-preview {
    top: @header-height!important;
  }
}

.search {
  .container();
  .criteria {
    .make-xs-column(12);
    padding: 0;
    .search-term {
      border: 0;
      .make-responsive-font-size(17px, 17px, 17px, 17px);
      margin: 0 auto;
      padding: 5px 15px;
      width: 100%;
      text-align: center;
      outline: 1px;
      outline-offset: 0px;
      line-height: normal;
      background-color: transparent;
    }
  }
}
