.admin {
  .curated-product {
    background-color: #999;
    color:#FFF;
    padding:3px;
  }
  .sortable-products-container {
    .product-list();
    height: 500px;
    overflow: scroll;
    div.curated {
      display: none;
    }
  }
  .save-button-container {
    clear:both;
  }
  .save-button-fixed-container {
    position:fixed;
    top: 600px;
    left: 1150px;
    z-index:8;
  }
  .div-box {
    border: 1px solid #e0e0e0;
    background-color: #f5f5f5;
    padding:5px;
    min-height: 34px;
    max-height: 102px;
    overflow: scroll;
  }
  .category-fixed-container {
    width:180px;
    position:fixed;
  }
  .showcase-fixed-container, .wear-it-with-fixed-container {
    // width:500px;
    // position:fixed;
  }
  .current-product-image {
    position:fixed;
    top:420px;
    border: 1px solid #eeeeee;
    .product-image {
      height:200px;
      width:180px;
      img {
        max-height:180px;
        max-width:160px;
      }
    }
  }

  .product-item-container {
    .clearfix();
    .make-md-column(4);
    position:relative;

    .action-buttons {
      height: 30px;
      position: absolute;
      z-index: 100;
      top: 8px;
      left: 30px;

      button {
        height: 20px;
        background: transparent;
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
        padding: 3px;
        cursor: pointer;
        margin-top: 0;
      }
      .add {
        float: right;
        margin-right: 0;
        color:#008800;
      }
      .remove {
        float: right;
        margin-right: 0;
        color:#ff0000;
      }
      .move-prev {
        float: left;
        margin-left: 0;
        color:#444444;
      }
      .move-next {
        float: left;
        margin-left: 5px;
        color: #444444;
      }
    }
  }

  .product .slug {
    text-transform: none;
    color: #ad241a;
  }

  .product .variants {
    list-style: none;
    padding: 0;
    li {
      border-bottom: 1px solid #ddd;
      padding: 10px 0;
      .remove {
        margin: 15px 20px 0 0;
      }
    }
  }
  .product-tags {
    .tag-list{
      width:100%;
      border: solid 1px #EEE;
      padding: 5px;
      color: #FAA;
      margin-bottom: 10px;
    }
  }
  tags-input {
    input, span {
      text-transform: lowercase;
    }
  }
  .category-tree {
    overflow: scroll;
    label {
      color: #000;
    }
  }
  .last-category {
    display:none;
  }
  .product.saving {
    opacity: 0;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
  }
  .error-message {
    color: #F00;
  }
}
