
@pagination-active-bg: transparent;
@pagination-active-border: transparent;
@pagination-active-color: #000000;

@pagination-hover-bg: transparent;
@pagination-hover-border: transparent;
@pagination-hover-color: #000000;

@pagination-disabled-border: #AAAAAA;
@pagination-disabled-color: #AAAAAA;

@pagination-border: transparent;
@pagination-color: #717171;

@border-radius-base: 0px;

@border-radius-small: 0px;
@padding-small-vertical: 10px; 
@padding-small-horizontal: 0px;


.pagination {
  
  > li > a,
  > li > span {
    .font-family-responsive-sans-serif(14px, 14px, 12px, 12px);
    .make-responsive-width(26px, 26px, 31px, 31px);
    text-align:center;
    &:focus {
      color: @pagination-color;
      background-color: transparent;
      border-color: @pagination-border;
    }
    &:hover{
      text-decoration: underline;
    }
  }
  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
     text-decoration: underline;
     .font-family-responsive-sans-serif-bold(14px, 14px, 12px, 12px);
    }
  }  
  .disabled {
    display:none;
  } 
  
}