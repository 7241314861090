.brands {
  .make-responsive-margin-top(@margin-default * 3, 0, 0, 0);
  .make-responsive-min-height(800px, inherit, inherit, inherit);

  span, a {
    display: block;
  }

  .brands--content--alph-guide {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: space-between;
    border-bottom: 1px solid #f1f1f1;
    margin: 0;

    .make-responsive-padding-top-bottom(15px, 15px, 15px, 15px);
    .make-responsive-padding-left-right(40px, 40px, 40px, 40px);

    li {
      list-style: none;

      a {
        color: @new-light-gray;
        text-transform: uppercase;
        .font-family-sans-serif();
        .make-responsive-font-size(@font-size-medium-large, @font-size-medium-large, @font-size-medium-large, @font-size-medium-large);
      }
    }
  }

  .brand-group-filter {
    .font-sans-serif(@font-size-small);
    text-align: center;
    margin-bottom: @margin-default*4;
    margin-top: @margin-default*3;
    @media (max-width: @screen-sm-min) {
      margin-bottom: @margin-default;
      margin-top: @margin-default;
    }
    a {
      display: inline-block;
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #f9f9f9;
      letter-spacing: 1px;
      &:hover {
        border-bottom: 1px solid @brand-main-color;
      }
      &.separator {
        padding-left: @margin-default*2;
        &:hover {
          border-color: #f9f9f9;
          cursor: auto;
        }
        @media (max-width: @screen-xs-max) {
          padding-left: @margin-default;
        }
      }
      &.separator::after {
        content: "\00a0"
      }
      &.active {
        color: @brand-main-color;
        border-bottom: 1px solid @brand-main-color;
      }
    }
    h1 {
      .font-sans-serif(@font-size-big-large);
      text-transform: capitalize;
      color: @brand-main-color;
    }
    .brand-group-filter--select {
      border: 0.5px solid;
      text-transform: capitalize;
      border-right: 0!important;
      border-left: 0!important;
      border-top: 0!important;
    }
  }
  .alph-guide {
    color: #4a4a4a;
    position: fixed;
    right: 15px;
    top: 25%;
    list-style: none;
    text-transform: uppercase;
    .font-family-sans-serif(12);
    width: 10px;

    li {
      float: left;
      width: 9px;
    }
  }
  .alph-horizontal-guide {
    color: #4a4a4a;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    text-transform: uppercase;

    li {
      list-style: none;
    }
  }

  .brands--title {
    color: #4a4a4a;
    position: relative;
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'OMBR', Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    h1 {
      text-transform: uppercase;
      display: inline-block;
      .make-responsive-font-size(@font-size-x-big-large, @font-size-x-big-large, @font-size-x-big-large, @font-size-large-medium);
      .font-serif;

      margin-top: 0;
      margin-bottom: 5px;
      margin-right: 20px;
    }
    font-size: @font-size-small;
    .font-sans-serif;
    @media (max-width: @screen-xs-max) {
      h1 {
        float: left;
      }

      text-align: right;
      padding-right: 10px;
      width: 95%;
    }
  }
  .brands--filter {
    z-index: 3;
    position: relative;
    top: 35px;
    .sidebar-rail {
      .top-offset {
        .make-responsive-height(180px, 180px, 80px, 80px);
      }
      .bottom-offset {
        height: 450px;
      }
    }

    .filter-container {
      .hidden-xs;
      .make-responsive-width(240px, 230px, 230px, 0);
      z-index: 3;
      border: 1px solid @brand-gray-light;
      background-color: #fff;
      opacity: .8;
      margin-bottom: 15px;

      .filter-container--filter {
        border-top: 1px solid #f1f1f1;
        margin-left: @margin-default;
        margin-right: @margin-default;
        .panel-title {
          .font-sans-serif(@font-size-small);
          height: 45px;
          padding-top: 8px;
          label {
            cursor: pointer;
            text-transform: capitalize;
            a {
              font-weight: 400;
              margin-right: 8px;
            }
          }
          label.active {
            a {
              color: @brand-main-color;
              font-weight: 700;
            }
          }
          label:hover {
            a {
              color: @brand-main-color;
            }
          }
        }
      }

      .filter-container--filter:first-child {
        border-top: none;
      }
    }
  }
  .brands--content {
    margin-top: 0;
    flex: 1 auto;

    // @media (min-width: @screen-lg-min) {
    //   .make-responsive-columns(9, 0, 0, 0);
    //   .make-responsive-columns-offset(3, 0, 0, 0);
    // }

    .alphabetical-list {
      list-style: none;

      .letter {
        .make-responsive-padding-right(0, 25px, 25px, 25px);

        h2 {
          .make-responsive-font-size(@font-size-xx-large, @font-size-large, @font-size-large, @font-size-large);
          .make-responsive-margin-top(60px, 60px, 60px, 60px);
          .make-responsive-margin-bottom(20px, 20px, 20px, 20px);
          .make-responsive-padding-top-bottom(0, 6px, 6px, 6px);
          .make-responsive-border-bottom(none, solid 2px #e8e8e8, solid 2px #e8e8e8, solid 2px #e8e8e8);
          .font-serif-bold;

          color: @new-light-gray;
          width: 100%;
        }

        h2:first-child {
          .make-responsive-margin-top(30px, 30px, 30px, 30px);
        }

        h2::before {
          margin-top: 0;
          height: 0;
        }

        ul {
          .letter--chunk {
            .make-responsive-columns(4, 4, 4, 12);
            padding-left: 0;
          }

          @media (min-width: @screen-lg-min) {
            display: flex;
            flex-direction: row;

            &.minSizeForAbc {
              flex-direction: column;
            }
          }

          &.moreThanOne {
            li {
              margin-bottom: 10px;
            }
          }

          li {
            list-style: none;

            a {
              .make-responsive-font-size(@font-size-medium, @font-size-medium-large, @font-size-medium-large, @font-size-medium-large);
              .make-responsive-line-height(2.36em, 2.75em, 2.75em, 2.75em);
              .font-sans-serif;

              color: @new-light-gray;
            }
          }
        }
      }
    }
  }

  ul.discover-list {
    li {
      .make-row();
      .make-responsive-height(314px, 258px, 264px, 174px);

      list-style: none;
      background-color: #222222;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      .make-responsive-margin-bottom(10px, 10px, 10px, 5px);

      .left {
        .make-responsive-columns(3, 3, 12, 12);
        z-index: 2;
        .make-responsive-padding(30px, 30px, 20px, 15px);
        .make-responsive-padding-top(30px, 30px, 10px, 5px);
        .make-responsive-padding-bottom(30px, 30px, 10px, 5px);
        .font-text-align-xs-center;
        .font-text-align-sm-center;

        @media (max-width: @screen-sm-max) {
          .brand-stitch {
            .margin-center;
          }
        }
        .logo {
          color: white;
          .brand-logo {
            .make-responsive-margin-left(0, 0, auto, auto);
            .make-responsive-margin-right(0, 0, auto, auto);
            svg {
              .make-responsive-margin-top(30px, 30px, 30px, 10px);
              .make-responsive-width(95%, 95%, 50%, 50%);
              .make-responsive-height(166px, 110px, 80px, 50px);
            }

            &.white {
              svg {
                .svg-color(#FFF);
                @media (max-width: @screen-sm-max) {
                  .svg-color(#000);
                }
              }
            }
          }
        }
      }

      @media (max-width: @screen-sm-max) {
        margin-right: -15px;
        margin-left: -15px;
        a {
          position: relative;
          overflow: hidden;
          .overlay {
            .hidden-lg;
            .hidden-md;
            margin-left: auto;
            margin-right: auto;
            width: 60%;
            height: 300px;
            background-color: white;
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            opacity: .85;
          }
          .left {
            .link {
              color: black;
            }
            .logo {
              color: black;
              .brand-logo {
                text-align: center;
                &.black {
                  svg {
                    .svg-color(#000);
                  }
                }
              }
            }
          }
        }

        background-size: cover;
        background-repeat: no-repeat;
        height: percentage(360 / 1200);

      }

      .right {
        .make-responsive-columns(9, 9, 12, 12);
        .hidden-xs;
        .hidden-sm;
        z-index: 2;
        .products {
          .product {
            &:nth-child(2),
            &:nth-child(3) {
              .hidden-xs;
              .hidden-sm;
            }
          }
          .product-list();
          .no-product-info();
          .product-item-container, .product-list-item {
            .make-responsive-columns(3, 3, 6, 6);
            margin-top: @grid-gutter-width / 2;
            margin-bottom: @grid-gutter-width / 2;
            .product-image {
              .make-responsive-height(284px, 228px, 234px, 144px) !important;
            }
          }
        }
      }
    }
  }
  .alphabet-wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  .form-control {
    width: 90%;
    margin-top: 10px;
    &:focus {
      border-color: transparent;
      outline: 0;
      -webkit-box-shadow: inset 0 0 0 transparent, 0 0 8px transparent;
      box-shadow: inset 0 0 0 transparent, 0 0 8px transparent;
    }
  }
}
@media (min-width: 768px) {
  .drop-down-filter {
    display: block!important;
    position: relative!important;
  }
  .brands--filter, .filter-panel {
    display: none!important;
  }
  .shop-view.brand-products .filter-btn {
    left: 0;
    top: 33px!important;
    position: relative;
    width: auto;
  }
  .drop-down-content {
    left: 0;
    width: 400px;
    height: calc(90vh - 82px);
    top: 82px;
    .filter-footer .apply-btn {
      width: auto!important;
      color: white;
      float: right;
    }
  }
}