body {
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: white;
  .font-serif(@font-size-medium-large);
  line-height: 1.5em;
  @media (max-width: @screen-sm-max) {
    line-height: 1.1em;
  }
}
.transition( @duration: 1s, @easing: ease ) {

  -webkit-transition: all @duration @easing;
     -moz-transition: all @duration @easing;
      -ms-transition: all @duration @easing;
       -o-transition: all @duration @easing;
          transition: all @duration @easing;

}
h1, h2, h3, h4, h5, h6 {
  .font-serif;
  em {
    .font-serif-italic;
  }
}

h1 {
  .font-size(50px, 50px, 32px, 32px);
}

h2 {
  .font-size(40px, 40px, 24px, 24px);
}

h3 {
  .font-size(30px, 30px, 18px, 18px);
}

h4 {
  .font-size(20px, 20px, 16px, 16px);
}

h5 {
  .font-size(16px, 16px, 14px, 14px);
}

h6 {
  .font-size(14px, 14px, 12px, 12px);
}

p {
  .font-serif(@font-size-medium-large);
  line-height: 1.5em;
  @media (max-width: @screen-sm-max) {
    line-height: 1.1em;
  }
  &.lead {
    .font-serif(16px);
    .text-uppercase();
  }
  &.dek {
    .font-serif-italic(19px);
  }
  a {
    text-decoration: underline;
    &:hover {
      color: @brand-main-color;
      text-decoration: underline;
    }
  }
}

a, .link {
  .link();
  &.inverse {
    .link-inverse();
  }
  &.pale {
    .link-pale();
  }
  .white {
    .link-color-variation(#fff, @brand-main-color);
  }
  .black {
    .link-color-variation(#000, @brand-main-color);
  }
}

ul, ol {
  padding-start: 0;
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
}

.visibilityHidden {
  visibility: hidden;
}
