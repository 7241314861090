.drop-down-filter {
  @media (max-width: @screen-xs-max) {
    top: 0;
    left: 0;
    width: fit-content;
    position: relative;
    .filter-header {
      background-color: #ffffffd9;
      color: #333;
      margin: 0px -15px;

      h2 {
        .font-family-sans-serif(@font-size-big-large);
        background-color: #fff;
        padding: 20px 20px 0px 20px;
        margin: 0px;
        text-transform: capitalize;
      }

      h4 {
        background-color: #fff;
        padding: 0px 20px 20px 20px;
        margin: 0px;
        .font-family-sans-serif(@font-size-medium);
      }

      .close-btn {
        position: absolute;
        right: -5px;
        margin: 0 0 0 0;
        background-color: transparent;
        color: black;
        border: none;
        font-size: 16px;
        top: -4px;
        z-index: 2222222;

        &::before {
          font-family: 'Flaticons Solid';
          speak: none;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          content: "\e1bb";
        }
      }
    }
  }

  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  display: none;
  .visible-xs-portrait;

  &.search-preview-mobile {
    z-index: -100;

    &.opened {
      z-index: 24;
    }

    .visible-sm;
    .visible-xs;

    .drop-down-content {
      .overly {
        background: rgba(0, 0, 0, 0.75);
      }

      .filters {
        overflow-x: hidden;

        .filter-header {
          padding: 30px 10px 10px 10px;
        }

        &.with-results {
          background-color: #ffffffd1;
          padding: 0;
        }
      }

      .accordion-group {
        background-color: transparent;
      }

      .filter-footer {
        background-color: white;
      }

      .panel-heading {
        border-bottom: none;
      }

      .results-wrapper {
        padding: 0 10px 0 15px;
      }
    }
  }

  .filter-btn {
    position: fixed;
    top: 120px;
    left: 15px;
    width: auto;
    background-color: #fff;
    color: #000;
    opacity: .8;
    z-index: 23;
    @media (max-width: @screen-sm-max) {
      position: relative;
      left: 0;
      top: 0!important;
      margin-top: 0;
    }

    .flaticon {
      float: right;
    }

    // @media (max-width: @screen-xs-min) {
    //   top: 90px;
    // }
  }

  &#search-mobile {
    @media (max-width: @screen-sm-max) {
      opacity: 0;
    }
  }

  &#search-mobile.opened {
    @media (max-width: @screen-sm-max) {
      opacity: 1;
    }

    .filter-btn {
      opacity: 1;
      @media (max-width: @screen-xs-max) {
        top: 104px;
        right: 30px;
        left: auto;
        border: 1px solid #000;
        width: 120px;
      }
    }
  }

  .drop-down-content {
    position: fixed;
    overflow: auto;
    @media (min-width: @screen-sm-min) {
      top: initial;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
    @media (max-width: @screen-sm-max) {
      top: 100px !important;
      left: 0;
      right: 0;
      height: calc(100% - 110px);
    }
    bottom: 0;
    z-index: 24;

    .overly {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent;
    }

    .category-tree {
      background-color: #fff;
      width: 100%;
    }

    .filters {
      position: relative;
      z-index: 21;
      overflow: auto;
      padding: 0 15px 0 15px;
      @media (min-width: @screen-sm-min) {
        background-color: #fff;
        width: 100%;
        max-height: 75%;
      }
      @media (max-width: @screen-xs-max) {
        background-color: @brand-gray-light;
        width: calc(100% - 10px);
        height: calc(100% - 88px);
        overflow-x: hidden;
      }

      .other-filter {
        .panel-group .panel + .panel {
          margin-top: 10px;
        }

        .panel-heading {
          padding: 0px;
        }

        .panel-title {
          padding: 15px 0;
        }

        @media (max-width: @screen-xs-max) {
          a {
            .font-family-sans-serif(@font-size-medium);
            text-transform: uppercase;
            color: #333;

            &:link, &:visited, &:focus, &:hover {
              color: #333;
              text-decoration: none;
            }
          }

          .lvl0-icon {
            .font-family-sans-serif(@font-size-medium) !important ;
          }
        }

        .size-list, .color-list, .shipping-list {
          .filter-checkbox();
        }

        .filter-checkbox() {
          list-style: none;
          text-align: left;
          li {
            display: inline;

            input[type="checkbox"] {
              display: none;

              &:checked + label {
                @media (max-width: @screen-xs-max) {
                  .font-family-sans-serif(@font-size-small);
                  background-color: @button-color;
                  color: @brand-gray-light;
                }
                @media (min-width: @screen-sm-min) {
                  border: 1px solid @color-peach;
                  background-color: @color-peach;
                  .font-family-sans-serif-bold(@font-size-xx-small);
                }
              }

              &:disabled + label {
                @media (max-width: @screen-xs-max) {
                  .font-family-sans-serif(@font-size-small);
                  border: 1px solid @brand-gray-dark;
                  color: @brand-gray-dark;
                  background-color: @brand-gray;
                }
                @media (min-width: @screen-sm-min) {
                  color: #f3f3f3;
                  background: linear-gradient(to top left,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(0, 0, 0, 0) calc(50% - 0.8px),
                  rgba(243, 243, 243, 1) 50%,
                  rgba(0, 0, 0, 0) calc(50% + 0.8px),
                  rgba(0, 0, 0, 0) 100%);
                }
              }
            }

            label {
              cursor: pointer;
              color: #000;
              .make-responsive-width(65px, 62px, 30%, 31%);
              height: 50px;
              line-height: 50px;
              text-align: center;
              text-transform: uppercase;
              .make-responsive-margin-right(0px, 0px, 1%, 1%);
              @media (max-width: @screen-xs-max) {
                .font-family-sans-serif(@font-size-small);
                border: 1px solid @button-color;
                color: @button-color-hover;
              }
              @media (min-width: @screen-sm-min) {
                border: 1px solid #f3f3f3;
                .font-family-sans-serif-bold(@font-size-xx-small);
              }
            }

            .shipping-input + label {
              width: 99%;
              text-transform: capitalize;

              b {
                @media (max-width: @screen-xs-max) {
                  .font-family-sans-serif-bold(@font-size-small);
                }
                @media (min-width: @screen-sm-min) {
                  .font-family-sans-serif-bold(@font-size-x-small);
                }
              }
            }

            .with-description + label {
              line-height: 16px;
              padding-top: 9px;
              padding-bottom: 9px;
            }
          }
        }
      }

      .brand-filter {
        margin-left: 0;
        margin-right: 0;

        .brand-list {
          max-height: none;
          overflow: visible;
        }
      }

      .occasion-filter {
        margin-left: 0;
        margin-right: 0;

        li {
          margin-bottom: 15px;

          a {
            padding-top: 10px;
          }
        }
      }

      .sale-filter {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .filter-footer {
      position: relative;
      padding: 15px;
      z-index: 25;
      @media (min-width: @screen-sm-min) {
        margin: 0;
        border-top: 1px solid #f1f1f1;
        background-color: #fff;
        .apply-btn {
          background-color: @brand-main-color;
          width: 100%;

          &.disabled {
            background-color: @brand-gray;
            cursor: default;
          }
        }
      }
      @media (max-width: @screen-xs-max) {
        margin: 0px 10px 10px 10px;
        background-color: @brand-gray;
        .clear-btn, .apply-btn {
          width: 45%;
          padding-left: 25px;
          padding-right: 25px;
          padding-top: 12px;
          padding-bottom: 12px;
          text-transform: none;
          font-size: @font-size-base;
        }

        .clear-btn {
          border: 1px solid @button-color;
          margin-left: 1%;
          margin-right: 3%;
          color: @button-color-hover;
          background-color: @brand-gray;
          border: 1px solid @button-color-hover;
        }

        .apply-btn {
          margin-left: 3%;
          margin-right: 1%;
          background-color: @button-color-hover;
        }
      }
    }
  }

  .overly {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media (min-width: @screen-sm-min) {
      background: #000;
      opacity: .5;
    }
    @media (max-width: @screen-xs-max) {
      background: #fff;
    }
    z-index: 21;
  }
}

.with-sale-banner {
  .drop-down-filter .filter-btn {
    top: 180px;
  }
}
@media (min-width: 768px) {
  .drop-down-filter {
    display: block!important;
    position: relative!important;
  }
  .brands--filter, .filter-panel {
    display: none!important;
  }
  .drop-down-filter .filter-btn {
    left: 0;
    top: 10px!important;
    position: relative;
    width: auto;
    padding: 0 30px 30px 30px;
  }
  .drop-down-content {
    left: 0;
    width: 400px;
    height: calc(96vh - 82px);
    top: 82px;
    .filter-footer .apply-btn {
      width: auto!important;
      color: white;
      float: right;
    }
  }
}