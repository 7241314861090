.clipboard {
  .clipboard-flags, .clipboard-categories {
    position:relative;
    width:350px;
    margin-top:10px;
    label {
      font-family: 'OMBR',Arial,sans-serif;
      font-style: normal;
      font-size: 14px;
      display: block;
    }
    button {
      position:relative;
      right:0;
    }
  }
  .clipboard-categories {
    .product-tags {
      display: none;
    }
    .category-tree{
      overflow-y: auto;
      height: 40vh;
    }
  }
}
