@link-color:                   #000;
@link-hover-color:             @action-color;
@link-inverse-color:           #e6e6e6;
@link-hover-inverse-color:     darken(@link-inverse-color, 10%);
@link-pale-color:              #ffffff;
@link-hover-pale-color:        darken(@link-pale-color, 5%);

.link() {
  text-decoration: none;
  &:hover,
    &:focus {
    cursor: pointer;
    text-decoration: none;
    color: @brand-main-color;
  }
}
.no-outline(){
  &:hover,
    &:focus {
    outline: none;
  }
}
.link(@font-size) {
  font-size: @font-size;
  .link;
}

.link-color-variation(@color, @hover) {
  color: @color;
  &:hover,
    &:focus {
    color: @hover;
  }
}

.link-default-color() {
  .link-color-variation(@link-color, @link-hover-color);
}

.link-inverse() {
  .link-color-variation(@link-inverse-color, @link-hover-inverse-color);
  .link-nodecoration();
}

.link-pale() {
  .font-sans-serif(12px);
  color: @link-pale-color;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:hover, &:focus {
    text-decoration: underline;
    color: @link-hover-pale-color;
  }
}

.link-cta() {
  .font-family-sans-serif(@font-size-small);
  letter-spacing:1px;
  color: #fff;
  padding: 1em 30px;
  text-align: center;
  text-transform: uppercase;
  background-color: @button-color;
  &:hover, &:focus {
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    background-color: @button-color-hover;
  }
}

.link-underline {
  .link-underline();
}
.link-underline() {
  text-decoration: underline;
}
.link-nodecoration() {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}
