.fullscreen-zoom-container {
  .fullscreen-zoom {
    background: white;
    opacity: 0.01;
    transition: opacity 0.5s;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: scroll;
    z-index: 9999;
    display: flex;
    justify-content: center;

    .zoom-preload-img, .zoom-img {
      cursor: pointer;
      width: 100%;
      height: auto;
      img {
        position: relative;
        width: 100%;
        height: auto;
      }
    }
    @media (max-width: @screen-md-min) {
      .zoom-preload-img, .zoom-img {
        width: 100%;
        height: auto;
        position: fixed;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .zoom-img {
      opacity: 1;
      background-color: white;
    }
  }
  .btn-close {
    display: none;
    position: absolute;
    right: 10px;
    top: 0;

    .square(15px);
    .flaticon-solid-x;
    color: #000;
    text-shadow: white 0 0 10px;
    font-size: 24px;
  }

  &.visible {
    .fullscreen-zoom {
      opacity: 1;
      z-index: 9997;
    }
    .btn-close {
      display: block;
      z-index: 9999;
    }
  }
  &.fullyLoaded {
    .fullscreen-zoom {
      width: auto;
      height: auto;
      .zoom-img {
        opacity: 1;
      }
    }
  }
  &.fullyClosed {
    .fullscreen-zoom {
      width: 0;
      height: 0;
      .zoom-img {
        img {
          width: 0;
          height: 0;
        }
      }
    }
    visibility: hidden;
  }
}
