.product-list {

  @product-height-md: 300px;

  .make-row();

  .product-list-item {
    margin-top: @grid-gutter-width*1.2;

    .make-responsive-margin-bottom(@grid-gutter-width*1.2, @grid-gutter-width*1.2, @grid-gutter-width*1.2, 0px);
    margin-left: 0;
    margin-right: 0;
    text-align: center;

    a:hover, a:focus {
      .product-info {
        .product-brand {
          color: @brand-main-color;
        }

        color: @brand-main-color;
      }
    }

    .product-image {
      position: relative;
      background-color: #eff2f7;
      border: 1px solid #f5f5f5;
      // .make-responsive-height(414px, 339px, 350px, 268px);
      min-height: inherit;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
        background-color: #fff;
      }
    }
  }

  &.no-product-info {
    .product-info {
      display: none;
    }
  }

  .product-info {
    height: @product-height-md / 4;
    text-align: center;
    .font-sans-serif(@font-size-base);
    color: #737373;
    line-height: 1.5;

    a {
      text-decoration: none;
    }

    span {
      display: block;
      .truncate();
    }

    .product-brand {
      margin-top: 10px;
      .font-serif(@font-size-medium-large);
      color: #000;
    }

    .product-price.small {
      margin: 0;
      font-weight: 600;

      span {
        display: inline;
      }
    }

    .low-in-stock {
      color: @brand-main-color;
    }

    @media (max-width: @screen-xs-max) {
      text-align: center;
    }
  }

  @animation-function: cubic-bezier(0.4, 0, 1, 1);
  @animation-duration: 0.3s;

  .product-list-item {

    .close-btn {
      z-index: 1;
      float: right;
      background-color: transparent;
      border: none;
      position: absolute;
      right: 20px;
      padding: 5px;

      .make-responsive-width(22px, 22px, 18px, 18px);
      .make-responsive-display(none, block, block, block);

      svg {
        path {
          fill: #d5d5d5 !important;
        }
      }
    }

    &.child-style {
      position: relative;
      background: white;
      .transition(all @animation-duration @animation-function);
      .translate3d(0; 0; 0);
      left: 0;
      top: 0;
      opacity: 1;

      &.appearing {
        .translate3d(-100px; 0; 0);
        opacity: 0;
      }
    }

    .product-info {
      position: relative;

      .more-colors {
        .font-sans-serif(@font-size-x-small);
        letter-spacing: .1em;
        text-transform: uppercase;
        text-align: center;
        margin-top: -(@grid-gutter-width/2);
        z-index: 2;

        a {
          display: block;
          width: 100%;
          height: 100%;
        }

        span {
          padding: 2px 0;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .wishlist {
      background: url('/img/icons/heart.svg') no-repeat;
      background-origin: content-box !important;
      cursor: pointer;
      padding: 10px;
      width: 40px;
      height: 40px;
      position: absolute;
      z-index: 1;
      right: 1rem;
      top: 3.6rem;

      &:hover {
        background: url('/img/icons/heart-hover.svg') no-repeat;
      }

      &.active {
        background: url('/img/icons/heart-active.svg') no-repeat;
      }
    }

    .quick-view-band {
      position: absolute;
      z-index: 1;
      left: 0.9rem;
      bottom: 13.5rem;
      width: calc(100% - 1.8rem);
      @media (max-width: @screen-xs-max) {
        bottom: 9.5rem;
      }

      button {
        background-color: #ffffff50;
        border: 1px #00000050 solid;
        padding: 3px;
        color: #00000070;
        width: 80%;
        opacity: 0;
        transition : all @animation-duration;
        @media (max-width: @screen-sm-max) {
          padding: 4px;
          opacity: 1;
          transition : none;
        }

        &.active {
          background: #0000007a;
          color: white;
        }
      }
    }
  }

  .product-list-item:hover {
    .close-btn {
      display: block;
    }

    .wishlist {
      background: url('/img/icons/heart-hover.svg') no-repeat;

      &.active {
        background: url('/img/icons/heart-active.svg') no-repeat;
      }
    }

    .quick-view-band {
      button {
        background: #0000007a;
        color: white;
        opacity: 1;
        transition : all @animation-duration;
      }
    }
  }

  .product-item-container {
    position: relative;
    padding: 0 8px 0 8px !important;

    .more-colors a span:after {
      content: ' ❯';
    }
  }

  .styles-expanded {
    .more-colors a span:after {
      content: ' ❮';
    }
  }

  .admin-product-list-params {
    .font-sans-serif(@font-size-base);
    position: absolute;
    top: -20px;
    text-overflow: ellipsis;
    color: #aaa;
    @media (max-width: @screen-sm-max) {
      display: none;
    }

    .product-list-param-values {
      display: none;
    }

    &:after {
      content: '?'
    }

    &:hover {
      .product-list-param-values {
        display: inline-block;
      }

      &:after {
        content: ''
      }
    }

    .product-list-param-value:hover {
      color: black;
    }
  }
}
