.make-hero-image-panel{
    background-size: cover;
    // .hero-image-height;
    // @media (max-width: @screen-xs-min) {
    //   height: 160px;
    // }
}
.hero-image-height {
  height: calc(100vw / 2.19);
  // @hero-image-panel-height-lg: 500px;
  // @hero-image-panel-height-md: 350px;
  // @hero-image-panel-height-sm: 280px;
  // @hero-image-panel-height-xs: 230px;
  // .make-responsive-height(@hero-image-panel-height-lg;@hero-image-panel-height-md;@hero-image-panel-height-sm;@hero-image-panel-height-xs);
}

.hero-image-height-auto {
  height: auto;
} 

.hero-image {
  .make-responsive-height(230px, 200px, 150px, 100px);
  &.large {
    .make-responsive-height(722px, 522px, 300px, 200px);
  }
}
