.admin-users-view {
  table {
    .font-family-sans-serif-light(@font-size-small);
    display: table;
    a {
      text-transform: none;
    }
  }
  .user-hero {
    a.email {
      .font-family-serif(@font-size-large);
    }
  }
  .external-links {
    margin: 20px 0 20px 0;
    a {
      padding: 10px;
      background: #eee;
    }
  }
  .remove-reward {
    .flaticon-solid-x;
  }

  .controls-pagination-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
  }

  .pagination-btn {
    width: 120px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 3px;
  }

  .total-page {
    margin: 0 15px;
  }

  .flex-space {
    flex-grow: 1;
  }

  .page-size {
    width: 50px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }

  .copy-button {
    padding: 4px 60px;
  }

  .add-btn {
    margin: 20px 0;
  }

  .reward-list {
    .add-new-row.not-adding {
      text-align: left;
      button {
        padding: 4px 60px;
      }
    }
  }

  .product-card {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 10px;
  }

  .product-card .card-img-top {
    height: 200px;
    object-fit: cover;
    max-width: 180px;
  }

  .product-card .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .product-card .card-text {
    font-size: 1rem;
    color: #6c757d;
  }

  .draggable-file {
    .drop-zone {
      border: 2px dashed #ccc;
      border-radius: 20px;
      padding: 20px;
      text-align: center;
      font-weight: bold;
      height: 640px;
      width: 360px;
      cursor: pointer;
    }

    .drop-zone.hover {
      border-color: #007bff;
    }

    .preview {
      width: 100%;
      border-radius: 20px;
      border: 2px dashed #666;
    }

    .loading {
      border: 2px dashed #ccc;
      background-color: #2c2c2c;
      border-radius: 20px;
      padding: 20px;
      text-align: center;
      font-weight: bold;
      height: 640px;
      width: 360px;
      cursor: wait;
      .text {
        position: absolute;
        text-align: center;
        bottom: 50%;
        left: 30%;
        width: 40%;
      }
    }

    .remove {
      position: absolute;
      bottom: 20px;
      left: 30%;
      width: 40%;
      z-index: 10;
    }
  }

  .award-button {
    position: relative;
    max-width: 100% !important;
    min-width: 130px !important;
    padding: 4px 60px !important;
  }

  .give-award-button,
  .add-product-button {
    position: relative;
    max-width: 100% !important;
    min-width: 130px !important;
    padding: 6px 60px !important;
    margin-left: 15px;
  }

  .modify-closet-form {
    max-width: 50% !important;
  }

  .notes {
    outline: none;
    &.dirty {
      border-color: #00f;
    }
    &.saving {
      border-color: #99f;
    }
  }
}
