.invite {

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  min-height: 100%;
  min-width: 600px;
  background-image: url("/img/invite-bg.jpg");
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;

  header {
    position: absolute;
    top: 0;
    left: 30px;
    right: 30px;
    height: 80px;
    .logo svg {
      margin-top: 10px;
      width: 115px;
      height: 40px;
    }
    div, a {
      padding: 20px 0;
      display: inline-block;
    }
    a {
      .font-family-sans-serif(@font-size-small);
      text-transform: capitalize;
    }
    .login-btn {
      float: right;
      text-align: right;
      padding: 32px;
    }
  }

  .content-container {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 80px;
    margin-bottom: 200px;
    .tag-line {
      margin-top: 250px;
      .font-family-serif(70);
      line-height: 72px;
      text-transform: capitalize;
    }
    .form-success-message {
      background: transparent;
      text-align: left;
      .font-family-sans-serif-bold(14px);
    }
    .stitch {
      .make-stitch;
    }

    form {
      margin: 0;
      padding: 0;
      max-width: 700px;
      input {
        max-width: 50%;
        display: inline-block;
        .make-responsive-padding-top(16px,16px,15px,15px);
        .make-responsive-padding-bottom(16px,16px,14px,15px);
        .make-responsive-padding-left(16px,16px,16px,16px);
        .make-responsive-padding-right(10px,10px,10px,10px);
        float:left;
      }
      button {
        display: inline-block;
        margin-top: 0;
        .make-responsive-padding-top(16px,16px,16px,16px);
        .make-responsive-padding-bottom(16px,16px,16px,16px);
        .make-responsive-padding-left(30px,30px,30px,30px);
        .make-responsive-padding-right(30px,30px,30px,30px);

        float: left;
        background-color: @action-color;
        .font-family-sans-serif(@font-size-base);
        &:hover, &:focus {
          background-color: lighten(@action-color, 3%);
        }
      }
    }
  }

  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: transparent !important;
    .copyright {
      text-align: left !important;
      color: black !important;
    }
    div {
      height: 40px;
      padding: 0 20px;
      margin: 0;
      &>div {
        display: inline-block;
        .font-family-responsive-serif(12px,12px,12px, 10px);
        text-transform: uppercase;
      }
    }
    .logo {
      display: none;
    }
    .links {
      float: right;
      a {
        padding: 15px 0;
      }
    }
    .social-networks {
      display:none;
      float: right;
      a {
        padding: 15px;
      }
    }
  }

  @media only screen and (max-device-width : 736px) {
    &{
      min-width: 300px;
    }
    header {
      .login-btn {
        padding-top: 23px;
        padding-right: 3px;
        padding-bottom: 0px;
        padding-left: 0px;
      }
    }
  }

  @media
  only screen
  and (min-device-width : 768px)
    and (max-device-width : 1024px)
    and (-webkit-min-device-pixel-ratio : 2) {
    .content-container {
      .tag-line {
        margin-top: 300px;
      }
    }
  }

  @media only screen and (max-device-width : 414px)   {
    .logo svg {
      max-width: 150px;
    }

    .content-container {
      .tag-line {
        margin-top: 150px;
        .font-family-serif(36px);
        line-height: 36px;
      }
      form {
        max-width: 100%;

        input {
          width: 100%;
          max-width: 100%;
          .font-family-sans-serif(12px);
          text-align:left;
        }
        button {
          width: 100%;
          max-width: 100%;
          margin-left: 0px;
          .font-family-sans-serif(12px);
        }
      }
    }
    footer {
      .logo {
        visibility:hidden;
      }
      height: 100px;
      text-align:left;
      div {
        padding: 0 15px;
      }
      span {
        .font-family-sans-serif(9px);
      }
      .social-networks {
        text-align:left;
        padding:0;
        float:none;
      }
      .copyright {
        display:block;
        margin-top: 10px;

      }
      .links {
        display:none;
      }
    }

  }

  @media only screen and (max-device-width : 736px) and (orientation:landscape)  {
    .content-container {
      .tag-line {
        margin-top: 90px;
        .font-family-serif(36px);
        line-height: 36px;
      }
      form {
        button {
          width: 50%;
          max-width: 50%;
          .font-family-sans-serif(12px);
        }
        input {
          width: 50%;
          max-width: 50%;
          .font-family-sans-serif(12px);
        }
      }
    }
  }
}
