.slider-lens {
  .magnifying-glass;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  color: black;
  border-radius: 40px;
  text-align: center;
  bottom: 20px;
  left: 20px;
  padding-left: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  ::before {
    padding: 10px;
  }

  /* iPad */
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    left: 20%;
    transform: translateX(-20%);
  }
  z-index: 2;
  font-size: 14px;
  line-height: 40px;
}

.swiper-container {
  width: 100%;
  height: 100%;
  .hidden-md;
  .hidden-lg;

  &.big {
    .swiper-slide {
      .make-responsive-max-height(auto, auto, 700px, 480px);

      img {
        .make-responsive-max-height(auto, auto, 700px, 480px);
      }
    }
  }

  &.big-middle {
    .swiper-slide {
      .make-responsive-max-height(auto, auto, 700px, 330px);

      img {
        .make-responsive-max-height(auto, auto, 700px, 330px);
      }

      @media (min-height: 600px) and (max-height: 650px) {
        .make-responsive-max-height(auto, auto, 700px, 400px);
        img {
          .make-responsive-max-height(auto, auto, 700px, 400px);
        }
      }
      @media (min-height: 651px) and (max-height: 700px) {
        .make-responsive-max-height(auto, auto, 700px, 500px);
        img {
          .make-responsive-max-height(auto, auto, 700px, 500px);
        }
      }
      @media (min-height: 701px) and (max-height: 750px) {
        .make-responsive-max-height(auto, auto, 700px, 510px);
        img {
          .make-responsive-max-height(auto, auto, 700px, 510px);
        }
      }
      @media (min-height: 751px) {
        .make-responsive-max-height(auto, auto, 700px, 610px);
        img {
          .make-responsive-max-height(auto, auto, 700px, 610px);
        }
      }
    }
  }

  &.big-responsive {
    .swiper-wrapper {
      height: auto !important;

      .swiper-slide {
        .make-responsive-height(420px, 420px, 100%, 100%) !important;
        .make-responsive-max-height(420px, 420px, inherit, 100%);

        img {
          .make-responsive-max-width(auto, auto, 100%, 100%);
          .make-responsive-width(auto, auto, auto, auto);
          .make-responsive-max-height(auto, auto, inherit, 100%);
          .make-responsive-height(auto, auto, auto, auto);
        }
      }
    }
  }

  .swiper-pagination {
    bottom: 0px;

    position: absolute;
    width: 100%;
    margin-left: auto;
    padding-top: 1px;
    text-align: center;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4);

    .swiper-pagination-switch {
      margin-left: 5px;
      margin-right: 5px;
      width: 10px;
      height: 10px;
      text-indent: -999px;
      border: 1px solid #b8b8b8;

      border-radius: 10px;
      cursor: pointer;
      display: inline-block;

      &.swiper-active-switch {
        background-color: #000;
        border-color: transparent;
      }
    }
  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  float: left;
  width: 100%;
  position: relative;
  .make-responsive-max-height(auto, auto, 500px, 345px);

  .product-image img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    .make-responsive-height(683.2px, 558.24px, 500px, 345px);
    .make-responsive-width(455.5px, 372.2px, 333.3px, 230px);

    @media (max-width: @screen-sm-max) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.shop-content {
  .product-image {
    position: relative;
    width: 100%; /* Puede ser cualquier valor, como 300px, 50%, etc. */
    padding-bottom: calc((3 / 2) * 100%); /* 3/2 representa el aspect ratio 2:3 */
    /* background-image: url('path/to/your/image.webp'); */
    background-size: cover;
    background-position: center;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Mantiene la relación de aspecto de la imagen */
    }
  }
}
.product-image {
  position: relative;
  min-height: 460px;
  @media (min-width: @screen-sm-min) {
    width: auto;
    // display: flex;
  }
  img {
    position: relative;
    // width: 100%;
  }

  .low-resolution-preview {
    position: absolute;
    width: 100%;
  }
}

.swiper-blank {
  .product-image {
    .low-resolution-preview {
      position: relative;
    }
  }
}

.standalone-low-resolution-preview {
  .product-image {
    .low-resolution-preview {
      position: relative;
    }
  }
}

.swiper-container {
  .standalone-low-resolution-preview {
    .product-image {
      .low-resolution-preview {
        position: relative;
      }
    }
  }

  product-image {
    width: 100%;
    min-height: 430px;
  }
}

.carousel.product-image-slider {
  @height: 100%;
  border: 0 solid #f5f5f5;
  vertical-align: middle;
  .make-responsive-line-height(680px, 553.24px, auto, auto);
  .hidden-xs;
  .hidden-sm;

  .carousel-inner > .item {
    img {
      width: 100%;
      // .make-responsive-height(683.2px, 558.24px, 500px, 345px);
      // .make-responsive-width(455.5px, 372.2px, 333.3px, 230px);
    }
  }

  .carousel-indicators {
    bottom: -10px;
    opacity: 1;
    width: 100%;
    margin-left: auto;
    padding-top: 1px;
    left: 0;
    background-color: rgba(255, 255, 255, .8);
    .hidden-lg;
    .hidden-md;

    &:hover {
      opacity: .9;
    }

    li {
      border-color: #b8b8b8;
      margin-left: 5px;
      margin-right: 5px;

      &.active {
        background-color: #000;
        border-color: transparent;
        margin-top: 1px;
        margin-bottom: 1px;
        margin-left: 5px;
        margin-right: 5px;
        width: 10px;
        height: 10px;
      }
    }
  }

  .carousel-control {
    opacity: 0;
    background-image: none;
    width: 5%;
    .hidden-xs;
    .hidden-sm;

    .glyphicon.glyphicon-chevron-left {
      .flaticon-solid-left;
      color: #b8b8b8;
      text-shadow: none;
      width: 50px;
      height: 50px;
      .make-responsive-font-size(25px, 25px, 15px, 15px);
      opacity: .7;

      &:hover {
        color: #b8b8b8;
        opacity: .9;
      }
    }

    .glyphicon.glyphicon-chevron-right {
      .flaticon-solid-right;
      color: #b8b8b8;
      text-shadow: none;
      width: 50px;
      height: 50px;
      .make-responsive-font-size(25px, 25px, 15px, 15px);
      opacity: .7;

      &:hover {
        color: #b8b8b8;
        opacity: .9;
      }
    }
  }

  &.ready {
    .carousel-indicators, .carousel-control {
      opacity: 1;
    }
  }
}

.magnify-container {
  margin: auto;
  position: relative;
}

.magnify-container .magnify-glass {
  width: 160px;
  height: 160px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.9),
  0 0 7px 7px rgba(0, 0, 0, 0.25),
  inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.9),
  0 0 7px 7px rgba(0, 0, 0, 0.25),
  inset 0 0 40px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 7px rgba(255, 255, 255, 0.9),
  0 0 7px 7px rgba(0, 0, 0, 0.25),
  inset 0 0 40px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  background-repeat: no-repeat;
  background-color: white !important;
  z-index: 999;
}

.magnify-container .magnify-image {
  width: 100%;
  height: 100%;
}
