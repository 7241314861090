@font-face {
  font-family: 'OMSN';
  src: url('/fonts/om-sn-display-webfont.eot');
  src: url('/fonts/om-sn-display-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/om-sn-display-webfont.woff2') format('woff2'),
  url('/fonts/om-sn-display-webfont.woff') format('woff'),
  url('/fonts/om-sn-display-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OMSN';
  src: url('/fonts/om-sn-displayit-webfont.eot');
  src: url('/fonts/om-sn-displayit-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/om-sn-displayit-webfont.woff2') format('woff2'),
  url('/fonts/om-sn-displayit-webfont.woff') format('woff'),
  url('/fonts/om-sn-displayit-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'OMSN';
  src: url('/fonts/omsn-demi-webfont.eot');
  src: url('/fonts/omsn-demi-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/omsn-demi-webfont.woff2') format('woff2'),
  url('/fonts/omsn-demi-webfont.woff') format('woff'),
  url('/fonts/omsn-demi-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OMBR';
  src: url('/fonts/ombr-regular-webfont.eot');
  src: url('/fonts/ombr-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/ombr-regular-webfont.woff2') format('woff2'),
  url('/fonts/ombr-regular-webfont.woff') format('woff'),
  url('/fonts/ombr-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OMBR';
  src: url('/fonts/ombr-bold-webfont.eot');
  src: url('/fonts/ombr-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/ombr-bold-webfont.woff2') format('woff2'),
  url('/fonts/ombr-bold-webfont.woff') format('woff'),
  url('/fonts/ombr-bold-webfont.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'OMBR';
  src: url('/fonts/BrownPro-Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'KR';
  src: url('/fonts/alternative/NotoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-family-alt: 'KR', Arial, sans-serif;
@font-family-sans-serif:  'OMBR', Arial, sans-serif;
@font-family-serif:       'OMSN', Times, serif;

@font-size-xx-small:      8px;
@font-size-x-small:       10px;
@font-size-small:         12px;
@font-size-base:          14px;
@font-size-medium:        14px;
@font-size-medium-large:  16px;
@font-size-large:         18px;
@font-size-large-medium:  20px;
@font-size-big-large:     24px;
@font-size-x-big-large:   28px;
@font-size-x-large:       30px;
@font-size-xl-large:      32px;
@font-size-xl-m-large:    34px;
@font-size-xx-m-large:    36px;
@font-size-xx-large:      48px;

@font-letter-spacing-large: .5px;

@headings-font-family:    @font-family-serif;
@headings-font-weight:    500;

.font-family-properties (@font-family, @font-weight, @font-style) {
  font-family: @font-family;
  font-weight: @font-weight;
  font-style: @font-style;
}

.font-family-properties (@font-family, @font-weight, @font-style, @font-size) {
  .font-family-properties(@font-family, @font-weight, @font-style);
  font-size: @font-size * 1px;
}

.font-serif() {
  .font-family-properties(@font-family-serif, 400, normal);
}

.font-serif(@font-size) {
  .font-family-properties(@font-family-serif, 400, normal, @font-size);
}

.font-serif-bold() {
  .font-family-properties(@font-family-serif, bold, normal);
}

.font-serif-bold(@font-size) {
  .font-family-properties(@font-family-serif, bold, normal, @font-size);
}

.font-serif-italic() {
  .font-family-properties(@font-family-serif, 400, italic);
}

.font-serif-italic-bold() {
  .font-family-properties(@font-family-serif, bold, italic);
}

.font-serif-italic-bold(@font-size) {
  .font-family-properties(@font-family-serif, bold, italic, @font-size);
}

.font-serif-italic(@font-size) {
  .font-family-properties(@font-family-serif, 400, italic, @font-size);
}

.font-sans-serif() {
  .font-family-properties(@font-family-sans-serif, 400, normal);
}

.font-sans-serif(@font-size) {
  .font-family-properties(@font-family-sans-serif, 400, normal, @font-size);
}

.font-sans-serif-italic() {
  .font-family-properties(@font-family-sans-serif, 400, italic);
}

.font-sans-serif-italic(@font-size) {
  .font-family-properties(@font-family-sans-serif, 400, italic, @font-size);
}

.font-sans-serif-bold() {
  .font-family-properties(@font-family-sans-serif, bold, normal);
}

.font-sans-serif-bold(@font-size) {
  .font-family-properties(@font-family-sans-serif, bold, normal, @font-size);
}

.font-size(@font-size) {
  font-size: @font-size;
}

.font-size(@font-size-lg, @font-size-md, @font-size-sm, @font-size-xs) {
  @media (max-width: @screen-xs-max) { font-size: @font-size-xs; }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { font-size: @font-size-sm; }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { font-size: @font-size-md; }
  @media (min-width: @screen-lg-min) { font-size: @font-size-lg; }
}

// --- OLD font mixins ---


.font-serif {
  .font-serif;
}
.font-serif-bold {
  .font-serif-bold;
}
.font-serif-italic {
  .font-serif-italic;
}
.font-serif-italic-bold {
  .font-serif-italic-bold;
}
.font-sans-serif {
  .font-sans-serif;
}
.font-sans-serif-bold {
  .font-sans-serif-bold;
}

.font-family-sans-serif-bold (@font-size: @font-size-base) {
  .font-family-properties (@font-family-sans-serif, 700, normal, @font-size);
}

.font-family-responsive-sans-serif-bold(@font-size-lg: @font-size-base, @font-size-md: @font-size-base, @font-size-sm: @font-size-base, @font-size-xs: @font-size-base){
  @media (max-width: @screen-xs-max) { .font-family-properties (@font-family-sans-serif, 700, normal, @font-size-xs); }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .font-family-properties (@font-family-sans-serif, 700, normal, @font-size-sm); }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .font-family-properties (@font-family-sans-serif, 700, normal, @font-size-md); }
  @media (min-width: @screen-lg-min) { .font-family-properties (@font-family-sans-serif, 700, normal, @font-size-lg); }
}

.font-family-sans-serif (@font-size: @font-size-base) {
  .font-family-properties (@font-family-sans-serif, 400, normal, @font-size);
}

.font-family-responsive-sans-serif(@font-size-lg: @font-size-base, @font-size-md: @font-size-base, @font-size-sm: @font-size-base, @font-size-xs: @font-size-base){
  @media (max-width: @screen-xs-max) { .font-family-properties (@font-family-sans-serif, 400, normal, @font-size-xs); }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .font-family-properties (@font-family-sans-serif, 400, normal, @font-size-sm); }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .font-family-properties (@font-family-sans-serif, 400, normal, @font-size-md); }
  @media (min-width: @screen-lg-min) { .font-family-properties (@font-family-sans-serif, 400, normal, @font-size-lg); }
}

.font-family-sans-serif-light (@font-size: @font-size-base) {
  .font-family-properties (@font-family-sans-serif, 300, normal, @font-size);
}

.font-family-responsive-sans-serif-light(@font-size-lg: @font-size-base, @font-size-md: @font-size-base, @font-size-sm: @font-size-base, @font-size-xs: @font-size-base){
  @media (max-width: @screen-xs-max) { .font-family-properties (@font-family-sans-serif, 300, normal, @font-size-xs); }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .font-family-properties (@font-family-sans-serif, 300, normal, @font-size-sm); }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .font-family-properties (@font-family-sans-serif, 300, normal, @font-size-md); }
  @media (min-width: @screen-lg-min) { .font-family-properties (@font-family-sans-serif, 300, normal, @font-size-lg); }
}

.font-family-sans-serif-extra-light (@font-size: @font-size-base) {
  .font-family-properties (@font-family-sans-serif, 200, normal, @font-size);
}

.font-family-responsive-sans-serif-extra-light(@font-size-lg: @font-size-base, @font-size-md: @font-size-base, @font-size-sm: @font-size-base, @font-size-xs: @font-size-base){
  @media (max-width: @screen-xs-max) { .font-family-properties (@font-family-sans-serif, 200, normal, @font-size-xs); }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .font-family-properties (@font-family-sans-serif, 200, normal, @font-size-sm); }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .font-family-properties (@font-family-sans-serif, 200, normal, @font-size-md); }
  @media (min-width: @screen-lg-min) { .font-family-properties (@font-family-sans-serif, 200, normal, @font-size-lg); }
}

.font-family-serif (@font-size: @font-size-base) {
  .font-family-properties (@font-family-serif, 500, normal, @font-size);
}

.font-family-serif-italic (@font-size: @font-size-base) {
  .font-family-properties (@font-family-serif, 500, italic, @font-size);
}

.font-family-responsive-serif(@font-size-lg: @font-size-base, @font-size-md: @font-size-base, @font-size-sm: @font-size-base, @font-size-xs: @font-size-base){
  @media (max-width: @screen-xs-max) { .font-family-properties (@font-family-serif, 500, normal, @font-size-xs); }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .font-family-properties (@font-family-serif, 500, normal, @font-size-sm); }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .font-family-properties (@font-family-serif, 500, normal, @font-size-md); }
  @media (min-width: @screen-lg-min) { .font-family-properties (@font-family-serif, 500, normal, @font-size-lg); }
}
.font-family-responsive-serif-italic(@font-size-lg: @font-size-base, @font-size-md: @font-size-base, @font-size-sm: @font-size-base, @font-size-xs: @font-size-base){
  @media (max-width: @screen-xs-max) { .font-family-properties (@font-family-serif, 500, italic, @font-size-xs); }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { .font-family-properties (@font-family-serif, 500, italic, @font-size-sm); }
  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) { .font-family-properties (@font-family-serif, 500, italic, @font-size-md); }
  @media (min-width: @screen-lg-min) { .font-family-properties (@font-family-serif, 500, italic, @font-size-lg); }
}
.font-family-headings(@font-size) {
  .font-family-properties(@headings-font-family, @headings-font-weight, normal, @font-size);
}
