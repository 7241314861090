.admin-loyalty-user {
  h1 {
    margin-bottom: 40px;
  }

  .withdraw-info {
    padding: 10px;
  }

  .info-box {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    max-width: 600px;
  }

  .withdraw-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .info-key {
    margin-right: 5px;
    width: 150px;
  }

  .info-value {
    font-family: monospace;
    font-weight: bold;
    flex-grow: 1;
    margin-right: 10px;
  }

  .copy-btn {
    background-color: #4CAF50; /* Green */
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }

  .table {
    tfoot {
      td {
        background-color: #f2f2f2;
        line-height: 38px;
        border-bottom: 0;
      }
      .total-points-text {
        text-align: right;
        .make-responsive-padding-right(50px, 40px, 30px, 20px);
      }
      font-size: @font-size-x-large;
    }
    tr {
      a,
      p,
      td {
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 0px;
      }
      p,
      a {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      td,
      th {
        padding-left: @margin-default / 4;
        padding-right: @margin-default / 4;
      }
      .pending-points {
        color: #868686;
      }
    }
  }
}
