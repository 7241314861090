.checkout-v2-view {
  .shipping {
    .create-new-address {
      .hidden-md;
      .hidden-lg;
      text-align: center;
      margin-top: 25px;
      button {
        .make-xs-column(12);
      }
    }
    .email-legend {
      font-size: @font-size-small;
      margin: 6px 14px;
    }
    .unique-email-message {
      font-size: @font-size-small;
      margin: 6px 14px;
      a {
        text-decoration: underline;
        .font-sans-serif(@font-size-small);
      }
    }
    .subscribe-saks {
      label {
        padding-left: 10px;
        .font-sans-serif(@font-size-small);
      }
    }
    .subscribe-newsletter{
      .make-responsive-columns(10, 10, 12, 12);
      .make-responsive-columns-offset(1, 1, 0, 0);
      .margin-bottom-small;
      padding-left: 0;
      padding-right: 0;
      .font-text-align-xs-center;
      div {
        padding-left: 0;
        padding-right: 0;
        label {
          padding-left: 10px;
          .font-sans-serif(@font-size-small);
        }
        input {
          padding: 0;
        }
      }
    }
  }
}
