.sale-banner-container {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 28;
  top: 0px;
  height: @header-height-sm;

  .home-banner {
    text-align: center;
    vertical-align: middle;
    background: black;
    color: white;
    height: 100%;

    p {
      display: inline-block;
      padding: 6px;
      margin: 0;
      vertical-align: middle;
      .font-serif-italic(@font-size-small);
      .make-responsive-font-size(@font-size-small, @font-size-small, @font-size-small, @font-size-x-small);
      text-transform: uppercase;
      letter-spacing: 0.1em;

      a,
      .a:link,
      .a:visited,
      .a:hover,
      .a:active {
        text-decoration: none;
        color: white;
      }

      &.language {
        margin-left: 22px;
      }
    }

    a:hover {
      color: inherit;
    }

    img {
      .make-responsive-right(-4rem,-3rem,-8rem,-2rem);
      z-index: 2;
      position: relative;
    }

    button {
      background-color: @brand-main-color;
      padding: 2px 50px;
      box-shadow: 1px 1px 5px #00000087;
      margin-top: 1.7rem;
      z-index: 1;
      position: relative;
    }

    span {
      .make-responsive-left(-4rem,-3rem,-8rem,-2rem);
      margin-top: 2rem;
      transform: skewY(354deg);
      background: rgba(91, 91, 91, 0.7);
      padding: 1px 30px;
      position: absolute;
      font-size: 13px;
      color: #fff;
    }

    span:before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      transform: skewY(-11deg);
    }
  }

  &.bg-black {
    .home-banner {
      background: black;
      button {
        background: @brand-main-color;
      }
    }
  }
  &.bg-red {
    .home-banner {
      background: @brand-main-color;
      button {
        background: white;
        color: @new-light-gray;
      }
    }
  }
}

@media (max-width: 992px) {
  .sale-banner-container .home-banner span {
    padding: 4px 25px;
  }
  .sale-banner-container .home-banner button {
    padding: 5px 33px;
  }
}

@media (max-width: 340px) {
  .sale-banner-container .home-banner span {
    padding: 3px 19px;
  }
  .sale-banner-container .home-banner button {
    padding: 5px 23px;
  }
}