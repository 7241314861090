.checkout-base-view {
  .container();
  padding-top: 0;
  .btn-action {
    button {
      font-size: 13px;
      min-width: 220px !important;
    }
  }
}
a.enter-promo-code-link {
  cursor: default;
}