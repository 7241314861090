.checkout-order-items {
  .container;
  .margin-bottom-large;
  border-top: 1px solid #f5f5f5;

  header {
    margin-top: 60px;
    text-align: center;
  }

  .order-items {
    margin-top: 60px;

    .brand-container {
      margin-top: 45px;
      padding-bottom: 30px;
      border-bottom: 1px solid #f5f5f5;
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
      @media (max-width: @screen-sm-max) {
        border: 0;
      }

      .shipping-from {
        .make-md-column-offset(1);
        .font-serif-italic(16px);
        @media (max-width: @screen-sm-max) {
          .font-serif-italic(16px);
          .text-center;
        }
      }

      .shipping-methods {
        .make-md-column-offset(1);
        margin-top: 15px;
        @media (max-width: @screen-sm-max) {
          margin-top: 30px;
        }
        ul {
          list-style: none;
          li {
            label {
              cursor: pointer;
            }
            .shipping-method-message {
              .font-serif-italic(12px);
              margin-left: @margin-default*2;
            }
          }
        }
      }

      .brand-header {
        .make-md-column-offset(1);
        .brand-logo {
          .hidden-xs;
          .hidden-sm;
          svg {
            max-width: 392px;
            max-height: 90px;
            height: 100%;
          }
        }
        .name {
          .font-family-serif;
          margin-top: 5px;
          font-size: 40px;
          &.is-brand {
            display: none;
          }
          @media (max-width: @screen-sm-max) {
            font-size: 20px;
            text-align: center;
            &.is-brand {
              display: inherit;
            }
          }
        }
        .newsletter-subscription {
          display: inline-block;
          padding: 10px;
          input {
            margin-right: 5px;
          }
        }
      }

      .brand-items {
        .make-row();
        margin-top: 30px;

        .order-item {
          // sm, md & lg
          @media (min-width: @screen-sm-min) {
            margin-top: @grid-gutter-width;
            &:nth-child(1),
            &:nth-child(2) {
              margin-top: 0;
            }
            &:nth-child(odd) {
              .image-placeholder {
                .make-md-column-offset(1);
              }
            }
            &:nth-child(even) {
              .clearfix();
            }
          }

          // xs
          @media (max-width: @screen-xs-max) {
            margin-top: @grid-gutter-width / 2;
            &:first-child {
              margin-top: 0;
            }
            .clearfix();
          }

          .image-placeholder {
            .make-responsive-columns(2, 2, 3, 4);
            img {
              border: 1px solid #f5f5f5;
              max-width: 100%;
            }
          }

          .attribution {
            width: 200px;
            border: 2px solid #ccc;
            border-radius: 10px;
            text-align: center;
            background-color: #f9f9f9;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            h1 {
              font-size: 17px;
              margin-bottom: 0;
            }

            button {
              padding: 0 10px;
              border: none;
              cursor: pointer;
              font-size: 14px;
            }

            button:hover {
              background-color: #0056b3;
            }
          }

          .product-data {
            .make-responsive-columns(3, 3, 3, 8);
            .font-sans-serif(@font-size-small);
            overflow: hidden;
            .brand {
              .font-family-sans-serif(@font-size-small);
            }
            .final-sale, .pre-order {
              .font-family-sans-serif(@font-size-small);
              color: @tertiary-color;
            }
            .name {
              margin-top: 10px;
              .text-truncate-lines(3);
            }
            table {
              margin-top: 10px;
              width: 100%;
              .font-sans-serif(@font-size-small);
              tr td:first-child {
                width: 30%;
                .font-sans-serif(@font-size-small)
              }
            }
          }

          .sold-out-warning {
            display:none;
            margin-bottom:20px;
            color: @error-color;
            .font-sans-serif-bold(@font-size-small);
          }
          &.sold-out {
            .product-data {
              color: #7f7f7f;
              .sold-out-warning {
                display:block;
              }
              .price, .quantity {
                color: @error-color;
                text-decoration: line-through;
              }
            }
          }
        }
      }

    }
  }

  .checkout-step {
    .review {
      .order-items {
        .brand-container.sold-out {
          display: none;
        }
        .order-item.sold-out {
          display: none;
        }
      }
    }
  }
}
