#error-explosion-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;


  &.error-explosion-modal-hidden {
    background: rgba(255, 255, 255, 0);
    opacity: 0;
    visibility: hidden;
    &>ol {
      .transform(scale(0.8));
      &>li:last-child {
        background: red;
      }
    }
    button {
      display: none;
    }
    transition: all 0.2s;
  }

  background: rgba(100, 0, 0, 0.5);
  opacity: 1;
  transition: all 0.5s;

  z-index: 9999999;

  ol, li {
    list-style-type: none;
  }
  ol, li, div, button {
    padding: 0;
    margin: 0;
  }
  li {
    display: block;
  }

  font-style: normal;
  font-weight: normal;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 16px;

  .json-value {
    font-family: "Courier New", Courier, monospace;
  }
  .method-name {
    padding-left: 10px;
    font-size: 10px;
    color: #f33;
  }

  .close-btn {
    position: absolute;
    top: 34px;
    right: 34px;
    color: black;
    background: none;
    .flaticon-solid-x;
    .square(40px);
    font-size: 30px;
  }

  &>ol {
    position: absolute;
    top: 30px;
    bottom: 30px;
    right: 30px;
    left: 30px;
    overflow: scroll;
    background: rgba(255, 255, 255, 0.5);

    transform-style: preserve-3d;
    transition: all 0.5s;

    border: 8px solid red;

    &>li {
      padding: 2px;
      transition: background 0.5s;
      &:nth-child(even) {
        background: rgba(255,255,255, 0.2);
      }
      &:last-child {
        background: white;
        transition: background 2s;
      }

      li {
        display: inline-block;
        margin: 0 5px;
      }
    }
  }
}
