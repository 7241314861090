.brand-substores {
  a {
    margin-right: 5px;
  }
  .plain-select  {
    position: relative;
  }
  .plain-select:after{
    content: "";
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 45%;
    margin-top: 6px;
    border-top: 6px solid #ABA59B;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    @media (max-width: 479px) {
      top: 33%;
    }
  }
  select {
    -webkit-appearance: none;
  }
}
