.admin-order, .admin-return {
  padding-top: 25px;
  .order-number {
    text-align: center;
    h1 {
      .order-prefix {
        .test-flag {
          background-color: @diamond-blue;
          padding: 5px 10px;
        }
        .guest-flag {
          background-color: @diamond-blue;
          padding: 5px 10px;
        }
        .influencer-flag {
          background-color: @diamond-blue;
          padding: 5px 10px;
        }
        .app-flag {
          background-color: @diamond-blue;
          padding: 5px 10px;
        }
        padding: 5px 10px;
        font-size: 22px;
        display: block;
        clear: right;
      }
    }
    .risk {
      .font-sans-serif-bold;
      color: #008cdd;
      &.elevated {
        color: #f19906;
      }
    }
  }

  .order-provider {
    .partner-flag {
      background-color: @diamond-blue;
      padding: 2px 10px;
      font-size: 12px;
      text-transform: Uppercase;
      font-weight: bold;
    }
  }

  .boxes {
    .make-row();
    margin-top: 30px;
    &.international-order {
      .box {
        .make-responsive-columns(3, 3, 3, 12);
      }
    }
    .box {
      .make-responsive-columns(4, 4, 4, 12);
      margin-top: 15px;
      margin-bottom: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      border: 1px solid transparent;
      text-align: center;
      h3 {
        .font-sans-serif-bold(@font-size-small);
        margin-top: 0;
        .margin-bottom-medium;
      }
      .user-data {
        text-align: left;
        label {
          .font-sans-serif(@font-size-small);
          display: block;
          text-align: left;
          margin: 10px 0 0 0;
        }
        input {
          clear: both;
          width: 100%;
          padding: 3px 10px;
          .font-sans-serif(@font-size-small);
        }
        a {
          .font-sans-serif(@font-size-small);
          text-transform: none;
          text-decoration: underline;
        }
        .payment-provider-link {
          display: block;
          .font-sans-serif(@font-size-large);
          text-align: center;
          text-transform: uppercase;
        }
      }
      &.totals {
        border: 1px solid #f1f1f1;
        @media (max-width: @screen-xs-max) {
          border-color: transparent;
        }
        .font-family-sans-serif-light(@font-size-small);
        text-transform: uppercase;
        table {
          .make-responsive-width(100%, 100%, 100%, 60%);
          .margin-center;
          td {
            &:nth-child(odd) {
              .text-left;
            }
            &:nth-child(even) {
              .text-right;
            }
          }
          tr.total td {
            .padding-top-small;
            .font-family-sans-serif-bold(@font-size-small);
          }
        }
      }
    }
  }
  .detail-form {
    form > div {
      .make-row();
      & > div {
        .make-responsive-columns(6, 6, 12, 12);
      }
      & > div:only-child {
        .make-responsive-columns(6, 12, 12, 12);
      }
      &:last-child > div:only-child {
        .make-responsive-columns(12, 12, 12, 12);
      }
    }
    [type=submit] {
      float: right;
    }
    [type=number] {
      text-align: right;
    }

    .calculated-currency {
      text-align: right;
    }
  }
  .clipboard-icon {
    background-image: url('/styles/flaticons/solid/png/16/clipboard-checkmark-1.png');
    background-repeat: no-repeat;
    height: 16px;
    width: 16px;
    margin: 4px 0 0 5px;
    float: right;
    clear: right;
  }
  .nolink {
    text-decoration: none !important;
  }
}
