section#main{
  footer {
    @footer-background: #fafafa;
    @footer-color: #7d7d7d;
    background-color: @footer-background;
    .container-fluid;
    .footer-nav {
      margin-bottom: 0;
    }

    .copyright {
      .make-responsive-columns(12, 12, 12, 12);
      .text-center;
      .make-responsive-margin-bottom(41px,41px,41px,37px);
      .font-family-responsive-serif(12px,12px,12px, 10px);
      color: #5b5b5b;
    }

    .links {
      .text-center;
      .make-responsive-margin-bottom(37px,37px,37px,19px);
      .make-responsive-margin-top(37px,37px,37px,33px);
      .make-responsive-flex-direction(row, row, row, column);

      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: flex-start;
      position: relative;
      width: 94%;
      height: auto;
      max-height: 600px;

      .links--sublinks {
        .make-responsive-margin-bottom(15px, 15px, 15px,10px);
        .make-responsive-margin-top(15px, 15px, 15px, 10px);
        .make-responsive-margin-left-right(10px, 10px, 5px, 5px);
        .make-responsive-max-width(200px, 200px, 150px, 150px);

        ul {
          display: block;

          a {
            .link(@font-size-small);
            color: @new-light-gray;
            text-decoration: none;
          }

          li:first-child, li:first-child a {
            text-transform: uppercase;
            color: #666;

            .font-family-sans-serif-bold();
          }

          li {
            .make-responsive-font-size(@font-size-base,@font-size-base,@font-size-small, @font-size-small);
            .font-family-sans-serif(@font-size-small);
            .make-responsive-margin-left(18px,18px,10px,0px);
            .make-responsive-margin-right(18px,18px,10px,0px);
            .make-responsive-margin-bottom(0px,0px,0px,15px);

            @media (max-width: @screen-xs-max) {
              display: block;
            }

            text-wrap: normal;
            list-style: none;
            text-align: left;
            text-transform: capitalize;
          }
        }
      }
    }
    .social-networks{
      .text-center;
      .make-responsive-margin-top(45px,45px,45px,37px);

      a {
        .make-responsive-padding-left(20px, 20px, 20px, 5%);
        .make-responsive-padding-right(20px, 20px, 20px, 5%);
        font-size:16px;
        text-decoration: none;
        color: @footer-color;
        &:hover {
          color: @brand-main-color;
        }
        &.tiktok {
          top: 4px;
          position: relative;
          path {
            &:hover {
              fill: @brand-main-color;
            }
          }  
        }
      }
    }
  }
}
