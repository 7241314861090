.friend-referer,.anonymous-subscription, email-subscribe  {
  text-align: center;
  overflow: auto;
  .share-form{
    .make-responsive-margin-bottom(21px;21px; 21px; 5px);
    input {
      color: black;
    }
    a {
      color: white;
    }
    .referer-form {
      form {
        input, button {
          float: none;
        }
      }
    }
    .social-link-shareable {
      form {
        input, button {
          float: none;
        }
      }
    }
  }
  form {
    overflow: hidden;
  }
  .referer-form, .share-form, .social-link-shareable  {
    .make-responsive-width(40%,55%, 55%,100%);
    margin-left: auto;
    margin-right: auto;
    overflow: auto;

    .ng-submitted, .submitted, .visited {
      input.ng-invalid {
        border: 1px solid @error-color;
        .make-responsive-padding-top(12px;12px; 9px; 9px);
        .make-responsive-padding-bottom(12px;12px; 8px; 8px);
      }
    }
    input {
      float: left;
      .make-responsive-padding-top(11px;11px; 9px; 9px);
      .make-responsive-padding-bottom(11px;11px; 10.5px; 10.5px);
      padding-left: 10px;
      .font-sans-serif(@font-size-small);
      .make-responsive-width (60%;60%;60%;47%);
    }
    .ng-submitted {
      .error-summary {
        &.has-errors {
          display: inline-block;
        }
      }
    }
    .form-success-message{
      display: none;
      .font-family-responsive-serif-italic(26px, 26px, 20px, 16px);
      text-transform: capitalize;
      .make-responsive-line-height(40px, 40px, 30px, 30px);
    }
    .error-summary {
      float: left;
      overflow: auto;
      display: none;
    }
    button {
      max-width: 183px;
      .make-responsive-padding-top(12px,12px,10.5px,10px);
      .make-responsive-padding-bottom(12px,12px,10.5px,10px);
      .make-responsive-padding-left-right(30px,30px,30px,8px);
      .make-responsive-width(40%;40%;40%;23%);
    }
  }
  .email-list {
    clear:both;
    .make-responsive-width(35%,40%,40%,100%);
    text-align: left;
    margin-left: auto;
    margin-right: auto;

    ul {
      list-style:none;
      &:first-child{
        margin-top: @grid-gutter-width/2;
      }
      li {
        .font-sans-serif(@font-size-small);
        padding-bottom: 15px;
        &.success {
          .invite-feedback {
            .flaticon-solid-checkmark();
            color: @positive-feedback-color;
            padding-right: 15px;
          }
        }
        &.error {
          .invite-feedback {
            .flaticon-solid-exclamation-sign();
            color: @error-color;
            padding-right: 15px;
          }
        }
      }
    }
  }
}
.user-is-anonymous{
  footer {
    .friend-referer {
      display:none;
    }
    .anonymous-subscription, email-subscribe {
      display: block;
    }
  }
}
footer {
  .anonymous-subscription, email-subscribe {
    display: none;
  }
  .friend-referer,.anonymous-subscription, email-subscribe {
    .make-responsive-margin-top(37px,37px,37px,19px);
    .referer-form {
      .make-responsive-margin-top(21px;21px; 21px; 5px);
      .make-responsive-margin-bottom(21px;21px; 21px; 5px);
      input {
        border-radius: 0;
        -webkit-border-radius: 0;
        .make-responsive-margin-right(0,-24px,-24px,-5px);
      }
    }
    @media (max-width: @screen-xs-max) {
      padding: 0 15px;
      .text-center;
      .referer-form, .share-form, .social-link-shareable  {
        input, button {
          float: none;
          width: 230px;
          margin-right: 0;
        }
        button {
          width: 230px;
          display: block;
          max-width: 270px;
          margin: 10px auto;
        }
        .error-summary.has-errors {
          float: none;
          display: inherit;
          color: black;
        }
      }
      .email-list {
        .text-center;
      }
    }
    .share-form {
      display: none;
    }
    p.footer-copy {
      .font-family-responsive-serif-italic(30px, 30px, 26px, 22px);
      span {
        .font-family-responsive-serif-italic(25px, 25px, 21px, 16px);
      }
      text-transform: none;
    }
    p.checkout-copy, p.account-copy{
      display:none;
    }
  }
}
.loyalty-content {
  .friend-referer {
    .make-row;
    margin-top: 30px;
    margin-bottom: @grid-gutter-width;
    color: black;
    .referer-form, .social-link-shareable {
      .make-responsive-margin-top(38px;38px; 38px; 20px);
      .make-responsive-margin-bottom(21px;21px; 21px; 5px);
      width: 90%;
      input {
        color: #000;
        margin-right: -5px;
      }
    }
    p.footer-copy {
      display:none;
    }
    p.account-copy {
      margin-top: @grid-gutter-width;
      .make-responsive-font-size(14px; 12px; 12px; 10px);
    }
    p.checkout-copy {
      display: none;
    }
    .social-networks {
      padding-top: 10px;
      .make-responsive-font-size(16px; 14px; 14px; 12px);
      clear: both;
      a {
        margin-left: 5px;
        margin-right: 5px;
        :first-child{
          margin-left: 0px;
        }
        :last-child{
           margin-right: 0px;
        }
      }
    }
    .share-form {
      width: 90%;
      a {
        color: black;
      }
    }
    @media (max-width: @screen-xs-max) {
      padding: 0 15px;
      .text-center;
      .referer-form, .share-form {
        input, button {
          float: none;
        }
        .error-summary.has-errors {
          float: none;
          display: inherit;
          color: black;
        }
      }
      .email-list {
        .text-center;
      }
    }
  }
}
.anonymous-subscription, email-subscribe {
  .form-success {
    .form-success-message {
      display: block;
    }
    .invite-email, button{
      display: none;
    }
  }
  .referer-form, .social-link-shareable {
    input {
      width: 70%;
    }
    button {
      width: 30%;
    }
  }
}
.checkout-base-view {
  .friend-referer {
    display: none;
    .make-row;
    margin-top: 30px;
    margin-bottom: @grid-gutter-width;
    background-image: url(/img/order-referral-form.jpg);
    background-size: cover;
    color: #fff;
    .referer-form, .social-link-shareable {
      .make-responsive-margin-top(38px;38px; 38px; 20px);
      .make-responsive-margin-bottom(21px;21px; 21px; 5px);
      input {
        color: #000;
      }
      .error-summary{
        width: 100%;
        span {
          color: black;
        }
      }
    }
    p.footer-copy, p.account-copy {
      display:none;
    }
    p.checkout-copy {
      margin-top: @grid-gutter-width;
      .make-responsive-font-size(20px; 16px; 16px; 16px);
    }
    @media (max-width: @screen-xs-max) {
      padding: 0 15px;
      .text-center;
      .referer-form, .share-form, .social-link-shareable  {
        input, button {
          float: none;
        }
        button {
          width: auto;
        }
        .error-summary.has-errors {
          float: none;
          display: inherit;
          color: black;
        }
      }
      .email-list {
        .text-center;
      }
    }
  }
}
