.account-view {
  min-height: 990px;
  .container();
  .margin-bottom-medium;

  h1 {
    .margin-top-medium;
    .margin-bottom-medium;

    .title-alternative-word {
      .font-serif-italic(@font-size-xx-large);
    }

    text-align: center;
  }

  .account-content {
    .make-row();

    .details {
      .make-responsive-columns(8, 8, 8, 12);
      .make-responsive-columns-offset(2, 2, 3, 0);

      .anchor {
        display: block;
        height: 126px; /*same height as header*/
        margin-top: -126px; /*same height as header*/
        visibility: hidden;
      }

      .profile {
        text-align: center;

        .user-data {
          .key {
            margin-top: 20px;
            .font-family-sans-serif-bold(@font-size-x-small);
            color: #787878;
            text-transform: uppercase;
          }

          .help {
            .font-family-sans-serif-bold(@font-size-x-small);
            color: #787878;
          }

          .value {
            margin-top: 4px;
            .font-family-sans-serif-light(@font-size-large);
            .margin-bottom-small;
          }

          .email-address-validated {
            &.icon {
              position: relative;
              top: 3px;
              color: #008800;
              .flaticon-solid-checkmark;
            }
          }

          .email-address-not-validated {
            &.icon {
              position: relative;
              top: 3px;
              color: @brand-main-color;
              .flaticon-solid-x;
            }

            a {
              font-weight: 800;
              text-decoration: underline;
            }
          }

          &.account-phone-number .value, &.account-birthday .value {
            cursor: pointer;

            &:hover {
              color: @brand-main-color;
            }
          }

          &.account-phone-number form, &.account-birthday form, &.account-name form {
            .clearfix();
            padding-top: 10px;
            display: inline;

            input.form {
              .make-responsive-padding-top(11px; 11px; 9px; 9px);
              .make-responsive-padding-bottom(11px; 11px; 9px; 9px);
              .make-responsive-width(22%, 24%, 26%, 35%);
              .font-sans-serif(@font-size-small);
              padding-left: 10px;
              height: 48px;
              vertical-align: middle;

              &.name {
                .make-responsive-width(16%, 16%, 16%, 28%);
                margin-right: 5px;
              }

              &.birthday {
                &.day, &.month {
                  .make-responsive-width(6%, 6%, 6%, 10%);
                  padding-left: 7px;
                }

                &.year {
                  .make-responsive-width(8%, 8%, 8%, 14%);
                  margin-right: 5px;
                }
              }

            }

            button.form {
              margin-left: -5px;
              .make-responsive-padding-top(11px; 11px; 10px; 10px);
              .make-responsive-padding-bottom(11px; 11px; 8px; 8px);
              .make-responsive-width(13%, 13%, 15%, 18%);
              text-align: center;
              height: 48px;
              padding-left: 0;
              padding-right: 0;
              min-width: 0;
              vertical-align: middle;
            }

            button.calendar {
              background-color: transparent;
              margin-left: -79px;
            }

            .invalid {
              color: #f00;
            }
          }

        }
      }

      .popular-products {
        .product-list();

        .product-item-container {
          .make-responsive-columns(3, 3, 3, 6);
        }
      }
    }

    .summary-table {
      width: 100%;

      thead {
        tr {
          background-color: #f2f2f2;
          .font-family-sans-serif(@font-size-x-small);
          .make-responsive-line-height(38px, 38px, 38px, 38px);
          border-bottom: 0;
        }
      }

      tr {
        .make-responsive-line-height(50px, 50px, 50px, 50px);

        a, td {
          .font-family-sans-serif(@font-size-small);
        }

        text-transform: uppercase;
        border-bottom: 1px solid #f2f2f2;

        td, th {
          padding-left: @margin-default / 2;
          white-space: nowrap;

          .final-sale, .final-sale-mobile, .pre-order {
            color: @tertiary-color;
            div {
              color: @brand-watermark;
              p {
                color: @brand-gray-dark;
              }
            }      
          }

          .ships-international-mobile {
            font-weight: normal;
            word-wrap: normal;
          }
        }
      }

    }

    .order-history, .billing-options {
      h2 {
        text-align: center;
        .margin-bottom-medium;
      }

      i.x-1 {
        font-size: 30px;
        float: left;
      }

      .no-orders, .no-options {
        .font-serif-italic(@font-size-large);
        text-align: center;
      }
    }
  }

  .order-detail {
    .make-row();

    .loading {
      text-align: center;
      .font-serif-italic(@font-size-large);
    }

    .detail {
      .make-responsive-columns(8, 8, 8, 12);
      .make-responsive-columns-offset(2, 2, 2, 0);
    }

    h3 {
      .margin-top-large;
    }

    .flag {
      background-color: #ddd;
      margin-left: 5%;
      padding: 2px 10px;
      text-transform: capitalize;
    }

    .order-data-container {
      .row;
      .make-responsive-columns(12, 12, 12, 12);
      margin-left: auto;
      margin-right: auto;
      .make-responsive-margin-bottom(0px, 0px, 45px, 30px);

    }

    .order-data {

      .font-family-sans-serif(@font-size-small);
      text-transform: uppercase;
      .font-text-align-xs-center;

      span {
        .font-family-sans-serif-bold(@font-size-small);
        color: #787878;

        display: block;
        .margin-top-small;
        .margin-bottom-small;
      }

      .shipping, .billing {
        .make-responsive-columns(6, 6, 6, 12);
        .make-responsive-margin-top(0px; 0px; 0px; 30px);

        span {
          margin-top: 18px;
          margin-bottom: 8px;
        }

        .address-country {
          .font-family-sans-serif(@font-size-small);
          color: black;
          margin: 0;
        }
      }

      .number, .date {
        .make-responsive-columns(6, 6, 6, 6);

        span {
          margin-bottom: 8px;
        }
      }
    }

    .table-bottom {

      .margin-top-medium;

      .button-back {
        .make-responsive-columns(3, 3, 3, 12);
        .margin-bottom-small;
        text-align: center;
      }

      .totals {
        .make-responsive-columns(5, 6, 7, 12);
        .make-responsive-columns-offset(4, 3, 2, 0);
        .font-family-sans-serif(@font-size-small);
        text-transform: uppercase;
        .margin-bottom-medium;

        table {
          .make-md-column(12);
          margin-left: auto;
          margin-right: auto;
        }

        .title {
          .make-md-column(10);
        }

        .total {
          .font-family-sans-serif-bold(@font-size-small);
        }
      }
    }

    .separator {
      .margin-bottom-medium;
      .margin-top-medium;
      .padding-bottom-large;
      .padding-top-large;
      .hidden-lg;
      .hidden-md;
      clear: both;
      background-color: @brand-gray-light;
      .row;
    }

    .items-detail {
      margin-top: 5px;
      .margin-bottom-medium;
      .make-responsive-columns(12, 12, 12, 12);

      .table-header {
        text-align: center;

        h3 {
          display: inline-block;
          .margin-top-medium;
          margin-bottom: 8px;
        }

        h4 {
          .font-family-sans-serif(@font-size-medium-large);
          display: inline-block;
          position: relative;;
          text-transform: uppercase;
          border: 1px solid #000;
          margin-left: 5px;
          padding: 5px;
          text-align: center;
          width: -moz-max-content;
          width: -webkit-max-content;
          width: max-content;
        }
      }

      .items {
        .font-family-sans-serif(@font-size-small);

        thead {
          tr {
            .make-responsive-line-height(38px, 20px, 20px, 16px);
          }

          th.item-data {
            .hidden-xs;
            .hidden-sm;
          }
        }

        tr {
          .make-responsive-line-height(@font-size-base; @font-size-base; @font-size-base; @font-size-base);

          td.item-quantity, td.col-3, td.item-return {
            vertical-align: top;
            .padding-top-medium;
          }
        }

        .qty {
          .hidden-lg;
          .hidden-md;
        }

        .quantity {
          .hidden-sm;
          .hidden-xs;
        }

        .item-price {
          .make-responsive-width(15%; 15%; 15%; 15%);
          .hidden-xs;
          .hidden-sm;
        }

        .name {
          white-space: normal;
        }

        .item-return {
          white-space: nowrap;
          .make-responsive-width(28%; 28%; 28%; 28%);
          .hidden-xs;
          .hidden-sm;
        }

        .item-quantity, .final-sale, .ships-international, .pre-order {
          .hidden-xs;
          .hidden-sm;
        }

        .item-image {
          .make-responsive-width(45%; 45%; 45%; 100%);

          .image, .item-data {
            width: 45%;
            line-height: @font-size-base;
            height: 100%;
            display: inline-block;
            position: relative;
            .margin-bottom-medium;
            .margin-top-medium;

            img {
              width: 100%;

            }
          }

          .image {
            border: 1px solid #f5f5f5;
          }

          .item-data {
            padding-top: 0;
            .margin-top-small;
            width: 50%;

            .make-responsive-padding-left(15px; 10px; 10px; 5px);

            div {
              .padding-top-small;
            }

            @media (max-width: @screen-sm-max) {
              font-weight: bold;
              span {
                font-weight: normal;
              }
            }
          }
        }

        .extra-data {
          .hidden-lg;
          .hidden-md;
        }

        .item-return-mobile {
          .hidden-lg;
          .hidden-md;
          .margin-top-small;

          display: block;
        }

        .return {
          a {
            text-decoration: underline;
            .font-family-sans-serif-bold(@font-size-small);
          }
        }
      }
    }
  }

  .menu-panel {
    position: fixed;
    z-index: 3;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    opacity: .8;
    margin-bottom: 15px;
    width: 180px;
    padding: 0 15px;
    .hidden-xs;

    ul {
      list-style: none;
      margin-bottom: 0;

      li {
        margin-top: 0;
        padding-top: 10px;
        padding-bottom: 5px;
        margin-bottom: 0;
        border-bottom: 1px solid #e0e0e0;

        a {
          margin: 0;
          letter-spacing: 1px;
          font-family: 'OMBR', Arial, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 12px;
          line-height: 0;
          text-decoration: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          padding: 0;
        }

        &.active {
          a {
            font-weight: 800;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .nav {
      padding-left: 0;
      margin-bottom: 0;
    }
  }

  .scroll-label {
    margin-top: @margin-default*2;
  }

  .active {
    top: 144px !important;
  }
}

.with-sale-banner {
  .account-view .menu-panel {
    top: 275px;
  }
}

.black {
  color: #000;
}
