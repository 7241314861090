@modal-width-lg: 500px;
@modal-width-md: 500px;
@modal-width-sm: 500px;
@modal-width-xs: 256px;
@modal-animation-function: cubic-bezier(0.4, 0, 1, 1);

.modal-panel {
  display: block;
  position: relative;
  overflow: visible;
  .scrollbars-always-visible();
  form {
    padding: 0 15px;
  }
  .modal-view-container {
    position: absolute;
    height: 100%;
    width:100%;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    .full-message {
      text-align: center;
      padding: 80px 40px 150px 40px;
    }
    &.modal-cart {
      height: auto;
    }
  }
  .modal-header {
    text-align:center;
    margin-top: 40px;
    @media (max-height: 768px) {
      margin-top: @margin-default;
    }
    @media (max-height: 750px) {
      margin-top: 0;
    }
    border: 0;
    padding: 0;
    h4 {
      .font-serif;
      .font-size(@font-size-x-large; @font-size-x-large; @font-size-x-large; @font-size-medium-large);
      margin:0 auto;
      word-spacing: 2px;
      .make-responsive-line-height(@font-size-x-large+3px;@font-size-x-large+3px;@font-size-x-large+3px; @font-size-medium-large);
    }
    h5 {
      .font-serif(@font-size-x-small);
      .make-responsive-margin-top(5px, 5px, 5px, 7px);
      padding:0;
    }
    .brand-stitch {
      .margin-center;
    }
    p {
      .font-serif-italic;
      .font-size(@font-size-large; @font-size-large; @font-size-large; @font-size-medium);
      margin: -5px 0 @margin-default 0;
    }
  }
  .close-btn {
    float:right;
    position:relative;
    margin: 0 0 0 0;
    .make-responsive-padding(25px, 25px, 25px, 18px);
    background-color: transparent;
    .flaticon-solid-x;
    color: gray;
    border: none;
    font-size: 25px;
    &:focus {
      margin: 0 0 0 0;
      .make-responsive-padding(25px, 25px, 25px, 18px);
    }
    &:hover {
      opacity: 0.5;
      background-color: transparent;
    }
  }
  .modal-body {
    .make-responsive-padding(30px, 30px, 15px, 15px);
    padding: 28px;
    ul {
      padding-left: 0;
    }
    button {
      width: 100%;
    }
  }
  .modal-footer {
    text-align:center;
    margin:0;
    border:none;
    p {
      .font-sans-serif(@font-size-small);
      margin: 10px 0 30px 0;
    }
  }
  .content {
    position: fixed;
    top: 0;
    .make-responsive-right(-(@modal-width-lg);-(@modal-width-md);-(@modal-width-sm);-(@modal-width-xs));
    .make-responsive-width(@modal-width-lg;@modal-width-md;@modal-width-sm;100%);
    .transform(translateX(0));
    .transition-transform(0.2s @modal-animation-function);
    overflow-x: hidden;
    overflow-y: auto;

    box-shadow: -1px 0px 7px 0px rgba(32, 29, 30, 0.1);
    height: 100%;
    z-index: 1100;
    background-color: #fff;
  }
  .modal-overlay {
    visibility: hidden;
    background-color: black;
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    transition: opacity 1s;
    -moz-transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
  &.fully-closed {
    display: none;
  }
  &.open {
    display: block;
    .content {
      .make-responsive-translateX(-(@modal-width-lg);-(@modal-width-md);-(@modal-width-sm);-(@modal-width-xs));
    }
    .modal-overlay {
      visibility: visible;
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .modal-errors {
    text-transform: uppercase;
    .font-family-sans-serif-bold(@font-size-x-small);
    color: rgb(199, 78, 78);
    min-height: 60px;
    margin-top: 5px;
  }
}
.checkout-v2-view-cart .modal-view-container.modal-cart .steps {
  padding-top: 40px;
  .step-heading p {
    line-height: 1.2;
  }
  form {
    select, input {
      text-overflow: ellipsis;
      padding: 15px 55px 15px 20px;
    }
    .fulfiller .fulfiller-items .fulfiller-item {
      width: 100%;
      padding: 5px 0;
    }
  }
}
