.value-proposition {
  .modal-dialog {
    width: 970px;
    .modal-content {
      width: 970px;
      height: 600px;
      background: url(/img/value-proposition.jpg) no-repeat;
      border-radius: 0;

      .close-btn {
        position: absolute;
        top: 30px;
        right: 30px;
        display: inline-block;
        border: 0;
        .flaticon-solid-x-rotate;
        font-size: 20px;
        background: transparent;
        &:hover {
          opacity: 0.5;
        }
      }

      .copy-wrapper {
        width: 600px;
        .copy {
          margin: 120px auto;
          width: 400px;
          text-align: center;

          h3 {
            .font-family-serif(22px);
            text-transform: uppercase;
            margin: 20px 0 30px 0;
          }

          .brand-stich {
            margin: 27px auto;
          }

          .anonymous-subscription, email-subscribe {
            p {
              .font-sans-serif(@font-size-base);
              text-transform: uppercase;
            }
            .referer-form {
              margin-top: 60px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
