.product-scores-indicator .color-bar,
.product-scores-detail-modal .attribute-color-reference,
.product-ranking-modal .progress-bar
{
  .hotness {
    background-color:red;
  }
  .views {
    background-color:blue;
  }
  .cartToDetailRate {
    background-color:#0bf;
  }
  .buyToDetailRate {
    background-color:green;
  }
  .quantitySold {
    background-color:#bf0;
  }
  .revenue {
    background-color:#8f8;
  }
  .recency {
    background-color:yellow;
  }
  .offset {
    background-color: gray;
  }
}

.product-scores-indicator {
  .hidden-xs;
  .hidden-sm;
  position:relative;
  .color-bar {
    position:absolute;
    height:20px;
    width: 20px;
    background-color:grey;
    cursor:pointer;
    .part {
      margin:0;
      width:100%
    }
  }
}
.product-scores-detail-modal {
  .modal-dialog{
    .modal-content {
      .modal-body {
        .style-list {
          .font-family-sans-serif(@font-size-x-small);
          li {
            display: inline-block;
            margin-left: 5px;
          }
          .best-score {
            font-weight: bolder;
          }
        }
        ul {
          margin-left:15px;
          list-style: none;
        }
        .attribute-color-reference {
          display: inline-block;
          width: 10px;
          height: 10px;
          & > div {
            width: 100%;
            height: 100%;
          }
        }
        .attribute-value.negative {
          color:red;
          &:before {
            content: '('
          }
          &:after {
            content: ')'
          }
        }
        .remove-offset {
          color: red;
          margin-left: @margin-default*2;
          font-weight: bolder;
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
  }
}
