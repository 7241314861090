.occasion-filter {
  .font-sans-serif(@font-size-small);
  border-top: 1px solid #f1f1f1;
  margin-left:@margin-default;
  margin-right:@margin-default;
  .panel-title {
    .font-sans-serif(@font-size-small);
    height:30px;
    padding-top: 8px;
    text-align: left;
    @media (max-width: @screen-xs-max) {
      .title {
        .font-family-sans-serif(@font-size-medium);
        color: #333;
        text-transform: uppercase;
        margin:0;
      }
    }
  }
  .icon {
    float:right;
  }
  .reset {
    float:right;
    margin-right:@margin-default;
    text-decoration: underline;
  }
  .occasion-list {
    list-style: none;
    margin-left: @margin-default;
    li {
      margin-bottom: 5px;
      a {
        padding-top: 10px;
      }
      a.active {
        font-weight: bolder;
      }
      a.disabled {
        color: #BBB;
      }
    }
  }
}
