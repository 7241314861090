.checkout-v2-view {
  .review {
    .warn-block {
      background-color: #fbe7e9;
      border: 1px solid #de5b5b;
      text-align: center;
      float: left;
      margin-top: 7.5px;
      margin-bottom: 13px;
      padding: 12px;
      font-family: 'OMBR',Arial,sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 12px;
    }

    .error-summary {
      .make-responsive-columns(10, 10, 12, 12);
      .make-responsive-columns-offset(1, 1, 0, 0);
      .make-responsive-margin-bottom(30px, 30px, 0, 0);
      .font-serif();
      .make-responsive-font-size(@font-size-medium-large,@font-size-medium-large,@font-size-medium,@font-size-medium);
      .incident{
        .make-responsive-margin-top(10px, 10px, 0, 0);
        .make-responsive-font-size(@font-size-medium,@font-size-medium,@font-size-medium,@font-size-medium);
      }
    }

    .invalid-state-or-zip-code {
      background-color: @error-color-light;
      color: @error-color;
      .zip-feedback-icon {
        display: inline-block;
        .flaticon-solid-exclamation-sign();
        color: @error-color;
        font-size: 16px;
        padding-left: 4px;
      }
    }

    .fulfillers {
      display: none;
      padding: 0 @grid-gutter-width/2;
    }

    .totals {
      .font-sans-serif(@font-size-small);
      padding: 0 @grid-gutter-width/2;
      table {
        .make-responsive-width(100%, 100%, 100%, 100%);
        .margin-center;
        text-transform: uppercase;
        td {
          &:nth-child(odd) {
            .text-left;
          }
          &:nth-child(even) {
            .text-right;
          }
        }
        tr.total td {
          .padding-top-small;
          .font-family-sans-serif-bold(@font-size-small);
        }
      }
      .discount {
        color: @brand-main-color;
      }
    }

    .btn-action {
      margin: @grid-gutter-width auto;
    }
  }
}
