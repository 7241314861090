.gift-card-promo-code {
  float: left;
  width: 100%;
  clear: both;
  margin-bottom: @margin-default;
  margin-left: @margin-default/2;

  @media (max-width: @screen-xs-max) {
    margin: @margin-default @margin-default 0 @margin-default;
    clear: both;
  }

  .gift-card-promo-links {
    a {
      text-decoration: underline;
      .font-sans-serif(@font-size-small);
      padding-right: 15px;
    }
    .enter-gift-card-link:after {
      .flaticon-solid("\e0e9");
    }
  }

  .gift-card-promo-message {
    word-break: normal;
  }

  .gift-card-container, .promo-code-container {
    .make-responsive-columns(6, 6, 12, 12);
    padding-left: 0;
    padding-right: 0;
    clear: both;
    .gift-card-input-panel {
      .make-responsive-columns(12, 12, 6, 10);
    }
    .promo-code-input-panel {
      .make-responsive-columns(12, 12, 6, 10);
    }
    .gift-card-input-panel, .promo-code-input-panel {
      float: none !important;
      padding: 0;
      white-space: nowrap;
      .gift-card-applied-message, .promo-code-applied-message {
        white-space: normal;
        .font-sans-serif(@font-size-small);
        margin: 5px;
        .currency-value {
          font-weight: bold;
        }
        .promo-description {
          text-transform: uppercase;
        }
        .form-failure-message {
          display: block;
          margin-top: 5px;
        }
      }
      .welcome-reward-removed {
        .font-sans-serif(@font-size-small);
        color: red;
        white-space: normal;
        margin: 5px;
      }
      .gift-card-remove, .promo-code-remove {
        .flaticon-solid-x;
        .font-sans-serif(@font-size-large);
        padding: 10px;
      }
      button {
        margin: 0;
        padding: 12px 12px;
        min-width: 80px;
        @media (max-width: @screen-xs-max) {
          padding: 12px 4px;
          min-width: 50px;
        }
      }
    }
    &.gift-card-code-complete {
      .error-summary.has-errors {
        display: block;
      }
    }
  }
}
