.make-stitch(@width-md, @height-md, @width-sm, @height-sm) {
  width: @width-md;
  height: @height-md;
  margin-left: 0;
  background-color: @brand-main-color;
  @media (max-width: @screen-sm-max) {
    width: @width-sm;
    height: @height-sm;
  }
}
.make-stitch() {
  .make-stitch(40px, 10px, 20px, 5px);
  .margin-top-bottom-medium;
  .make-responsive-margin-top(25px, 25px, 15px, 15px);
  .make-responsive-margin-bottom(30px, 30px, 20px, 20px);
}
.make-stitch-small() {
  .make-stitch(20px, 5px, 20px, 5px);
  .margin-top-bottom-small;
}

.brand-stitch {
  .make-stitch;
  &.small {
    .make-stitch-small;
  }
  &.center {
    .margin-center;
  }
}