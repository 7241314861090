.loyalty-view {
  // min-height: 990px;
  .margin-bottom-medium;
  .text-center;
  .banner-loyalty-om-rewards {
    padding-top: 50px;
    .text-center {
      width: 90%;
      margin: 0 auto;
    }
  }
  h1 {
    .margin-bottom-medium;

    .title-alternative-word {
      .font-serif-italic(@font-size-xx-large);
    }
  }

  .loyalty-content {
    .anchor {
      display: block;
      height: 126px;
      /*same height as header*/
      margin-top: -126px;
      /*same height as header*/
      visibility: hidden;
    }

    h2.title-loyalty {
      .make-responsive-font-size(@font-size-xl-m-large; @font-size-xl-large; @font-size-x-large; @font-size-x-big-large);
    }

    .tier-banner {
      background: no-repeat center top;
      background-size: cover;
      .make-responsive-text-align(right, right, right, center);
      .make-responsive-line-height(300px, 300px, 183px, 183px);

      .text-banner {
        background-color: rgba(255, 255, 255, 0.7);
        display: inline-block;
        vertical-align: middle;
        .make-responsive-margin-right(10%, 10%, 10%, 0);
        padding-top: 7px;
        padding-bottom: 7px;
        .make-responsive-padding-left(21px, 21px, 18px, 8px);
        .make-responsive-padding-right(21px, 21px, 18px, 8px);
        .text-center;

        h1 {
          margin-bottom: 15px;
          margin-top: 10px;
          .make-responsive-font-size(@font-size-xl-large; @font-size-x-large; @font-size-x-large; @font-size-x-big-large);
          .font-sans-serif-bold;
          .make-responsive-letter-spacing(5px, 5px, 4px, 3px);
        }
      }
    }

    .movements-history {
      margin: 0 0 5em 0;

      .movements-table {
        width: 70%;
        margin: 0 auto;

        thead {
          tr {
            background-color: #f2f2f2;
            .font-family-sans-serif(@font-size-small);
            line-height: 47px;
            border-bottom: 0;

            th {
              border-right: 4px solid white;
              line-height: 1.4;
              .make-responsive-padding-top-bottom(1.55em, 1.55em, 0.85em, 0.85em);
            }
          }
        }

        tfoot {
          tr {
            td {
              background-color: #f2f2f2;
              .font-family-sans-serif(@font-size-small);
              line-height: 38px;
              border-bottom: 0;
              padding-top: 5px;
              padding-bottom: 5px;
            }
          }

          .total-points-text {
            text-align: right;
            .make-responsive-padding-right(50px, 40px, 30px, 20px);
          }
        }

        tr {

          a,
          p,
          td {
            .font-family-sans-serif(@font-size-small);
            padding-top: 17px;
            padding-bottom: 17px;
            margin-bottom: 0px;
          }

          p,
          a {
            padding-top: 0px;
            padding-bottom: 0px;
            color: #333;
          }

          text-transform: uppercase;
          border-bottom: 1px solid #f2f2f2;

          td,
          th {
            padding-left: @margin-default / 1.5;
            padding-right: @margin-default / 4;
            .text-left;
          }

          .pending-points {
            color: #868686;

            p {
              color: #868686;
            }
          }
        }
      }
    }

    .no-points,
    .loading {
      .font-serif-italic(@font-size-large);
      .text-center;
      margin: 0 0 5em 0;
    }

    .redeem-content {
      text-align: center;
      width: 100%;

      .rewards-cards {
        .font-family-sans-serif(@font-size-x-small);
        line-height: 38px;
        border-bottom: 0;

        .reward-card {
          .clearfix();
          .make-md-column(4);
          display: inline-block;
          width: 280px;
          height: 400px;

          .reward-image {
            border: 1px solid;
            border-color: #f9f9f9;

            img {
              max-width: 100%;
              max-height: 100%;
              margin: auto;
            }
          }

          .reward-detail {
            height: 110px;

            h4 {
              text-transform: capitalize;
              .text-center;
              margin-top: 10px;
              .font-sans-serif(@font-size-base);
              color: #000;
            }

            .description {
              line-height: 1.14;
              text-align: center;
              color: #868686;
              margin-top: 5px;
              font-size: @font-size-base;
              .font-sans-serif(@font-size-small);
            }

            .points {
              text-align: center;
              line-height: 1.14;
              color: #4a4a4a;
              .font-family-serif();
              font-size: @font-size-base;
              margin-top: 10px;
            }
          }
        }
      }
    }

    .referrer-friends {
      margin: 5em 0;

      .social-networks {
        padding-bottom: 0px;

        a {
          font-size: @font-size-x-large;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      .share-form,
      .social-link-shareable,
      .social-networks {
        .padding-top-medium;
        .padding-bottom-medium;
      }

      .social-link-shareable {
        padding-top: 0px;
      }

      .referer-form {
        margin-top: 0px;
      }

      .title-explain {
        .font-serif(@font-size-medium-large);
      }

      h2 {
        .margin-top-medium;
        .text-center;
        clear: both;
      }

      .referrer-friends-details {
        .text-center;

        .info {
          .font-family-sans-serif-light(@font-size-large);
          min-height: 250px !important;
          border-right: 1px solid rgb(238, 238, 238);
          .make-responsive-columns(3, 3, 3, 12);
          padding-top: 30px;

          &.last {
            border-right: 0px;
          }

          &.center {
            .make-responsive-padding-right(5%, 5%, 2%, 0%);
            .make-responsive-padding-left(5%, 5%, 2%, 0%);
          }

          .number-indicator {
            .font-serif-italic(@font-size-big-large * 2);
            position: relative;
            float: left;
            margin-left: auto;
            margin-right: auto;
            padding-top: 43px;
            padding-bottom: 82px;
            width: 100%;
            height: 100%;

            &.circle {
              margin-top: 45px;
              margin-bottom: -70px;
              padding-bottom: 0px;
              padding-top: 0px;
              .make-responsive-margin-bottom(-70px, -70px, 80px, 80px);
            }
          }
        }

        margin-left: auto;
        margin-right: auto;
      }

      .brand-stitch {
        margin-left: auto;
        margin-right: auto;
      }

      h3 {
        .text-center;
        .margin-top-small;
        .margin-bottom-small;
      }
    }

    .how-works,
    .member,
    .faq,
    .terms {
      margin: 5em 0;
    }
  }

  .menu-panel {
    position: fixed;
    text-align: left;
    z-index: 3;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    opacity: .8;
    margin-bottom: 15px;
    width: 180px;
    padding: 0 15px;
    .hidden-xs;

    ul {
      list-style: none;
      margin-bottom: 0;

      li {
        margin-top: 0;
        padding-top: 10px;
        padding-bottom: 5px;
        margin-bottom: 0;
        border-bottom: 1px solid #e0e0e0;

        a {
          margin: 0;
          letter-spacing: 1px;
          font-family: 'OMBR', Arial, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 12px;
          line-height: 0;
          text-decoration: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          padding: 0;
        }

        div {
          padding-top: 7px;

          label {
            margin: 0;
            letter-spacing: 1px;
            font-family: 'OMBR', Arial, sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
            line-height: 0;
            text-decoration: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            padding: 0;
          }
        }

        &.active {
          a {
            font-weight: 800;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .nav {
      padding-left: 0;
      margin-bottom: 0;
    }
  }

  .scroll-label {
    margin-top: @margin-default*2;
  }

  hr {
    border-top: 1px solid #a5a5a5;
  }

  .referrer-friends .share-form {
    button {
      @media (max-width: @screen-xs-max) {
        width: auto;
      }
    }

    form {
      margin-top: 14px;
    }
  }

  table.loyalty {
    box-shadow: 1px 1px 9px 0px #00000059;
    border-top: 0 solid #ddd;
    .font-sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    width: 80%;
    margin: 20px auto;

    th:first-child {
      font-weight: 200;
      line-height: 1.3;
      .text-left;
      .make-responsive-width(auto, auto, auto, 40%);
      .make-responsive-font-size(@font-size-base; @font-size-base; @font-size-base; @font-size-small);
      .make-responsive-line-height(1.3; 1.3; 1.3; 1);
      .make-responsive-padding-right(auto, auto, auto, 7px);
    }

    th {
      padding: 10px 0;
      .make-responsive-width(25%, 25%, 25%, 20%);
      .text-center;

      @media (max-width: @screen-xs-max) {
        line-height: 1;
        padding: 21px 20px 8px 7px;
      }
    }

    div.box-tier {
      color: #697E88;
      letter-spacing: 2px;
      .make-responsive-width(92%, 92%, 92%, 138px);
      .make-responsive-height(auto, auto, auto, 55px);
      .make-responsive-padding-top-bottom(20px, 20px, 20px, 10px);
      .make-responsive-padding-left-right(0, 0, 0, 12px);
      .make-responsive-font-size(@font-size-large-medium; @font-size-large-medium; @font-size-large; @font-size-medium-large);
      .make-responsive-rotate(0deg; 0deg; 0deg; -90deg);
      .make-responsive-left(auto, auto, auto, -61%);

      @media (max-width: @screen-xs-max) {
        position: absolute;
      }
    }

    div.box-silver {
      background-color: white;
      color: #4a4a4a;
      font-size: 63px;
      width: 92%;
      .make-responsive-padding-top(2px, 2px, 2px, 3px);
      .make-responsive-padding-bottom(46px, 46px, 46px, 15px);
      .make-responsive-height(auto, auto, auto, 70px);
    }

    div.box-gold {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 46px;
      padding: 0;
      background: white;
      width: 92%;
    }

    thead tr {
      background-color: #f2f2f2;
      .make-responsive-height(80px, 80px, 80px, 186px);

      th {
        .text-center;

        @media (max-width: @screen-xs-max) {
          padding: 0 21px 39px 7px !important;
          position: relative;
          margin: 0 auto;
        }
      }
    }

    tbody tr:first-child{
      background-color: #f2f2f2;
      height: 48px;
      td {
        font-style: italic;
      }
    }

    tr th:first-child {
      .themile-primary;
      color: white;
      padding: 0 15px;
      .font-family-serif-italic;
      text-align: right;
    }

    tr:not(:first-child) {
      height: 60px;
    }
  }

  .collapsible {
    .panel .panel-heading {
      a.collapsed .panel-title {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: 100;
        color: #4a4a4a;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      a .panel-title {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-weight: 100;
        margin-top: 15px;
        margin-bottom: 15px;
        color: black;
        .font-family-serif-italic();
        .make-responsive-font-size(@font-size-big-large, @font-size-big-large, @font-size-large-medium, @font-size-large);
      }
    }

    .panel-collapse div {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      line-height: 1.3;
      .font-family-sans-serif();
      .make-responsive-font-size(@font-size-medium-large, @font-size-medium-large, @font-size-medium, @font-size-small);
      .text-left;
    }

    .panel {
      border-top: #b8b8b8 solid 0px;
      line-height: 25px;
      min-height: 40px;
    }

    .panel:last-child {
      border-bottom: #b8b8b8 solid 0;
    }
  }

  .active {
    top: 144px !important;
  }

  .parent-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    background-color: #F5F5F5;
    @media (max-width:768px) {
      flex-direction: column;
    }
  }
  .child-div {
    flex: 1;
    margin: 0;
    align-items: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    &.middle-text {
      justify-content: space-between;
      .logo {
        width: 200px;
        margin-bottom: 40px;
      }
      .points {
        display: flex;
        width: 100%;
        justify-content: space-around;
        padding-bottom: 20px;
        @media (max-width:1024px) {
          flex-direction: column;
          align-items: center;
          gap: 10px;                
        }
        .line {
          height: 100%;
          width: 3px;
          background-color: @themile-primary-medium;
        }
        .tier-text {
          display: flex;
          align-items: center;
          .font-sans-serif;
          b {
            color: @themile-primary;
            padding-right: 15px;
            font-size: 110%;
          }
          img {
            width: 40px;
          }
        }
      }
      .btn.themile-primary {
        margin-bottom: 50px;
        margin-top: 20px;
      }
      .empty-wrapper {
        height: 80px;
      }
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  .member-wrapper {
    display: flex;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-around;
    @media (max-width:768px) {
      flex-direction: column;
    }
    .circle-wrapper {
      background-color: #F4F4F4;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    h4 {
      margin-bottom: 3px;
    }
    p {
      margin-bottom: 10px;
    }
  }
    button.btn-loyalty {
      background: white;
      color: @themile-primary-dark;
      border-color: @themile-primary-dark;
      margin: 10px;
      &:hover {
        background: @themile-primary-light;
      }
    }      
    button.the-mile-btn  {
      color: white;
      background-color: @themile-primary;
      margin: 10px;
      max-width: 280px;
      width: 100%;
      &:hover {
        background: @themile-primary-medium;
      }
      a {
        color: white;
      }
    }     

  .tier-section {
    padding: 3em 0;
    .tile-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 10px 10px;
      padding: 19.78px;
      position: relative;
      justify-content: center;
      max-width: 1200px;
      margin: 0 auto;
    }

    .tile-list {
      .list-wrapper {
        display: flex;
        flex-direction: column;
        width: 217.6px;
        height: 171.12px;
        align-items: center;
        justify-content: space-between;
        padding: 29.67px 19.78px 19.78px;
        position: relative;
        background-color: #ffffff;
        border-radius: 5.93px;
        overflow: hidden;
        &.soon {
          opacity: 0.35;
          position: relative;
          overflow: hidden;
        }
        .coming-soon {
            position: absolute;
            background-color: #8B9FA8;
            padding: 0 98px;
            font-size: .8%;
            color: white;
            transform: rotate(-30deg);
            z-index: 1;
            right: -34px;
            bottom: 22px;
            h5 {
              .font-family-sans-serif;
            }
        }

        &.obtained-wrapper {
          opacity: 0.5;
          position: relative;
          overflow: hidden;
          .obtained {
            position: absolute;
            background-color: @themile-primary;
            padding: 0 43px;
            font-size: .8%;
            color: white;
            transform: rotate(-45deg);
            z-index: 1;
            right: -47px;
            bottom: 21px;
            h5 {
              .font-family-sans-serif;
            }
          }
        }
      }
    }

    .tile-list .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      align-self: stretch;
      width: 100%;
      flex: 0 0 auto;
    }

    .tile-list .img {
      position: relative;
      width: 23.74px;
      height: 23.74px;
    }

    .tile-list .text {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      flex: 0 0 auto;
    }

    .tile-list .title {
      position: relative;
      width: fit-content;
      margin-top: -0.99px;
      .font-sans-serif;
      font-weight: 700;
      color: #777;
      font-size: 15px;
      text-align: center;
      letter-spacing: 0;
      line-height: 29.7px;
      white-space: nowrap;
    }

    .tile-list .description {
      position: relative;
      width: fit-content;
      margin-top: -8.9px;
      .font-sans-serif;
      font-weight: 400;
      color: #777;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0;
      line-height: 27.7px;
      white-space: nowrap;
    }

    .tile-list .button {
      position: relative;
      width: 100.89px;
      height: 25.72px;
      border: 0.99px solid;
      border-color: @themile-primary;
      cursor: pointer;
      display: flex;
      justify-content: center;
      &:hover {
        background-color: @themile-primary-light;
      }
    }

    .tile-list .primary {
      position: absolute;
      top: 3px;
      left: 35px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .text-wrapper {
      position: absolute;
      top: 3px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .primary-2 {
      left: 17px;
      position: absolute;
      top: 3px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .primary-3 {
      left: 27px;
      position: absolute;
      top: 3px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .primary-4 {
      left: 39px;
      position: absolute;
      top: 3px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .primary-5 {
      left: 21px;
      position: absolute;
      top: 3px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .primary-6 {
      left: 33px;
      position: absolute;
      top: 3px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .primary-7 {
      left: 36px;
      position: absolute;
      top: 3px;
      .font-sans-serif;
      font-weight: 600;
      color: @themile-primary;
      font-size: 10.6px;
      text-align: center;
      letter-spacing: 0.32px;
      line-height: 17.7px;
      white-space: nowrap;
    }

    .tile-list .frame {
      position: relative;
      width: 27.97px;
      height: 27.97px;
    }
  }
  button.close-btn:hover {
    background-color: transparent!important;
    color: #0f0f0f;
  }
}

.with-sale-banner {
  .loyalty-view .menu-panel {
    top: 275px;
  }
}

/* MODALS */
.loyalty-experience {
  padding: 15px;
  &.loyalty-confirmation {
    p {
      .make-responsive-font-size(@font-size-large, @font-size-large, @font-size-large, @font-size-medium);
      .make-responsive-max-width(490px, 490px, 490px, 90%);
      margin-left: auto;
      margin-right: auto;
    }

    img {
      .margin-bottom-small;
      .margin-top-small;
      .make-responsive-max-width(30%, 30%, 40%, 60%);
      max-height: 130px;
    }

    button.confirm-btn {
      background-color: @brand-main-color-light;
      color: #000;
      .margin-top-small;
      max-width: 490px;
      width: 100%;
    }
  }

  .make-responsive-padding(30px, 30px, 30px, 15px);

  .redeem-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .description {
    .make-responsive-max-height(220px, 220px, 220px, 100%);
    overflow-y: auto;
  }

  .name,
  .partner,
  .points {
    margin-bottom: 12px;
  }

  .name {
    .font-sans-serif(@font-size-large);
  }

  .partner {
    .make-responsive-margin-top(0, 0, 20px, 20px);
    .font-serif;
    .font-size(@font-size-x-large; @font-size-x-large; @font-size-big-large; @font-size-big-large);
    text-transform: capitalize;
  }

  .experience-img {
    border: 1px solid @brand-gray-light;
    background-color: #fff;
    .text-center;
    .make-responsive-height(390px, 390px, 390px, 325px);

    img {
      .make-responsive-max-height(385px, 385px, 385px, 320px);
    }
  }

  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    display: inline-block;
    border: 0;
    .flaticon-solid-x-rotate;
    font-size: @font-size-large-medium;
    line-height: 25px;
    background: transparent;
    color: black;
    z-index: 20;

    &:hover {
      opacity: 0.5;
      background-color: transparent!important;
    }
  }
}
.loyalty-view.redeem {
  button:focus-visible {
    outline: 2px solid #4a90e2 !important;
    outline: -webkit-focus-ring-color auto 5px !important;
  }  
  .frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 29px;
    position: relative;
  }
  
  .frame .element-points-mile {
    position: relative;
    align-self: stretch;
    margin-top: -1px;
    font-family: "Open Sans", Helvetica;
    font-weight: 700;
    color: #000000;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.7px;
    line-height: 28px;
  }
  
  .frame .div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    @media (max-width: 768px) {
      flex-direction: column;
      .rectangle {
        display: none;
      }
    }
  }
  
  .frame .activity-rules {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 147px;
    align-items: center;
    justify-content: space-between;
    padding: 30px 20px 20px;
    position: relative;
    background-color: #ffffff;
    border-radius: 6px;
  }
  
  .frame .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
  }
  
  .frame .text {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 0 0 auto;
  }
  
  .frame .title {
    position: relative;
    width: fit-content;
    margin-top: -1px;
    font-family: var(--loyaltylion-title-font-family);
    font-weight: var(--loyaltylion-title-font-weight);
    color: var(--textheading);
    font-size: var(--loyaltylion-title-font-size);
    text-align: center;
    letter-spacing: var(--loyaltylion-title-letter-spacing);
    line-height: var(--loyaltylion-title-line-height);
    white-space: nowrap;
    font-style: var(--loyaltylion-title-font-style);
  }
  
  .frame .description {
    position: relative;
    width: fit-content;
    margin-top: -9px;
    font-family: var(--loyaltylion-description-font-family);
    font-weight: var(--loyaltylion-description-font-weight);
    color: var(--textsecondary-text);
    font-size: var(--loyaltylion-description-font-size);
    text-align: center;
    letter-spacing: var(--loyaltylion-description-letter-spacing);
    line-height: var(--loyaltylion-description-line-height);
    white-space: nowrap;
    font-style: var(--loyaltylion-description-font-style);
  }
  
  .frame .component {
    display: flex;
    width: 180px;
    height: 36px;
    align-items: center;
    justify-content: center;
    gap: 8.3px;
    padding: 0px 16.59px;
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    background-color: var(--secondary-30-0alpha-100);
  }
  
  .frame .button-wrapper {
    display: inline-flex;
    align-items: flex-start;
    position: relative;
    flex: 0 0 auto;
  }
  
  .frame .button {
    all: unset;
    box-sizing: border-box;
    position: relative;
    width: fit-content;
    margin-top: -1.04px;
    font-family: "Open Sans", Helvetica;
    font-weight: 600;
    color: #464646;
    font-size: 12px;
    letter-spacing: 0.6px;
    line-height: 20.7px;
    white-space: nowrap;
  }
  
  .frame .rectangle {
    position: absolute;
    right: -8px;
    height: 100%;
    top: 0;
    width: 1.91px;
    background-color: var(--primary-30-0alpha-100);
    border-radius: 15.31px;
    opacity: 0.5;
  }
  .frame .wrapper-box {
    position: relative;
    &:last-child .rectangle {
      display: none;
    }
  }
  .frame .text-wrapper {
    position: relative;
    width: auto;
    margin-top: -9px;
    font-family: var(--loyaltylion-description-font-family);
    font-weight: var(--loyaltylion-description-font-weight);
    color: var(--textsecondary-text);
    font-size: var(--loyaltylion-description-font-size);
    text-align: center;
    letter-spacing: var(--loyaltylion-description-letter-spacing);
    line-height: var(--loyaltylion-description-line-height);
    font-style: var(--loyaltylion-description-font-style);
  }
  
  .frame .frame-wrapper {
    display: flex;
    width: 180px;
    height: 36px;
    align-items: center;
    justify-content: center;
    gap: 8.3px;
    padding: 0px 16.59px;
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: -16px;
    background-color: var(--secondary-30-0alpha-100);
  }
}
:root {
  --textheading: rgba(85, 85, 85, 1);
  --textsecondary-text: rgba(119, 119, 119, 1);
  --primary-30-0alpha-100: rgba(197, 212, 219, 1);
  --secondary-30-0alpha-100: rgba(228, 224, 219, 1);
  --textwhite: rgba(255, 255, 255, 1);
  --grayscaleheadings: rgba(68, 68, 68, 1);
  --themeprimary: rgba(76, 55, 74, 1);
  --white: rgba(255, 255, 255, 1);
  --loyaltylion-title-font-family: "Assistant", Helvetica;
  --loyaltylion-title-font-weight: 700;
  --loyaltylion-title-font-size: 17px;
  --loyaltylion-title-letter-spacing: 0px;
  --loyaltylion-title-line-height: 30px;
  --loyaltylion-title-font-style: normal;
  --loyaltylion-description-font-family: "Assistant", Helvetica;
  --loyaltylion-description-font-weight: 400;
  --loyaltylion-description-font-size: 16px;
  --loyaltylion-description-letter-spacing: 0px;
  --loyaltylion-description-line-height: 28px;
  --loyaltylion-description-font-style: normal;
  --button-font-family: "Assistant", Helvetica;
  --button-font-weight: 400;
  --button-font-size: 16px;
  --button-letter-spacing: 0px;
  --button-line-height: normal;
  --button-font-style: normal;
}
.loyalty-redeem {
  .modal-lg {
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
  }
}
.modal-lg {
  &:has(> .loyalty-experience) {
    width: 500px;
  }
}