.error-page {
  .container();
  header {
    .make-md-column(8);
    .make-md-column-offset(2);
    .margin-top-large;
    h1 {
      text-align: center;
    }
  }
  .create-page {
    padding: 30px;
    text-align: center;
    a {
      .make-button();
    }
  }
}
