.breadcrumbs {
  .hidden-xs;
  z-index: 25;
  position:relative;
  clear: both;
  background-color: transparent;
  overflow:hidden;
  margin-bottom:5px;
  margin: 0 30px;
  .breadcrumb-container {
    display:inline;
  }
  .breadcrumb, .breadcrumb-separator {
    padding-left:4px;
    .font-sans-serif(@font-size-x-small);
    color: #767676;
    text-transform: capitalize;
  }
  .lowercase {
    text-transform: lowercase;
  }
  a.breadcrumb:hover {
    color: #888;
    text-decoration: underline;
  }
}
