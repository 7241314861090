.brand-assets-include {
}

.fancybox-wrap.brand-assets {
  .fancybox-prev span {
    color: #777;
  }
  .fancybox-next span {
    color: #777;
  }
}
