.make-error() {
  background-color: @error-color-light;
  border: 1px solid @error-color;
  text-align: center;
  .margin-top-small;
  padding: 12px;
  .font-sans-serif(@font-size-small);
  ul, ol {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }
  a {
    font-weight: bolder;
  }
}

.error-summary {
  .make-error;
}