@column-height: 420px;
.with-sale-banner {
  .dropdown-nav {
    top: @header-height-sm;

    &.nav-active {
      position: absolute;
      top: 50px;
    }

    &.no-active {
      top: -30px;
    }
  }
}

.dropdown-nav {
  .hidden-xs;
  .hidden-sm;
  .font-sans-serif(12px);

  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .045);
  padding-top: @header-message-height;
  height: auto;
  opacity: 0;
  z-index: -1;
  background-color: #eff4f6d9;

  &:not(.menu-visible) {
    opacity: 0;
    transition-duration: .2s;

    img {
      display: none;
    }
  }

  &.menu-visible {
    opacity: 1;
    z-index: 25;

    img {
      display: block;
    }
  }

  .container {
    padding: 100px 25px 0 10px;

    .row {
      position: relative;
    }
  }

  &.stories-dropdown {
    .menu-column {
      overflow: hidden;
      height: 340px;

      ul {
        li {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &.occasions {
    .occasions-view-all a {
      color: @brand-main-color;
    }
  }

  .menu-column {
    .make-md-column(2);

    &.big {
      .make-md-column(3);
    }

    &.small {
      .make-md-column(1);
    }

    &.offset {
      .make-md-column-offset(1);
    }

    &.seven-columns {
      width: 14%;
    }

    height: @column-height;
    position: relative;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      a {
        .font-family-sans-serif(@font-size-small);
      }

      li {
        margin: 0;
        padding: 0 0 1px 10px;
        .make-responsive-padding-left(10px, 0px, 0px, 0px);

        a.all-gifts {
          display: none;
        }

        a.cursor-default {
          cursor: default;
        }
      }
    }
  }

  .featured-column {
    ul {
      &.featured-story {
        li {
          .make-md-column(3);
        }
      }

      &.featured-occasion {
        li {
          padding-top: 70px;
          .make-md-column(7);
        }
      }

      &.featured-designers {
        div.featured-title {
          font-family: 'OMSN', Times, serif;
          line-height: 1.2;
          padding-bottom: 0;
          padding-top: 10px;
          font-size: 14px;
          letter-spacing: 0;
          text-decoration: none;
        }
      }

      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        .title {
          margin-left: 0;
          padding-left: 0;
          visibility: hidden;
        }

        &:first-child .title {
          visibility: visible;
        }

        .make-md-column(2);
        height: @column-height;

        img {
          width: 170px;
          height: 269px;

          &.editorial {
            width: 245px;
            height: 245px;
          }
        }

        .view-all {
          margin: 0;
          left: 0;
          width: 100%;
          text-align: center;
        }

      }
    }
  }

  &.holiday .featured-column ul.featured-story li {
    width: 20%;
  }

  .title {
    margin: @margin-default 0 5px 0;
    padding: 0;
    @media (min-width: @screen-lg-min) {
      padding-left: 10px;
    }
    display: block;
    .font-sans-serif(@font-size-base);
    color: #5b5b5b;
    text-transform: uppercase;

    a {
      color: #5b5b5b;
      text-decoration: none;
      text-transform: uppercase;

      &.link-to-designers {
        &:hover {
          color: @brand-main-color;
        }
      }
    }

    a.cursor-default {
      cursor: default;
    }
  }

  .view-all {
    margin-left: 55px;
    bottom: @margin-default;

    &.near-pic {
      bottom: auto;
    }

    position: absolute;

    a, span {
      padding-bottom: 7px;
      border-bottom: 1px solid #000;
      letter-spacing: 1px;
      line-height: 28px;

      &:hover {
        border-bottom-color: @brand-main-color;
      }
    }

    &.centered {
      margin-left: 0px;
      width: 100%;
      text-align: center;
    }
  }

  .dropdown-close {
    display: none;
    position: absolute;
    right: 0;
    margin-top: @margin-default*2;
    margin-right: @margin-default;
    .square(15px);
    .flaticon-solid-x;
    color: #000;
    font-size: 24px;
    cursor: pointer;

    &:hover {
      color: @brand-main-color;
    }
  }
}

.private-access.user-is-anonymous {
  .header-nav {
    .nav-container {
      visibility: hidden;
    }
  }

  .hamburger-nav {
    visibility: hidden;
  }

  .bag-orchard {
    display: none;
  }
}
