.admin-return{
  .summary-table {
      margin-top: @margin-default;
      width: 100%;
      thead {
        tr {
          background-color: #f2f2f2;
          .font-family-sans-serif(@font-size-x-small);
          .make-responsive-line-height(38px,38px,38px,38px);
          border-bottom: 0;
        }  
      }
      tr{
        .make-responsive-line-height(50px,50px,50px,50px);
        a, td {
          .font-family-sans-serif(@font-size-small);
        }
        text-transform: uppercase;
        border-bottom: 1px solid #f2f2f2;

        td, th {
          padding-left: @margin-default / 2;
          white-space: auto;
          
        }
      }
    }
}