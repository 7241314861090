
.text-uppercase() {
  text-transform:uppercase;
}

.main-heading() {
  .font-family-serif(@font-size-xx-large);
  .text-uppercase();
  margin: 0;
}

.main-text-lead() {
  .font-family-sans-serif(@font-size-base);
  .text-uppercase();
}

.text-truncate-lines(@lines) {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
}

.truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}