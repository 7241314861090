.product-review {
  .modal-dialog {
    margin-left: auto;
    margin-right: auto;
    @media (min-width: @screen-md-min) {
      width: 550px;
    }
    @media (max-width: @screen-sm-max) {
      width: 95%;
    }
  }

  display: flex;
  flex-direction: column;

  .product-review--title {
    .font-sans-serif();
    .make-responsive-font-size(@font-size-large, @font-size-large, @font-size-base, @font-size-base);
    .make-responsive-line-height(1.44, 1.44, 1.86, 1.86);
    .make-responsive-padding-top(30px, 30px, 15px, 15px);
    .make-responsive-padding-bottom(10px, 10px, 8px, 8px);
    .make-responsive-padding-left-right(0, 0, 16px, 16px);
    .make-responsive-max-width(400px, 400px, initial, initial);
    .make-responsive-letter-spacing(1.8px, 1.8px, 1.4px, 1.4px);
    color: @new-light-gray;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
  }

  .error-summary {
    float: none;
    margin-top: 0;
  }

  .message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .product-review--subtitle {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
    text-transform: uppercase;
    font-size: 13px;
  }

  .close-btn-footer {
    text-align: center;
    text-transform: uppercase;
    margin: 10px;
  }

  .review-stars {
    text-align: center;

    .star {
      width: 25px;
      height: 25px;
      display: inline-block;
      background-size: contain;
      cursor: pointer;

      .star-empty {
        background: url('/img/icons/review-star.svg') no-repeat;
        background-size: cover;
        width: 25px;
        height: 25px;
      }
      .star-half {
        background: url('/img/icons/review-star-half.svg') no-repeat;
        background-size: cover;
        width: 25px;
        height: 25px;
      }
      .star-full {
        background: url('/img/icons/review-star-full.svg') no-repeat;
        background-size: cover;
        width: 25px;
        height: 25px;
      }
    }

  }

  .has-error-box {
    border: 1px solid red;
  }

  .review-stars-container {
    display: flex;
    justify-content: center;
  }

  .brand-stitch {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .product-review-main-container {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .product-review-body {
    .make-responsive-padding-top(10px, 10px, 20px, 20px);
    .make-responsive-padding-bottom(30px, 30px, 20px, 20px);
    .make-responsive-padding-left-right(65px, 65px, 15px, 15px);
  }

  .close-btn {
    float: right;
    background-color: transparent;
    .flaticon-solid-x;
    color: black;
    border: none;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;
  }

  .phone-number {
    margin-bottom: 0;
  }
}
