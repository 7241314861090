.product-sort-selector {
  .font-sans-serif(@font-size-small);
  label {
    position:relative;
    left:6px;
    border-bottom: solid 1px #f1f1f1;
    padding-bottom: 5px;
    font-weight: normal;
  }
  select {
    outline: none;
    color: @brand-main-color;
    padding: 0 15px 5px 15px;
    min-width: 100px;
    margin-left: 10px;
    height: 30px;
    .border-radius(0);
    .text-left;
    .flaticon-solid-select-arrow;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-color:#f1f1f1;
    background-position: 95% 45%;
    &::-ms-expand {
      display: none;
    }
  }
}
