.order-packages-info {
  @success-color: hsl(120, 40%, 80%);
  @failure-color: hsl(0, 40%, 80%);
  .order-package-info {
    .package-status-summary {
      display: block;
      width: 100%;
      height: 80px;

      table, tr {
        height: 100%;
      }
      td {
        position: relative;
        text-align: center;
        background-color: #ddd;
        height: 100%;
        vertical-align: middle;
        padding: 10px;
        min-width: 10px;
        .status-title {
          display: block;
          .font-sans-serif-bold(@font-size-large);
          white-space: nowrap;
        }
        .status-subtitle {
          .font-sans-serif(@font-size-base);
          white-space: nowrap;
        }
        @media (max-width: @screen-sm-max) {
          .status-title {
            .font-sans-serif-bold(@font-size-base);
          }
          .status-subtitle {
            .font-sans-serif(@font-size-x-small);
          }
        }

        &.previous-status {
          background-color: #d2d2d2;
        }
        &.current-status {
          width: 100%;
          background-color: desaturate(@success-color, 30%);
          &.package-status-failure, &.package-status-cancelled {
            background-color: @failure-color;
          }
          &.package-status-delivered, &.available-for-pickup {
            background-color: @success-color;
          }
        }
        &.package-carrier-info {
          vertical-align: top;
          text-align: right;
          .font-sans-serif(@font-size-x-small);
          .carrier-name {
            .font-sans-serif(@font-size-base);
          }
        }
        .step-arrow {
          position: absolute;
          top: 0;
          left: -10px;
          polygon {
            fill: white;
          }
        }
      }
    }

    .package-details {
      .font-sans-serif(@font-size-base);
      @media (max-width: @screen-sm-max) {
        .font-sans-serif(@font-size-small);
      }
      padding-bottom: 30px;
      overflow-y: scroll;

      max-height: 0;
      opacity: 0;
      .transition(all 0.3s);

      .carrier-track-package {
        text-align: center;
        a {
          display: block;
          padding: 20px;
        }
      }

      .package-tracking-details {
        td {
          position: relative;
          height: 30px;
          padding: 0 8px 0 4px;
          &:first-child {
            min-width: 24px;
          }
          svg {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            polygon, rect, line, circle {
              stroke: #aaa;
              stroke-width: 4px;
              fill: none;
            }
          }
        }
      }
    }
    &.expanded {
      .package-details {
        max-height: 1000px;
        opacity: 1;
      }
    }
    .product-image {
      min-height: 216px;
    }
  }
}
