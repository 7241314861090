.occasions {
  span, a {
    display:block;
  }

  .occasions-header {
    .make-responsive-margin-top(0px, 0px, 30px, 30px);
    .make-responsive-margin-bottom(40px, 40px, 30px,2 30px);

    .occasions-title {
      .font-serif();
      .make-responsive-font-size(@font-size-xx-m-large, @font-size-xx-m-large, @font-size-x-big-large, @font-size-x-big-large);
      .make-responsive-line-height(1, 1, 1.15, 1.15);
      .make-responsive-margin-bottom(30px, 30px, 20px, 20px);
      color: #1a1a1a;
      text-align: center;
    }
  }

  ul.discover-list {
    li {
      .make-row();
      .make-responsive-height(314px, 258px, 264px, 174px);

      list-style: none;
      background-color: #222222;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      .make-responsive-margin-bottom(30px, 30px, 15px, 15px);

      .left {
        .make-responsive-columns(3, 3, 12, 12);
        z-index: 2;
        .make-responsive-padding(30px, 30px, 20px, 20px);
        .make-responsive-padding-top(60px, 20px, 10px, 10px);
        .make-responsive-padding-bottom(60px, 20px, 10px, 10px);

        .logo {
          .font-serif();
          .make-responsive-font-size(@font-size-xx-m-large, @font-size-x-large, @font-size-x-large, @font-size-x-large);
          .make-responsive-line-height(1, 1, 0.57, 0.57);
          .make-responsive-margin-top(10px, 10px, 10px, 10px);
          .make-responsive-margin-bottom(10px, 10px, 10px, 10px);
          color: #1a1a1a;
          text-align: left;
        }

        .description {
          .font-sans-serif();
          .make-responsive-font-size(@font-size-base, @font-size-small, @font-size-base, @font-size-base);
          .make-responsive-line-height(1.29, 1.1, 1.29, 1.29);
          .make-responsive-margin-top(10px, 10px, 10px, 10px);
          .make-responsive-margin-bottom(10px, 10px, 5px, 5px);
          color: #1a1a1a;
          text-align: left;
        }

        span {
          .font-sans-serif();
          .make-responsive-font-size(@font-size-base, @font-size-base, @font-size-base, @font-size-base);
          .make-responsive-line-height(2, 2, 2, 2);
          .make-responsive-margin-top(10px, 10px, 5px, 5px);
          .make-responsive-margin-bottom(10px, 10px, 5px, 5px);
          color: #1a1a1a;
          text-align: left;
          text-decoration: underline;
        }
      }

      @media (max-width: @screen-sm-max)  {
        margin-right:-15px;
        margin-left: -15px;
        background-size: cover;
        background-repeat: no-repeat;
        height: percentage(360 / 1200);

      }


      .right {
        .make-responsive-columns(9, 9, 12, 12);
        z-index: 2;
        .products {
          .product-list();
          .no-product-info();
          .product-item-container, .product-list-item {
            .make-responsive-columns(3, 3, 6, 6);
            margin-top: @grid-gutter-width / 2;
            margin-bottom: @grid-gutter-width / 2;
            .product-image {
              .make-responsive-height(284px, 228px, 228px, 228px) !important;
            }
          }
        }
      }
    }
  }
}
