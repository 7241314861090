.form-group {
  margin-top: 25px;
  margin-bottom: 0;
  &.small {
    margin-top: 5px;
  }
  .col-6 {
   .make-responsive-columns(6,6,6,6);
   padding-left: 0;
   padding-right: 0;
   &.left {
     .make-responsive-padding-right(@margin-default,@margin-default,@margin-default/2,@margin-default/2);
   }
   &.right {
     .make-responsive-padding-left(@margin-default,@margin-default,@margin-default/2,@margin-default/2);
   }
  }
}

.admin-form {
  input[type=number] {
    text-align: right;
  }
}

// TEMP: scope to some sections to avoid breaking other parts of site yet
.password-reset-view, .invite, .invite-redeem-view, .modal-panel,
  .general-form, .sign-in-directive, .forgot-password-directive,
  .billing, .shipping, .checkout-phone-view, .billing-modal, .gift-card-promo-code-wrapper, // checkout
  .admin-invite, .admin-form, .address-modal {

  form {
    input[type=radio] {
      @media (max-width: @screen-sm-max)  { padding: 0px; }
    }
    input[type=checkbox] {
      @media (max-width: @screen-sm-max)  { padding: 0px; }
    }
    .form-group {
      position: relative;
      label {
        position: absolute;
        .font-sans-serif(@font-size-small);
        top: -20px;
        opacity: 1;
        visibility: visible;
        span {
          padding-left: 15px;
          color: #000;
        }
        &:hover{
          span{
            text-decoration: underline;
            color: #808080;
            cursor: pointer;
          }
        }
      }
      .is-empty, &.is-empty {
        label {
          top: 20px;
          left: 14px;
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .tracking-is-empty {
      label {
        transition: all .2s;
      }
    }

    // list of options
    .form-group > ul {
      list-style-type: none;
    }
    li, .form-group > ul > li {
      input[type=radio] {
        display: inline-block;
        width: auto;
        margin: 0 12px 0 0;
        &+label {
          margin: 0 0 5px 0;
        }
      }
      > label {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        text-transform: none;
      }
    }

    // checkbox
    .form-group {
      &>input[type=checkbox] {
        display: inline-block;
        width: auto;
        margin: 0;
        &+label {
          margin: 0 0 5px 0;
          position: static;
          top: 0;
        }
      }
    }

    input, select, textarea {
      .font-family-sans-serif(@font-size-small);
      @media (max-width: @screen-sm-max)  { .font-family-sans-serif(@font-size-medium-large); }
      line-height: 1em;
      background: white;
      border: 1px solid #e6e6e6;
      -webkit-border-radius: 0;
      border-radius: 0;
      padding: 15px 13px 15px 20px;
      width: 100%;
    }
    input[type=text], input[type=email], input[type=password], textarea{
      /*
      The first background-image declaration, using the -webkit-gradient
      function, is what will actually work in Safari on iOS today.
      he second declaration using -webkit-linear-gradient
      is there for future compatibility;
      WebKit has recently updated its gradient
      syntax to match the W3C specification.
      These syntax changes are in nightly builds and
      will make it into live versions of Safari and Chrome sometime soon
      */
      background-image:-webkit-gradient(linear, 0% 0%, 0% 100%, from(hsla(0,0%,100%,0)), to(hsla(0,0%,100%,0)));
      background-image:-webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0));
    }
    .has-feedback {
      textarea, input {
        // make space for the feedback icon
        padding-right: 38px;
      }
    }
    textarea {
      min-height: 200px;
    }
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
      color: #999;
      text-transform: none;
    }
    input:-moz-placeholder, textarea:-moz-placeholder {
      color: #999;
      text-transform: none;
    }
    input::-moz-placeholder, textarea::-moz-placeholder {
      color: #999;
      text-transform: none;
    }
    input:-ms-input-placeholder, textarea:-ms-input-placeholder {
      color: #999;
      text-transform: none;
    }
    input[type=radio], input[type=checkbox] {
      width: auto;
    }
    input[type=email] {
      text-transform: lowercase;
    }
    // select custom appearance
    select {
      .flaticon-solid-select-arrow;
      &::-ms-expand {
        display: none;
      }
      &.ng-pristine {
        color: #999;
      }
      line-height: 1.4em;
    }
    input.input-lg, select.input-lg, textarea.input-lg {
      .make-responsive-font-size(14px; 14px; 12px; 12px);
      padding: 16px 16px 16px 25px;
      .make-responsive-padding-left(25px; 25px; 16px; 16px);
      .make-responsive-height(58px; 58px; 40px; 40px);
      @media (max-width: @screen-sm-max)  { .font-family-sans-serif(@font-size-medium-large); }
    }

    .error-summary {
      display: none;
    }
  }

  .form-group {
    .form-control-feedback {
      .make-responsive-top(9px; 9px; 8px; 8px);
      right: 15px;
      visibility: hidden;
      display: block;
      opacity: 0;
      transition: opacity 1s;
    }
    .form-control-feedback-select {
      .make-responsive-top(9px; 9px; 8px; 8px);
    }
    .input-lg ~ .form-control-feedback {
      .make-responsive-right(15px; 15px; 0; 0);
    }
    select ~ .form-control-feedback {
      // leave some space for the down arrow
      right: 46px;
    }
  }
  .no-label {
    .terms-and-condition {
      .font-sans-serif(@font-size-x-small);
      text-align: center;
      .make-responsive-margin-top(10px, 10px, 5px, 3px);
      a {
        .font-sans-serif(@font-size-x-small)
      }
    }
    input.input-lg, select.input-lg, textarea.input-lg {
      .make-responsive-padding(16px 25px, 16px 25px, 5px 16px, 5px 16px);
    }
    &.form-group, .form-group {
      padding-top: 2px;
      .make-responsive-padding-bottom(8px;8px;16px;16px);
      .form-control-feedback {
        .make-responsive-top(10px; 10px; 0px; 0px);
      }
    }
  }

  .field-not-applicable {
    font-size: small;
  }

  form {
    button, .btn {
      min-width: 200px;
      margin-top: 15px;
      margin-bottom: 14px;
      &.row {
        float: none;
      }
      &.secondary {
        background-color: #666;
      }
    }
    .submit-help-text {
      .font-sans-serif(@font-size-small);
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 10px;
      a {
        .font-sans-serif(@font-size-small)
      }
    }
  }

  // only show feedback when user input is done
  .ng-submitted, .submitted, .visited {
    .ng-invalid {
      border: 1px solid @error-color;
    }
    input.ng-invalid, select.ng-invalid, textarea.ng-invalid {
      border: 1px solid @error-color;
    }
  }
  .ng-pristine {
    .ng-invalid {
      border: 1px solid #e6e6e6;
    }
    input.ng-invalid, select.ng-invalid, textarea.ng-invalid {
      border: 1px solid #e6e6e6;
    }
    .form-control-feedback {
      opacity: 0 !important;
    }
  }
  .ng-submitted.ng-invalid, .submitted.ng-invalid {
    .error-summary {
      display: block;
      &.has-no-errors {
        display: none;
      }
    }
  }
  .ng-submitted .has-feedback, .submitted .has-feedback, .has-feedback.visited {
    input.ng-valid, input.ng-invalid {
      // leave space for the feedback icon
      .make-responsive-padding-right(50px, 50px, 36px, 36px);
    }
    .ng-valid ~ .form-control-feedback {
      visibility: visible;
      opacity: 1;
      .flaticon-solid-checkmark();
      color: @positive-feedback-color;
      font-size: 16px;
    }
    .ng-invalid ~ .form-control-feedback {
      visibility: visible;
      opacity: 1;
      .flaticon-solid-exclamation-sign();
      color: @error-color;
      font-size: 16px;
    }
  }


  // form submit results
  .form-success-message {
    display: none;
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    .font-sans-serif(@font-size-small);
    text-align: center;
    .margin-top-medium;
    .make-responsive-padding(22px, 22px, 15px, 15px);
    word-break: break-all;
  }

  .form-failure-message {
    display: none;
    background-color: @error-color-light;
    border: 1px solid @error-color;
    .font-sans-serif(@font-size-small);
    text-align: center;
    .margin-top-medium;
    .make-responsive-padding(22px, 22px, 15px, 15px);
    word-break: break-all;
  }

  .form-success-message, .form-failure-message, .form-group-error {
    a {
      font-weight: bolder;
    }
    a, p {
      font-size: 12px;
      text-transform: none;
    }
  }

  .form-failure {
    .form-failure-message {
      display: block;
    }
  }
  .form-success {
    .form-success-message {
      display: block;
    }
  }
  .standalone-message {
    .margin-top-medium;
    .margin-center;
  }
  .form-failure-message.standalone-message {
    display: block;
  }
  .form-success-message.standalone-message {
    display: block;
  }
  .form-success, .form-failure {
    &.cannot-retry {
      .form-group, .error-summary, button, .btn {
        display: none;
      }
    }
  }

  // form-group inline errors
  .form-group {
    .form-group-error {
      display: none;
      font-size: 12px;
      margin: 6px 14px;
      background-color: #fbe7e9;
      box-shadow: 0 0 8px #fbb;
    }
    .ng-invalid-unique-email ~ .on-invalid-unique-email {
      display: inline-block;
    }
    .ng-invalid-unique-email-no-invite ~ .on-invalid-unique-email-no-invite {
      display: inline-block;
    }
    .ng-invalid-non-disposable-email ~ .on-invalid-non-disposable-email {
      display: inline-block;
    }
    .ng-invalid-has-invite-pending ~ .on-invalid-has-invite-pending {
      display: inline-block;
    }
    .ng-invalid-state-and-zip-code ~ .on-invalid-state-and-zip-code {
      display: inline-block;
    }
  }

  .tooltip-inner {
    .font-family-sans-serif();
    .make-responsive-font-size(@font-size-base, @font-size-base, @font-size-base, @font-size-base);
    visibility: visible;
    margin-left: -115px;
    margin-top: -40px;
    margin-right: 35px;
    background-color: @brand-gray-light;
    color: black;
    overflow: hidden;
    max-width: 500px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: normal;
    div {
      display: inline-block;
      box-sizing: content-box;
      padding: 0px;
    }
  }

  .ng-invalid + .form-control-feedback:hover .tooltip-inner {
    visibility: visible;
    transition-delay: 0.5s;
  }
  // specific errors
  .form-failure-for-status (@status) {
    .form-failure-status-@{status} {
      display: none;
    }
    .form-error-@{status} {
      .form-failure-status-@{status} {
        display: block;
      }
      .form-failure-status-not-@{status} {
        display: none;
      }
    }
  }
  .form-failure-for-status('4xx');
  .form-failure-for-status(400);
  .form-failure-for-status(401);
  .form-failure-for-status(403);
  .form-failure-for-status(404);
  .form-failure-for-status(408);
  .form-failure-for-status('5xx');
  .form-failure-for-status(500);
  .form-failure-for-status(501);
  .form-failure-for-status(502);
  .form-failure-for-status(503);
  .form-failure-for-status(504);
}
