.admin {
  .container();
  background-color:#fff;
  padding-top: 100px;

  h1 {
    font-size: 40px;
    margin-top: 5px;
  }

  input, select, textarea {
    border-radius:0;
    box-shadow:0;
    border: 1px solid #f1f1f1;
    font-size: 12px;
    line-height: 1em;
  }

  select {
    .border-radius(0);
    .flaticon-solid-select-arrow;
    &::-ms-expand  {
      display: none;
    }
  }

  .success-feedback {
    color: #449944;
    margin-left: 20px;
  }

  .table {
    .table();
  }

  .angucomplete-holder {
    position: relative;
  }

  .angucomplete-dropdown {
    border-color: #ececec;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    width: 250px;
    padding: 6px;
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    /*top: 32px;
    left: 0px;
    */
    margin-top: -6px;
    background-color: #ffffff;
  }

  .angucomplete-searching {
    color: #acacac;
    font-size: 14px;
  }

  .angucomplete-description {
    font-size: 14px;
  }

  .angucomplete-row {
    padding: 5px;
    color: #000000;
    margin-bottom: 4px;
    clear: both;
  }

  .angucomplete-selected-row {
    background-color: lightblue;
    color: #ffffff;
  }

  .angucomplete-image-holder {
    padding-top: 2px;
    float: left;
    margin-right: 10px;
    margin-left: 5px;
  }

  .angucomplete-image {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border-color: #ececec;
    border-style: solid;
    border-width: 1px;
  }

  .angucomplete-image-default {
    /* Add your own default image here
     background-image: url('/assets/default.png');
    */
    background-position: center;
    background-size: contain;
    height: 34px;
    width: 34px;
  }
}
