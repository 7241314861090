.bg-error {
  background-size: 100%;
  margin-top: 15px;
  margin-bottom: 55px;
  display: flex;
  min-height: 241px;
  position: relative;

  .error-number {
    position: absolute;
  }
}

.error-img {
  .make-md-column(2);
  padding-left: 0;
  cursor: pointer;
  height: auto;
  @media (max-width: @screen-sm-max) {
    display: none;
  }
}

.error-header {
  .container();
  .make-md-column(10);
  margin-bottom: 50px;
  float: none !important;
  min-height: 100px;

  .error-product-name {
    font-style: italic;
    color: @brand-main-color;
  }
}

.shop-view {

  @shop-brand-header-height-lg: 300px;
  @shop-brand-header-height-md: 270px;
  @shop-brand-header-height-sm: 250px;
  @shop-brand-header-height-xs: 200px;
  // .container();

  .number-of-products {
    color: #4a4a4a;
    margin-left: 30px;

    h1 {
      text-transform: uppercase;
      display: inline-block;
      .make-responsive-font-size(@font-size-x-big-large, @font-size-x-big-large, @font-size-x-big-large, @font-size-large-medium);
      .font-serif;

      margin-top: 0;
      margin-bottom: 0;
      margin-right: 20px;
    }

    font-size: @font-size-small;
    .font-sans-serif;
    @media (max-width: @screen-xs-max) {
      h1 {
        float: left;
      }

      text-align: right;
      padding-right: 10px;
      width: 90%;
    }
  }

  min-height: 990px;
  margin-top: @margin-default*3;

  &.error-404 {
    margin-top: 0;

    .filter-btn {
      top: 345px;
    }
  }

  &.no-filter {
    margin-top: 0;
    position: relative;
    .make-responsive-left(-15%, -15%, 0%, 0%);
  }

  .hero-image-panel {
    background-position: center;
    position: relative;
    width: 100%;
    .make-hero-image-panel;
    background-repeat: no-repeat;
    @media (max-width: @screen-md-max) {
      height: calc(100vw / 2.68);
    }

    &.no-assets {
      height: 100%;
      position: relative;
      background-color: #fbf2e9;

      .section-container {
        position: relative;

        &.brand, &.gifts-section {
          height: 130px;

          .section-name {
            h1 {
              color: black;
              .font-serif();
              .make-responsive-font-size(50px, 50px, 40px, 34px);
              line-height: 90px;
            }

            .brand-stitch {
              margin-top: 0;
              margin-bottom: 50px;
              .hidden-xs;
              .hidden-sm;
            }

            .brand-logo {
              position: relative;
              .make-responsive-width(350px, 300px, 280px, 250px);
              height: 130px;
              margin-left: auto;
              margin-right: auto;

              &.white {
                svg {
                  .svg-color(#FFF);
                }
              }

              &.black {
                svg {
                  .svg-color(#000);
                }
              }

              svg {
                position: absolute;
                padding: 15px;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 11;
              }
            }

            h4 {
              color: black;
              margin-top: -20px;
              padding-bottom: 20px;
              .font-serif-italic;
              .hidden-sm;
              .hidden-xs;
              padding-left: 200px;
              padding-right: 200px;
            }

            p {
              color: black;
            }
          }
        }
      }
    }

    &.with-assets {

      .brand-logo {
        position: relative;
        .make-responsive-width(350px, 300px, 280px, 250px);
        margin-left: auto;
        margin-right: auto;

        &.top {
          &.black {
            svg {
              .svg-color(#000);
            }
          }

          &.white {
            svg {
              .svg-color(#FFF);
            }
          }

          &.overlay-white {
            svg {
              background-color: rgba(255, 255, 255, 0.6);
            }
          }

          top: -130px;

          svg {
            height: 100px;
            background-color: transparent;
            .make-responsive-margin-top(150px, 150px, 80px, 40px);
          }
        }

        &.left {
          float: left;
          margin-left: 180px;
        }

        &.first-logo {
          z-index: 0;
          background-color: rgba(255, 255, 255, 0.6);
        }

        &.no-overlay {
          background-color: transparent;

          svg {
            background-color: transparent;
          }
        }

        &.white {
          svg {
            .svg-color(#FFF);
            background-color: transparent;
          }
        }

        &.black {
          svg {
            .svg-color(#000);
          }
        }

        svg {
          position: absolute;
          .make-responsive-margin-top(150px, 120px, 80px, 40px);
          .make-responsive-height(130px, 130px, 110px, 90px);
          background-color: rgba(255, 255, 255, 0.6);
          padding: 15px;
          width: 100%;
          left: 0;
          top: 0;
          z-index: 11;
          @media (max-width: @screen-xs-min) {
            margin-top: 39px;
            height: 80px;
          }
        }

        &.with-cta {
          svg {
            .make-responsive-margin-top(120px, 120px, 80px, 70px);
          }
        }
      }

      .carousel {
        height: calc(100% / 2.19);
        .hidden-sm;
        .hidden-xs;

        .carousel-control {
          z-index: 3;
        }

        li, li.active {
          margin-right: 5px;
          margin-left: 5px;
          width: 14px;
          height: 14px;
          margin-top: 1px;
          margin-bottom: 1px;
        }

        img {
          height: calc(100% / 2.19);
          width: 100%;
          object-fit: cover;
          object-position: top;
          margin-left: 0;
        }

        .carousel-indicators {
          opacity: 0.6;

          li {
            border: 1px solid #888;
            background-color: #666;

            &.active {
              background-color: #000;
            }
          }
        }

        .carousel-control {
          background-image: none;
          width: 5%;

          .glyphicon.glyphicon-chevron-left {
            .flaticon-solid-left-2;
            .rotate(270deg);
            font-size: 25px;
            width: 50px;
            height: 50px;
          }

          .glyphicon.glyphicon-chevron-right {
            .flaticon-solid-right-2;
            .rotate(90deg);
            font-size: 25px;
            width: 50px;
            height: 50px;
          }
        }

        .carousel-caption {
          &:nth-child(1n+2) {
            color: #FFF;
            background-color: transparent;

            button {
              background-color: transparent;

              &.semi-transparent-background {
                background-color: rgba(0, 0, 0, 0.3);
              }

              &.align-bottom {
                margin-top: 140px;
              }

              &:hover {
                background-color: black;
              }
            }
          }

          &.black {
            color: #000;
            background-color: transparent;
            text-shadow: none;

            button {
              background-color: transparent;
              text-shadow: none;
              color: #000;
              border: 1px solid #000;

              &:hover {
                color: #fff;
              }

              &.semi-transparent-background {
                background-color: rgba(255, 255, 255, 0.6);

                &:hover {
                  color: #000;
                  background-color: rgba(255, 255, 255, 1);
                }
              }
            }
          }

          .make-responsive-top(30px, 30px, 30px, 30px);
          width: 65%;
          left: 0;
          right: 0;
          margin-right: auto;
          margin-left: auto;
          position: absolute;

          .make-responsive-margin-bottom(50px, 40px, 5px, 5px);
          .make-responsive-padding-top(100px, 100px, 50px, 50px);
          color: #000;
          background-color: rgba(255, 255, 255, 0.6);
          text-transform: uppercase;

          h4 {
            line-height: 1;
          }

          h2 {
            .make-responsive-font-size(40px, 32px, 22px, 18px);
          }

          h3 {
            .make-responsive-font-size(30px, 19px, 18px, 16px);
          }

          button {
            background-color: black;
            .make-responsive-margin-top(40px, 30px, 20px, 20px);
            border: 1px solid @brand-gray;
            text-shadow: 2px 2px 4px #000000;
            color: @brand-gray;
            .font-family-sans-serif-bold(@font-size-x-small);
            text-transform: uppercase;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: 35px;
            padding-right: 35px;
            .transition(~"background-color ease-in-out .35s");

            &:hover {
              background-color: #888;
            }
          }
        }
      }

      img {
        height: calc(100vw / 2.68);
        object-fit: cover;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }

    }


    .section-container {
      display: table;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 10;

      .section-name {
        display: table-cell;
        vertical-align: middle;
        color: #fff;
        text-align: center;
        width: 100%;
        margin: auto;

        h1 {
          .font-serif();
          .font-size(100px, 80px, 60px, 40px);
        }

        p {
          .font-serif-italic(@font-size-large);
          .make-responsive-margin-left-right(90px, 60px, 60px, 10px);
          .hidden-xs;

          a {
            color: #fff;
          }
        }
      }
    }

    .mile-panel {
      .hidden-xs;
      float: right;
      z-index: 20;
      .make-responsive-bottom(20px, 10px, 0, 0);
      right: 20px;
    }
  }

  .brand-hero {
    position: relative;
    .bg-brand-hero {
      background: rgba(0, 0, 0, 0.25);
      // margin-right: -15px;
      // margin-left: -15px;
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .content-brand-hero {
      min-height: 50px;
      vertical-align: middle;
      display: table;
      width: 100%;
      line-height: 1.3;
    }
  }

  .message {
    z-index: 20;
    position: relative;
    display: table-cell;
    vertical-align: middle;

    &.sales-season {
      background-color: @brand-main-color;
      opacity: .8;
    }

    &.message-white {
      background-color: #fff;
      opacity: .6;
      color: #000;
    }

    .font-sans-serif-bold(@font-size-base);
    text-align: center;
    color: #fff;

    @media (max-width: @screen-xs-max) {
      padding: 5px 10px;
      font-size: @font-size-base;
    }

    .small {
      display: block;
      clear: both;
      .font-sans-serif(@font-size-small);
      text-transform: none;
    }
  }

  &.gifts-view {
    margin-top: @margin-default;

    .hero-image-panel {
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        .overlay(#000, 0.1);
      }
    }

    h1 {
      .font-serif-italic;
    }

    h1 + p {
      font-size: 18px;
      text-align: center;
    }
  }

  &.gift-guide, &.gifts-view {
    margin-top: 0;

    .background-image {
      background-repeat: no-repeat;
      background-size: 100% 100% !important;
      text-align: center;
      width: 100%;
      max-width: 100%;
      background-color: rgb(255, 255, 255);
      .make-responsive-height(185px; 242px; 186px; 85px);
      .make-responsive-line-height(185px; 242px; 186px; 85px);
      .text-center;

      .banner {
        // margin-top: -5px;
        margin-bottom: 0;
        display: inline-block;
        max-width: 95%;
        vertical-align: middle;
        text-align: center;
        padding: 0;
        .make-responsive-max-height(185px; 242px; 186px; 85px);

        h1 {
          .font-serif;
          .make-responsive-letter-spacing(5px, 5px, 4px, 3px);
          .make-responsive-font-size(@font-size-xx-large, @font-size-xx-large, @font-size-x-large, @font-size-large);
          .make-responsive-padding-left(20px, 20px, 15px, 5px);
          .make-responsive-padding-right(20px, 20px, 15px, 5px);
          .font-sans-serif;
          text-transform: uppercase;
          padding-bottom: 8px;
          padding-top: 4px;
          margin-top: 0;
          margin-bottom: 0;
        }

        h3 {
          .font-serif;
          .make-responsive-font-size(@font-size-medium * 1.35, @font-size-medium * 1.15, @font-size-base, @font-size-x-small);
          .make-responsive-padding-left(20px, 20px, 15px, 5px);
          .make-responsive-padding-right(20px, 20px, 15px, 5px);
          padding-top: 4px;
          padding-bottom: 6px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .section {
      display: inline-block;
      position: relative;
      background-color: #000;
      @media (max-width: @screen-xs-max) {
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
      }

      &.double {
        .make-responsive-min-height(420px, 362px, 238px, 315px);
        background-color: #FFFFFF;
        @media (min-width: @screen-xs-max) {
          width: 100%;
        }
      }

      &.left {
        .make-responsive-min-height(480px, 415px, 270px, 310px);
        background-color: #FFFFFF;
        @media (min-width: @screen-xs-max) {
          margin-left: 0;
          margin-right: 1%;
          float: left;
          width: 48%;
        }
      }
    ;

      &.right {
        .make-responsive-min-height(480px, 415px, 270px, 310px);
        background-color: #FFFFFF;
        @media (min-width: @screen-xs-max) {
          margin-left: 1%;
          margin-right: 0;
          float: right;
          width: 48%;
        }
      }
    ;

      .text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        .make-responsive-padding-left(20px, 20px, 15px, 5px);
        .make-responsive-padding-right(20px, 20px, 15px, 5px);

        h3 {
          .font-sans-serif;
          .make-responsive-letter-spacing(5px, 5px, 4px, 3px);
          text-transform: uppercase;
          margin-bottom: 5px;
          margin-top: 5px;
        }
      }

      .image {
        text-align: center;
        padding: 0;
        transition: all 0.5s;

        img {
          margin: 0;
          width: 100%;
        }
      }

      &:hover, &:focus {
        img {
          opacity: 0.6;
        }
      }
    }
  }

  &.sale {
    margin-top: 0;

    .filter-panel {
      z-index: 3;
      position: relative;
      top: 67px;

      .sidebar-rail {
        .top-offset {
          .make-responsive-height(180px, 180px, 80px, 80px);
        }
      }
    }

    .number-of-products {
      margin-top: 5px;
    }

    .hero-image-panel {
      background: url('https://static.orchardmile.com/content/images/hero.jpg') top;
      background-size: cover;
      height: 160px;

      .section-name {
        h1 {
          .font-size(60px, 60px, 40px, 30px);
          color: white;
        }

        h2 {
          .font-size(60px, 60px, 40px, 30px);
          color: white;
        }

        padding-left: 35px;
        letter-spacing: 35px;
      }
    }
  }

  .filter-panel {
    z-index: 3;
    position: relative;
    top: 35px;

    .sidebar-rail {
      .top-offset {
        .make-responsive-height(110px, 110px, 80px, 80px);
      }

      .bottom-offset {
        height: 450px;
      }
    }

    .filter-container {
      .hidden-xs;
      .make-responsive-width(240px, 230px, 230px, 0);
      z-index: 3;
      border: 1px solid @brand-gray-light;
      background-color: #fff;
      opacity: .8;
      margin-bottom: 15px;
    }

    .filter-bottom-space {
      height: 40px;
    }

    .category-tree, .other-filter {
      width: 100%;
      padding: 0 @grid-gutter-width/2;
    }

    .panel {
      padding: 0;
    }

    .gifts-sections {
      list-style: none;
      width: 100%;
      padding: 0 15px;

      li {
        padding: 15px 0 0 0;

        &:last-child {
          border-bottom: none;
        }

        h4 {
          text-align: left;
          .font-sans-serif-bold(@font-size-small);
          line-height: @font-size-small;
          text-decoration: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          padding: 5px 0;
        }

        ul {
          list-style: none;

          li {
            padding: 0;

            a {
              display: block;
              .font-family-sans-serif(@font-size-small);
              line-height: @font-size-small;
              text-decoration: none;
              -webkit-box-shadow: none;
              box-shadow: none;
              margin: 0;
              padding: 7px 0;

              &:link, &:visited, &:focus {
                color: #000;
                text-decoration: none;
              }

              &.active {
                .font-family-sans-serif-bold(@font-size-small);
              }
            }
          }
        }
      }
    }

    .other-filter {
      .panel-group .panel + .panel {
        margin-top: 10px;
      }

      .panel-heading {
        padding: 20px 0 10px 0;
      }
    }
  }

  .other-filter {
    .panel-group {
      border-bottom: 1px solid #f1f1f1;
      @media (max-width: @screen-xs-max) {
        border-bottom: 1px solid @brand-gray;
      }
    }

    .panel-heading {
      background-color: transparent;
      border: 0;

      h4 {
        margin: 0;
      }

      .panel-title {
        a {
          .font-family-sans-serif(@font-size-small);
          line-height: @font-size-small;
          text-decoration: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          padding: 5px 0;

          &:link, &:visited, &:focus {
            color: #000;
            text-decoration: none;
          }

          &.active {
            .font-family-sans-serif-bold(@font-size-small);

            .lvl0-icon {
              .font-family-sans-serif-bold(@font-size-small);
            }
          }

          .lvl0-icon {
            float: right;
            .font-family-sans-serif(@font-size-x-small);
          }
        }
      }
    }
  }

  .filter-checkbox() {
    list-style: none;
    text-align: left;
    li {
      display: inline;

      input[type="checkbox"] {
        display: none;

        &:checked + label {
          @media (max-width: @screen-xs-max) {
            .font-family-sans-serif(@font-size-small);
            background-color: @button-color;
            color: @brand-gray-light;
          }
          @media (min-width: @screen-sm-min) {
            border: 1px solid @color-peach;
            background-color: @color-peach;
            .font-family-sans-serif-bold(@font-size-xx-small);
          }
        }

        &:disabled + label {
          @media (max-width: @screen-xs-max) {
            .font-family-sans-serif(@font-size-small);
            border: 1px solid @brand-gray-dark;
            color: @brand-gray-dark;
            background-color: @brand-gray;
          }
          @media (min-width: @screen-sm-min) {
            color: #f3f3f3;
            background: linear-gradient(to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            rgba(243, 243, 243, 1) 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%);
          }
        }
      }

      label {
        cursor: pointer;
        color: #000;
        .make-responsive-width(65px, 62px, 30%, 31%);
        height: 50px;
        line-height: 50px;
        text-align: center;
        text-transform: uppercase;
        .make-responsive-margin-right(0px, 0, 1%, 1%);
        @media (max-width: @screen-xs-max) {
          .font-family-sans-serif(@font-size-small);
          border: 1px solid @button-color;
          color: @button-color-hover;
        }
        @media (min-width: @screen-sm-min) {
          border: 1px solid #f3f3f3;
          .font-family-sans-serif-bold(@font-size-xx-small);
        }
      }

      .shipping-input + label {
        width: 99%;
        text-transform: capitalize;

        b {
          @media (max-width: @screen-xs-max) {
            .font-family-sans-serif-bold(@font-size-small);
          }
          @media (min-width: @screen-sm-min) {
            .font-family-sans-serif-bold(@font-size-x-small);
          }
        }
      }

      .with-description + label {
        line-height: 16px;
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }
  }

  .size-list, .color-list, .shipping-list {
    .filter-checkbox();
  }

  .brand-not-available {
    .make-row();
    margin-top: 30px;

    p {
      text-align: center;
      .font-serif-italic;
      .font-size(@font-size-x-large, @font-size-x-large, @font-size-large, @font-size-large);
    }
  }

  .shop-content {
    min-height: 800px;
    .container-fluid();

    &.with-hero {
      margin-top: 10px;
    }
    .quick-filter{
      position: relative;
      left: 7%;
      width: calc(100% - 280px);
      z-index: 1;
      @media (max-width: @screen-xs-max) {
        width: 100%;
        left: 10px;
        padding-right: 15px;
      }
      .nav-pills {
        display: flex;
        flex-direction: row;
        overflow: auto;
        cursor: grab;
        white-space: nowrap;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        &:active {
          cursor: grabbing;
        }
      }

      .capitalize {
        text-transform: capitalize;
      }

      .nav-pills .nav-link {
        margin-right: 10px;
        border: 0.5px solid #838383;
        border-radius: 10px;
        color: var(--dark, #454545);
        text-align: center;
        .font-sans-serif;
        font-size: 12px;
        line-height: 10px;
      }

      .nav-pills .nav-link.active {
        background-color: #d1ecf1;
        color: #0c5460;
        border-color: #88BFD1;
      }
    }

    .no-results-message {
      .font-serif(24px);
      line-height: 23px;
      .make-responsive-margin(200px; 150px; 100px; 50px);
      text-align: center;
    }
  }

  .product-panel {

    margin-top: 0;

    .make-responsive-columns(12, 12, 12, 12);
    // .make-responsive-columns-offset(3, 3, 4, 0);

    .list {
      .product-list();
      margin: 0;

      .product-item-container {
        .make-responsive-columns(3, 3, 6, 6);

        &.admin-small-product {
          .make-responsive-columns(2, 2, 4, 4);

          .product-image {
            height: 136px;
          }

          &.no-labels {
            .product-info {
              display: none;
              margin-top: 5px;
              margin-bottom: 5px;
            }
          }
        }
      }

      .clipboard {
        position: fixed;
        font-size: 30px;
        bottom: 10px;
        top: 10px;
        left: 0;
        z-index: 999;
        background: white;
        border: 1px solid grey;
        padding: 10px;
        width: 240px;

        &.minimized {
          width: 55px;
          height: 30px;
        }

        .delete, .minimize, .maximize {
          color: red;
          font-weight: bolder;
          cursor: pointer;
          margin-left: @margin-default*2;
          position: absolute;
          right: 5px;
          top: 0;
        }

        .delete {
          right: 5px;
        }

        .minimize, .maximize {
          right: 25px;
        }
      }

      .clipboard-ux {
        display: block;
        position: fixed;
        right: 5px;
        bottom: 5px;

        button {
          padding: 6px;
          z-index: 15;
        }
      }

      .product-list-item {
        &.checked {
          outline: 2px black dashed;
        }

        &.pasting {
          outline: 2px orange dashed;
        }

        .flaticon-pin {
          margin-left: -55px;
          position: absolute;
          margin-top: 65px;
          font-size: 20px;
          cursor: pointer;
        }
      }

      .product-list-item.view-all {
        position: relative;

        .product-image {
          background-color: #000;

          img {
            transition: none !important;
            opacity: 0.4;
          }
        }

        .box {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          padding: 40% 20px;
          position: absolute;
          .font-sans-serif(@font-size-small);
          text-transform: uppercase;
          color: #fff;

          .arrow-indicator {
            .flaticon-solid-arrow-right;
            margin: 22px 0 0 0;
            display: inline-table;
            font-size: 16px;
            opacity: .6;

            &:before {
              border-top: 2px solid white;
              content: '';
              display: table-cell;
              position: relative;
              top: 7px;
              left: 3px;
              width: 52%;
            }

            &:after {
              display: table-cell;
            }
          }
        }

        &:hover {
          .product-image {
            img {
              opacity: 0.5;
            }
          }

          .box {
            i {
              opacity: 1;
            }
          }
        }
      }
    }

    .extra {
      text-align: center;
      text-transform: uppercase;
      .font-family-sans-serif-bold(@font-size-small);
      margin-bottom: 100px;
      margin-top: 40px;

      .show-all {
        background: #fff;
        border: 1px solid #000;
        padding: 10px;
        display: inline-block;
        width: 180px;
      }

      .total-label {
        color: #999;
        margin: 10px;
      }
    }

    .product-pagination {
      float: right;
      .make-responsive-margin-left(50px, 50px, 50px, 0);
      .make-responsive-margin-right(14px, 14px, 14px, 0);

      clear: both;
      width: 100%;
      text-align: right;
      .font-text-align-xs-center;
    }
  }

  .pagination-sm {
    .font-family-sans-serif-bold(@font-size-x-small);
  }

  &.brand-products {
    margin-top: auto;
    padding-top: 0;

    .number-of-products {
      margin-top: 0;
      margin-bottom: 15px;
      // padding-left: 15px;
      @media (max-width: @screen-sm-max) {
        padding-right: 0;
        padding-left: 0;
        margin-top: 50px;
      }
    }

    .filter-panel {
      top: 64px;

      .filter-container {
        top: 0;
      }
    }

    .shop-content {
      // margin-top: 30px;
      @media (max-width: @screen-xs-max) {
        margin-top: @grid-gutter-width/2;
      }
    }

    .watermark {
      color: @brand-watermark;
      .font-family-responsive-serif-italic();
    }

    .brand-hero-buttons {
      min-height: 40px;

      button, .btn {
        @media (min-width: @screen-sm-min) {
          font-size: 11.5px;
        }
        letter-spacing: 0;
      }

      // .container();

      margin: 0;
      margin-right: 30px;

      .left-section, .center-section, .right-section {
        @media (min-width: @screen-sm-min) {
          padding: 0 !important;
        }
      }

      .left-section {
        .make-responsive-columns(4, 5, 6, 6);
      }

      .center-section {
        .make-responsive-columns(4, 3, 6, 6);
        text-align: center;
        margin-top: 15px;
        .hidden-xs;
        .hidden-sm;
      }

      .right-section {
        .make-responsive-columns(4, 4, 6, 6);
      }
    }

    .btn.red-white, .btn.shop-instagram {
      background-color: #fff !important;
      color: @brand-main-color;
      border-color: @brand-main-color;
      padding: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
      z-index: 8;
      text-decoration: none;
      min-width: 141px;
      @media (min-width: @screen-xs-min) {
        margin-top: 20px;
      }
    }

    .shop-section {
      @media (max-width: @screen-sm-max) {
        position: absolute;
        margin-left: 2px;
      }
    }

    .filter-btn {
      top: 229px;
    }
  }

  .mile-panel {
    float: right;
  }

  .sort-selector-container {
    .hidden-xs;
    position: relative;

    .product-sort-selector {
      position: absolute;
      top: -22px;
      right: 15px;
      z-index: 2;
    }
  }

  //Brand Influencer Pages
  &.influencer-brand {
    .brand-hero {
      display: flex;
      justify-content: stretch;
      align-items: center;
      margin: 0 auto;
      // max-width: 1600px;
      width: 100%;
      @media (max-width: @screen-xs-max) {
        .brand-logo.first-logo {
          height: 100%;
          align-items: center;
          display: flex;
          background-color: transparent;
          svg {
            margin-top: inherit;
            top: auto;
          }
        }
      }
    }
    .hero-image-panel.with-assets {
      position: relative;
      left: 0;
      z-index: 23;
      overflow: hidden;
      flex: 1 auto;
      width: 77%; //-> was 100%
      // .make-responsive-width(75vw, 70vw, 70vw, 50vw);
      .make-responsive-height(475px, 475px, 475px, 400px);
      .carousel img {
        .hero-image-height-auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        @media (min-width: 992px) and (max-width: 1199px) {
          display: none;
        }
      }
      @media (max-width: @screen-xs-max) {
        background-position: left;
      }
    }
    .brand-creator-show {
      position: relative;
      right: 0;
      // .make-responsive-max-width(33%,30%,40%,50%);
      .make-responsive-height(475px, 475px, 475px, 400px);
      .make-responsive-width(267px, 267px, 267px, 225px);
      object-fit: cover;
      overflow: hidden;
      z-index: 23;
      .influencer-shows {
        padding-top: 0;
      }
      #creator-brand-carousel{
        min-width: auto;
        width: 100%;
        margin: 0;
        .show {
          .make-responsive-height(475px, 475px, 475px, 400px);
          .make-responsive-width(267px, 267px, 267px, 225px);
          .carousel-influencer {
            width: 100%;
            margin: 0;
            padding: 12px 15px;
            .status {
              top: -17px;
              left: 15px;
            }
          }
          .carousel-item {
            height: 100%;
            margin-left: auto;
            margin-right: auto;
            img {
              .make-responsive-height(475px, 475px, 475px, 400px);
              .make-responsive-width(267px, 267px, 267px, 225px);
              // aspect-ratio: .5625;
              object-fit: cover;
            }
          }
        }
        .prevArrowCarouselItems {
          left: 10px;
          color: white;
          @media (max-width: @screen-xs-max) { display: inline; }
        }
        .nextArrowCarouselItems {
          right: 12px;
          color: white;
          @media (max-width: @screen-xs-max) { display: inline; }
        }
      }
    }
    .banner-influencer-brand {
      background-color: @color-peach;
    }
    .container-creator {
      margin-top: 20px;
      background-color: @color-peach;
      padding: 5px;
      box-shadow: 1px 1px 7px 0px #00000030;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: @screen-sm-max) {
        flex-direction: column;
      }
      .rounded {
        border-radius: 50%;
        margin: 0 auto;
        background-size: cover;
        width: 100px;
        height: 100px;
        margin-top: -53px;
      }
      p {
        .font-sans-serif-italic;
        font-size: 13px;
      }
      .brand-stitch {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      button img.play {
        margin-left: 10px;
      }
      @media (min-width: @screen-sm-min) {
        img.the-mile-logo {
          position: absolute;
          left: 0;
        }
        button {
          position: absolute;
          right: 0;
        }
      }
    }
    .brand-hero-buttons {
      min-height: 60px;
      margin-bottom: 15px;
    }
  }
}

.promotion-the-mile {
  padding-top: 15px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  .main-text {
    color: gray;
  }
  .secondary-text {
    color: dimgrey;
  }
}
.brand-products{
  .promotion-the-mile {
    align-items: flex-end;
  }
}
.btn-themile {
  position: relative;
  min-height: 1px;
  padding-right: 25px;
  padding-left: 25px;
  margin: 10px 0;
  background-color: @brand-main-color;
  letter-spacing: 1px;
  height: 38px;
  padding: 0 30px;
  img {
    width: 13px;
    margin-top: -5px;
    margin-left: 5px;
  }
}

.img-themile {
  margin-right: 10px;
}

.product-details-view {
  .btn-themile {
    margin: 0;
  }
  @media (max-width: @screen-xs-max) {
    .promotion-the-mile {
      text-align: center;
      padding-top: 15px;
    }
  }
}
.amount-item {
  .font-serif-italic;
  .make-responsive-font-size (16px, 16px, 14px, 14px);
}
@media (min-width: 768px) {
  .drop-down-filter {
    display: block!important;
    position: relative!important;
  }
  .brands--filter, .filter-panel {
    display: none!important;
  }
  .shop-view.brand-products .filter-btn {
    left: 0;
    top: 0 !important;
    width: auto;
    margin: 0;
    padding: 0 0 0 30px;
    position: absolute;
  }
  .drop-down-content {
    left: 0;
    width: 400px;
    height: calc(90vh - 82px);
    top: 82px;
    .filter-footer .apply-btn {
      width: auto!important;
      color: white;
      float: right;
    }
  }
}
