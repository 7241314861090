/*
see http://stackoverflow.com/questions/21278020/font-weight-turns-lighter-on-mac-safari
*/
* {-webkit-font-smoothing:subpixel-antialiased;}
html.iOS-device{
    overflow-x:hidden;
    body {
        overflow-x:hidden;
        width: 100%;
        -webkit-text-size-adjust: auto;
    }
}
