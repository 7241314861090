.checkout-v2-view {

  .loading-overlay {
    z-index: 27;
  }

  .make-responsive-min-width(@container-tablet, @container-tablet, inherit, inherit);

  @media (min-width: @screen-md-min) {
    .make-row();
  }

  .steps {
    @media (min-width: @screen-md-min) {
      .make-xs-column(8);
    }

    padding-left: 0;

    .step {
      border: 1px solid #f1f1f1;
      margin-bottom: @grid-gutter-width/2;
      padding: 0;

      &.active {
        .step-body {
          .transition(max-height 0.7s @modal-animation-function);
          max-height: 5000px;
          opacity: 1;
        }
      }

      &.visited {
        .step-heading {
          .step-summary {
            opacity: 1;
            width: 100%;
            .transition(opacity 0.5s @modal-animation-function);
          }
        }

        &.active {
          .step-heading {
            .step-summary {
              opacity: 0;
              .transition(opacity 0.5s @modal-animation-function);
            }
          }
        }
      }

      a.step-heading {
        display: block;
        position: relative;
        padding: 0;

        h2 {
          margin: 0;
          padding: @grid-gutter-width;
          font-size: @font-size-x-large;
        }

        .step-summary {
          position: absolute;
          top: 0;
          .make-responsive-right(@grid-gutter-width, @grid-gutter-width/2, @grid-gutter-width/2, @grid-gutter-width/2);
          bottom: 0;
          opacity: 0;

          .aligner {
            display: inline-block;
            vertical-align: middle;
            height: 100%;
          }

          p {
            display: inline-block;
            vertical-align: middle;
            font-family: 'OMBR', Arial, sans-serif;
            font-weight: 400;
            font-style: normal;
            .font-sans-serif(@font-size-small);
            color: #666;
            text-align: right;
            padding: 0;
            margin: 0;
            width: 40%;
            padding-top: 10px;
            float: right;
          }
        }
      }

      .step-body {
        .transition(all 0.3s ease-out);
        opacity: 0;
        max-height: 0px;
        overflow: hidden;
        padding: 0 @grid-gutter-width;
        @media (max-width: @screen-sm-max) {
          padding: 0 @grid-gutter-width/2;
        }
      }
    }
  }

  h3 {
    font-size: 26px;
  }

  #payment-element {
    margin-bottom: 30px;
  }

  .pay-with-buttons {
    @media (max-width: @screen-xs-max) {
      float: left;
      padding-left: 10px;
    }
    @media (min-width: @screen-sm-min) {
      float: left;
    }
    .clearfix;
    padding: @margin-default 0;

    #payment-request-button {
      margin-bottom: 15px;
      min-width: 148px;
    }
  }

  .checkout-options-container {
    .clearfix;
    clear: both;
  }

  .checkout-options {
    margin-bottom: 0;
    list-style-type: none;

    li {
      display: inline-table;
    }
  }

  .checkout-option {
    width: 100%;
    padding: 13px;
    margin-top: 0;
    border-bottom: 1px solid #f1f1f1;

    .payment-logo {
      height: 35px;
      border-radius: 20px;
    }

    &.add-new-option {
      border-bottom: 0;
      clear: both;

      .details {
        margin-top: 5px;
      }
    }

    &:first-child {
      margin-top: 0;
    }

    .title {
      .font-sans-serif-bold(@font-size-medium);
      text-transform: capitalize;
      color: #000;
      margin-bottom: 0;
    }

    .details {
      display: block;
      margin-left: 24px;
      .font-sans-serif(@font-size-small);
      color: #000;
    }

    .error {
      .font-sans-serif-bold(@font-size-small);
      color: @error-color;
    }

    a.delete {
      margin-left: 30px;
      text-decoration: underline;
      text-transform: capitalize;
      .font-sans-serif(@font-size-small);
      color: #000;
    }

    &.disabled {
      .title, .details, a.delete {
        color: #999;
      }
    }
  }

  button.continue {
    .clearfix;
    margin-bottom: 30px;
  }
}

.checkout-v2-view-cart {
  .checkout-base-view {
    width: 500px;
    margin-top: 15px;

    @media (max-width: @screen-xs-max) {
      width: 100%;
    }
  }

  .checkout-v2-view {
    .summary {
      margin-left: 15px;
      width: 62.666%;

      @media (max-width: @screen-xs-max) {
        margin-left: 0;
        width: 100%;
      }
    }

    .btn-action div {
      margin-left: 16.66667%;
      @media (max-width: @screen-xs-max) {
        margin-left: 0;
      }
    }

    .confirmation {
      margin-right: 0px;
      margin-left: 25px;
      width: 450px;
      min-width: 0;
      @media (max-width: @screen-xs-max) {
        margin-right: 5px;
        margin-left: 5px;
        width: 100%;
      }
    }
  }
}

#english-name-confirmation {
  &.modal {
    .modal-dialog {
      display: flex;
      justify-content: center;
      align-items: center;
      height: -webkit-fill-available;
      max-width: 400px;
      width: 80%;
      margin: 0 auto;
      .modal-content {
        border-radius: 0;
        box-shadow: none;
        background-color: #ffffffd1;
        padding: 20px;
        border-color: transparent;
        h5 {
          padding-top: 15px;
          .font-sans-serif-bold;
        }
        form {
          padding-top: 15px;
          button {
            width: 100%;
          }
        }
      }
    }
    .close-btn:hover {
      background-color: transparent;
      color: #000;
    }
  }
}
