.logo {
  .text-center;
  svg {
    max-width: 154px;
    width: 100%;
    .make-responsive-height(40px, 40px, 40px, 30px);
    g { fill: @brand-main-color; }
  }
  &.red { g { fill: @brand-main-color; } }
  &.white { g { fill: #fff; } }
  &.black { g { fill: #fff; } }

  .slogan {
    .font-serif-italic(13px);
    color: black;
    width: 250px;
    text-align:center;
    letter-spacing: 1px;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    .make-responsive-translateX(-50%,-50%,-50%,-50%);
    font-weight: bold;
    position: absolute;
    .hidden-xs;
    .hidden-sm;
    .make-responsive-margin-top(2px, -10px, 0px, 0px);
  }
}

.environment-alternative-name {
  position: relative;
  top: -18px;
  .text-center;
  left: 65%;
  width: 30px;

  span {
    color: #f00;
    text-transform: none;
    position: absolute;
    font-size: small;
    text-shadow: 0 0 4px @brand-main-color;
  }
}
