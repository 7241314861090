.checkout-v2-view {
  .billing {
    .use-shipping-address {
      .font-sans-serif(@font-size-small);
      color: #000;
    }
    .use-shipping-address-details {
      margin: 10px @grid-gutter-width;
      .font-sans-serif(@font-size-small);
    }

    .checkout-options {
      .cvv-panel {
        .make-md-column(12);
        .form-group {
          margin:0;
          .make-responsive-padding-top(24px, 24px, 0, 0);
          padding-right:0;
          padding-left: 38px;
          .make-responsive-padding-bottom(60px, 60px, 15px, 15px);
          input {
            padding-right: 24px;
          }
          .form-control-feedback {
            position:relative;
            display:inline-block;
            overflow:visible;
            width:0;
            top:2px;
            height:auto;
            left:-27px;
          }
          .help-text {
            .font-sans-serif-bold(@font-size-x-small);
            padding-top: 8px;
            .hidden-xs;
            .hidden-sm;
          }
        }
      }
    }
    .cvv-field {
      input {
        padding-left: 20px;
        padding-right: 24px !important;
      }
      .form-control-feedback {
        left: 60px;
      }
      .tooltip-inner {
        margin-left: -50px;
      }
    }

    input.security-code[type="text"]{
      width: 80px;
    }
    .stripe {
      float: right;
      .hidden-xs;
    }

    .billing-address {
      margin-top: @grid-gutter-width;
    }

    button.continue {
      float: left;
      .clearfix;
      margin-top: 30px;
    }
  }
}
