.size-guide {
  margin: 0;
  padding: 0;
  overflow: visible !important;
  overflow-x: auto;
  overflow-y: hidden;
  .font-sans-serif(@font-size-small);
  text-align: center;
  text-transform: capitalize;
  tr:first-child td {
    background-color: #000;
    color: #FFF;
  }
  tr:nth-child(2n+3) td {
    background: #F5F5F5;
  }
  th,td {
    border: solid 1px #EEE;
    white-space: nowrap;
  }
  td {
    padding: 3px 7px;
  }
  td:first-child {
    text-align: left;
    font-weight: bolder;
  }
}
.btn-zoom-guide {
  padding: 0 3px;
  margin-right: 2px;
  margin-top: -10px;
  float:right;
}
.size-guide-modal .modal-dialog {
  .modal-title {
    text-transform: capitalize;
    text-align: center;
  }
  .modal-content {
    overflow:auto;
    border-radius:0;
    .modal-body {
      &:extend(.clearfix all);
      .size-guide {
        overflow:scroll !important;
        margin: 0 auto;
      }
    }
  }
  .modal-footer {
    padding: 8px;
    button {
      padding-top:7px !important;
      padding-bottom:7px !important;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.zoomable-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
