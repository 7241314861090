.product-quick-view {
  display: flex;
  flex-direction: column;
  width: 100%;

  .close-btn {
    float: right;
    background-color: transparent;
    .flaticon-solid-x;
    color: black;
    border: none;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;
    z-index: 20;
  }

  .product-details-info-container {
    .make-responsive-columns(7, 6, 12, 12);
    @media (max-width: 991px) {
      .main-info {
        text-align: center;
      }
    }
  }

  .product-image-slider {
    line-height: 512px;
    .carousel-inner {
      min-height: 512px;
      .item {
        img {
          .make-responsive-height(513.2px, 558.24px, 500px, 230px);
          .make-responsive-width(342.2px, 372.2px, 333.3px, 345px);
        }
      }
    }
  }

  .size-chart {
    .font-family-sans-serif(@font-size-small);
    margin-top: 20px;
  }

  .btn-add-to-cart {
    @media (min-width: @screen-md-min) {
      width: 67% !important;
    }
  }

  .size-chart, .help {
    @media (max-width: @screen-sm-max) {
      text-align: center;
    }
  }
}
.details-container {
  margin-top: 20px;
}
.product-quick-view .size-chart, .product-details-view .help {
  font-family: 'OMBR',Arial,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  margin-top: 10px;
  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 800;
  }
}
.panel-heading {
  a {
    cursor: pointer;
    .plus {
      .pull-right();
      .flaticon-solid-plus;

      &.collapsed {
        .flaticon-solid-minus;
      }
    }
  }
}
.extra-details {
  margin-bottom: 15px;
  font-family: 'OMSN',Times,serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: inherit;
  color: #000;
  max-height: 180px;
  margin-top: 15px;
  overflow-y: auto;
    h3, span {
      .font-sans-serif;
      font-weight: 800;
      font-size: 13px;
      text-transform: uppercase;
    }
}
.quick-product-container {
  display: flex;
  margin: 1em;
  max-height: 80vh;
  overflow: auto;
  @media (max-width: 991px) {
    flex-direction: column;
    // max-height: 90vh;
    .product-details-image-container {
      flex: 1 0;
      .swiper-slide {
        max-height: auto;
      }

    }
  }
  .extra-details {
    max-height: inherit;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #f3f3f3;
  }

  &::-webkit-scrollbar:vertical {
    width: 7px;
  }

  &::-webkit-scrollbar-button:increment,
  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e6e6;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}