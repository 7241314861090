.trend-stories{
  .container;
  text-align: center;
  color: @new-light-gray;
  &>h2{
    margin: 45px 0;
    .make-responsive-font-size(@font-size-xx-m-large,@font-size-xx-m-large,@font-size-big-large,@font-size-big-large);
  }
  article{
    .make-responsive-columns(4,4,12,12);
    margin-top: 40px;
    @media (min-width: @screen-md-min){
      margin-top: 0px;
    }
    div{
      header{
        img{
          width: 100%;
          &.walk-the-mile{
            width: auto;
          }
        }
        h2{
          margin-top: 5px;
          .make-responsive-line-height(42px, 42px, @font-size-big-large, @font-size-big-large);
          .make-responsive-font-size(@font-size-xx-m-large,@font-size-xx-m-large,@font-size-big-large,@font-size-big-large);
        }
      }
      a{
        .font-sans-serif(@font-size-small);
        color: @new-light-gray;
        &:hover>.read-more{
          color:@brand-main-color;
        }
      }
      .read-more{
        text-transform: uppercase;
      }
    }
    ul{
      li{
        list-style: none;
        a{
          color: @new-light-gray;
          &:hover{
            color: @brand-main-color;
          }
          &.more-stories{
            .font-sans-serif(@font-size-small);
          }
          article{
            margin: 15px 0;
            width: 100%;
            padding: 0;
            img{
              .make-responsive-columns(4,4,4,4)
            }
            div{
              .make-responsive-columns(8,8,8,8);
              padding: 0;
              text-align: left;
              h2{
                margin: 0;
                .font-serif();
                .make-responsive-font-size(@font-size-big-large,@font-size-big-large,@font-size-large-medium,@font-size-large-medium);
                line-height: 28px;
              }
              p{
                .font-sans-serif(@font-size-small);
              }
            }
          }
        }
        &:first-child{
          article{
            margin-top: 0;
          }
        }
      }
    }
  }
  .flaticon.solid.right-2{
    display: inline;
  }
}
