.gift-card-check {
  .modal-dialog {
    .make-md-column(12);
    padding:0;
    .modal-content {
      width: 100%;
      height: 600px;
      background: url(/img/value-proposition.jpg) no-repeat;
      border-radius: 0;

      .copy-wrapper {
        .make-responsive-columns(7,8,12,12);
        .copy {
          margin: 120px auto;
          .make-md-column(10);
          .make-md-column-offset(1);

          text-align: center;

          h3 {
            .font-family-serif(22px);
            text-transform: uppercase;
            margin: 20px 0 30px 0;
          }

          .brand-stich {
            margin: 27px auto;
          }

          .anonymous-subscription {
            p {
              .font-sans-serif(@font-size-base);
              text-transform: uppercase;
            }
            .referer-form {
              margin-top: 60px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
