.admin-bulk-invite {
  h2 {
    margin-top:@margin-default*2;
  }
  table {
    .font-family-sans-serif-light(@font-size-small);
    tr {
      &:nth-child(odd) {
        background: #f0f0f0;
      }
      td,th {
        padding:2px;
      }
      th {
        font-weight: bolder;
      }
    }
  }
  .error {
    color:@error-color;
  }
}