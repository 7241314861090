@brand-main-color:		#ABA59B;
@brand-main-color-light:		#E4E0DB;
@brand-main-color-dark:		#ABA59B;
@themile-primary:		#8B9FA8;
@themile-primary-light:		#F4F4F4;
@themile-primary-medium:		#C5D4DB;
@themile-primary-dark:		#697E88;

@brand-gray-dark:       #666;
@brand-gray:            #d7d7d5;
@brand-gray-light:      #f1f1f1;

@brand-watermark: #aaa;

@tertiary-color: #DE565A;
@tertiary-color-dark: #AB4346;

@action-color: #666;
@action-color-hover: #d4d4d4;

@button-color: #222;
@button-color-hover: #333;
@button-color-disabled: #d7d7d5;

@error-color: #DE565A;
@error-color-light: #fbe7e9;

@positive-feedback-color: #b5cf70;

@diamond-blue: #cee4ee;
@color-diamond-blue: #cee4ee;
@color-peach: #fbf2e9;

@new-light-gray: #5b5b5b;

.make-transition-effect {
  .transition(~"all ease-in-out .35s");
}

//The Mile COLORS
@color-peach: #FBF2E9;
@color-peach-dark: #E4DFD8;
@diamond-blue: #CEE4EE;
@diamond-blue-light: #EFF6F9;
@diamond-blue-dark: #BCC8CE;
@brand-gray-color: #fafafa;

.themile-primary {
  background-color: @themile-primary;
}
.themile-primary-color {
  color: @themile-primary;
}
.brand-main-color-dark {
  color: @brand-main-color-dark;
}

.themile-primary-light {
  background-color: @themile-primary-light;
}
.themile-primary-light-color {
  color: @themile-primary-light;
}
.themile-main-color {
  color: @brand-main-color;
}
.brand-main-color-light {
  background-color: @brand-main-color-light;
}