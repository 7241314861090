.product-details-view .tabs {
  .panel {
    border-top: 1px solid @brand-gray-light;
    &:last-child {
      border-bottom: 1px solid @brand-gray-light;
    }
    .panel-heading {
      a {
        cursor: pointer;
        .panel-title {
          .margin-top-bottom-medium;
          .font-sans-serif-bold(@font-size-base);
          text-transform:uppercase;
          .plus {
            .pull-right();
            .flaticon-solid-minus;
          }
        }
        &.collapsed {
          .panel-title {
            .font-sans-serif(@font-size-base);
            .plus {
              .flaticon-solid-plus;
            }
          }
        }
      }
    }
    .panel-body {
      .margin-bottom-medium;

      .font-serif(@font-size-small);
      text-transform: inherit;
      color: #000;
      max-height: 220px;
      overflow-y: auto;
      span {
        .font-serif(@font-size-small);
      }
      @media (max-width: @screen-sm-max) {
        max-height: none;
        overflow-y: inherit;
      }
      ul {
        margin-left: 20px;
      }
      h3, h4, h5 {
        .font-sans-serif;
        text-transform: capitalize;
        color: #000;
        margin: 0 0 10px 0;
      }
      h3 {
        .font-sans-serif(@font-size-base);
      }
      h4 {
        .font-sans-serif(@font-size-small);
      }
      h5 {
        .font-sans-serif(@font-size-small);
        font-style: italic;
      }
      p, li {
        .font-serif(@font-size-small);
        color: #000;
      }
      a {
        .font-sans-serif(@font-size-small);
        text-decoration: underline;
        text-transform: none;
      }
      .code {
        margin-top: 20px;
      }
    }
  }
}
