.brand-filter {
  .font-sans-serif(@font-size-small);
  border-top: 1px solid #f1f1f1;
  margin-left:@margin-default;
  margin-right:@margin-default;
  @media (max-width: @screen-xs-max) {
    background-color: @brand-gray-light;
  }
  .panel-heading {
    @media (max-width: @screen-xs-max) {
      border-bottom: 1px solid @brand-gray;
    }
  }
  .panel-title {
    .font-sans-serif(@font-size-small);
    height:30px;
    padding-top: 8px;
    text-align: left;
    @media (max-width: @screen-xs-max) {
      span {
        .font-family-sans-serif(@font-size-medium);
        color: #333;
        text-transform: uppercase;
        margin:0;
      }
    }
  }
  .icon {
    float:right;
  }
  .reset {
    float:right;
    margin-right:@margin-default;
    text-decoration: underline;
  }
  .brand-list {
    .scrollbars-always-visible();
    max-height: 200px;
    list-style: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    cursor: pointer;
    &.disabled {
      color: #bbb;
      cursor: default;
    }
    input {
      position: relative;
      float: left;
      margin-left:@margin-default;
      &.checkbox-admin {
        margin-left:0px;
      }
    }
    li {
      a {
        color: #333;
        max-width: calc(100% - 44px);
      }
    }
    @media (max-width: @screen-xs-max) {
      li {
        .font-family-sans-serif(@font-size-medium);
        border-bottom: 1px solid @brand-gray;
        padding: 15px 15px;
        margin-bottom: 0px;
      }
      .space {
        width:10px;
        display:inline-block;
      }
    }
    @media (min-width: @screen-sm-min) {
      li {
        padding-bottom: 5px;
        a {
          padding-left: 2px;
          font-weight: bold;
        }
      }
    }
  }
  .checkmark-container {
    @media (max-width: @screen-xs-max) {
      .font-family-sans-serif-bold(@font-size-large);
      border: none;
      position: relative;
      float: right;
    }
  }
}
