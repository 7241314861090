.price-filter {
  .font-sans-serif(@font-size-small);
  border-top: 1px solid #f1f1f1;
  .panel-title {
    @media (min-width: @screen-sm-min) {
      .font-sans-serif(@font-size-small);
      color: #000;
      padding-top: 8px;
    }
    @media (max-width: @screen-xs-max) {
      .font-family-sans-serif(@font-size-medium);
      text-transform: uppercase;
      color: #333;
      padding-top: 15px;
    }
    height:30px;
    text-align: left;
    .make-responsive-margin-left(@margin-default, @margin-default, @margin-default, 0);
    .reset {
      float:right;
      margin-right:@margin-default;
      text-decoration: underline;
    }
  }
  .panel-content {
    margin-left:@margin-default;
    display: inline-block;
    input {
      text-align: right;
      margin-left: @margin-default/2;
      margin-right: @margin-default/2;
      margin-bottom: @margin-default;
      margin-top: @margin-default;
      width: 50px;
      &.error {
        color:red;
      }
    }
    .btn-apply {
      font-size: @font-size-small;
      margin-left: 7.5px;
      margin-right: 7.5px;
      margin-bottom: 15px;
      height: 35px;
      width: 55px;
      padding: 2px 0 0;
    }
  }
}
