body, p {
  @media (min-width: 1600px) {
    font-size: 16px;
  }
}
@header-height: 160px;
@header-height-md: 95px;
@header-height-sm: 65px; // same as xs but extra space for breadcrumb
@header-height-xs: 48px;
@header-message-height: 30px;
@header-height-md-without-message: 130px;

.with-sale-banner header.site-header{
  margin-top: 0;
  padding-top: @header-height-md;
}
header.site-header {
  // margin-top to reveal the header-message
  margin-top: @header-message-height;
  // padding-top: @header-message-height;
  width:100%;
  margin-bottom:0;
  z-index: -1;
  .header-container {
    .header-inner{
      padding: 0 2rem;
      height: 81px;
      background: rgba(255, 255, 255, 0.70);
      backdrop-filter: blur(6.295964241027832px);
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .the-mile-nav {
        a img {
          width: auto;
          height: 100%;
        }
      }
    }
    position: relative;
    .header-subnav {
      position: relative;
      height: inherit;
      top: 4px;
      box-shadow: none;
      align-content: center;
      .hidden-xs;
      .hidden-sm;
      &.with-kids {
        ul {
          gap: 1rem;
          @media (max-width: 1199px) { gap: 0.5rem;}

          li {
            a {
              .make-responsive-padding-left(0px, 0px, 15px, 11px);
              .make-responsive-padding-right(0px, 0px, 15px, 11px);
              .make-responsive-font-size(@font-size-small,@font-size-small,@font-size-x-small,@font-size-x-small);
              letter-spacing: 0.25px;
              line-height: 1.1;
              @media (min-width: 1600px) {
                font-size: 16px;
              }
            }
          }
        }
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        li {
          display: inline-block;
          &.holiday {
            background-size: 100% 100%;
            height: 50px;
            a {
              color: @brand-main-color;
              &.last{
                padding-right: 5px;
                padding-left: 5px;
              }
            }
          }
          &.red {
            a {
              color: @tertiary-color;
            }
          }
          a {
            display: block;
            text-align: center;
            .make-responsive-padding-left(21px, 12px, 15px, 11px);
            .make-responsive-padding-right(21px, 12px, 15px, 11px);
            font-size: @font-size-small;
            // &:focus {
              //color: #000;
            // }
            &.open,:hover{
              color: @brand-main-color;
            }


            padding-top: 12px;
            padding-bottom: 19px;
            text-decoration: none;
            text-transform: uppercase;
            .font-sans-serif(@font-size-base);
            height: 50px;
            &.active {
              box-shadow: 0 -2px 0 @brand-main-color inset;
            }
            &.last{
              text-align: right;
              padding-right: 0px;
              padding-left: 10px;
            }
          }
        }
      }
    }
    .btn-primary{
      &:focus{
        background-color: transparent;
        color: black;
      }
      &:active{
        box-shadow: none;
        -webkit-box-shadow: none;
      }
      min-width: 190px;
      border: 1px solid transparent;
      color: black;
      margin-top: 10px;
      padding-top: 17px;
      &.US{
          padding-top: 7px !important;
      }
      &.false {
          padding-top: 15px;
      }
    }
    .dropdown-menu>li>a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #333;
      white-space: nowrap;
    }

    .nav.consolidate-links {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      .welcomeName{
        margin-right: 5px;
        font-weight: bold;
        .make-responsive-font-size(@font-size-small,@font-size-xx-small,@font-size-x-small,@font-size-x-small);
        @media (min-width: 1600px) {
          font-size: 14px;
        }
}
      .points {
        text-transform: none;
        .make-responsive-font-size(@font-size-small,@font-size-xx-small,@font-size-x-small,@font-size-x-small);
      }
      .dropdown-menu {
        min-width: 200px;
        border: 1px solid transparent;
        li {
          .make-responsive-padding-left(0px, 0px, 0px, 0px);
          float: initial;
          a {
            line-height: 30px;
            .make-responsive-padding-left(15px, 15px, 15px, 15px);
          }
        }
        &.user {
          .user-email {
            display: none;
          }
        }
      }
      .dropdown-menu.flag {
        min-width: 145px;
        @media (max-width: 479px) { min-width: 100px; }
      }
      li.language {
        display: flex;
        align-items: center;
        svg {
          width: 15px;
          top: 6px;
          margin-right: 6px;
        }
        p.language {
          top: -2px;
          position: relative;
          display: inline-block;
          letter-spacing: initial;
        }
      }
      .wishlist {
        background: url('/img/the-mile/heart.svg') no-repeat;
        width: 19px;
        height: 19px;
        padding: 6px;
        &.active {
          background: url('/img/icons/heart-active.svg') no-repeat;
        }
      }
      .flags {
        width: auto;
        padding: 0px;
        background-size: cover;
        font-size: 1.2rem;
        .font-sans-serif;
        &.us:before {
          content: "USD / EN";
        }
        &.kr:before {
          content: "KRW / KO";
        }
        &.au:before {
          content: "AUD / EN";
        }
        &.ca:before {
          content: "CAD / EN";
        }
      }
      a {
        line-height: 1;
      }
    }
    .nav-container {
      .make-responsive-columns(5,5,4,4);
      padding-left: 0;
      .nav {
        .hidden-xs;
        .hidden-sm;
        li {
          .dropdown-menu{
            z-index: 1000;
            padding: 20px;
            .name {
              font-size: 14px
            }
            .points{
              font-size: 12px;
            }
          }
          .make-responsive-padding-right(30px, 15px, 20px, 20px);
          &:last-child {
            padding-right: 0;
          }
          a {
            .make-responsive-letter-spacing(0.1em, 0.04em, 0.04em, 0);
          }
        }
      }
      @media (max-width: 479px) {
        width: auto;
        float: none;
        padding:0!important;
      }
    }
    .checkout-header{
      .make-responsive-columns(5,5,4,4);
      color: @brand-main-color;
      .font-sans-serif-bold(@font-size-small);
      .make-responsive-line-height(80px, 80px, 50px, 50px);
    }
    .logo-container {
      .make-responsive-columns(2,2,4,4);
      padding-left: 0;
      padding-right: 0;
      .text-right;
      .make-responsive-padding-top(12px, 12px, 10px, 10px);
      .make-responsive-padding-bottom(10px, 10px, 10px, 0px);
      .slogan {
        .font-serif-italic(13px);
        color: black;
        width: 250px;
        text-align:center;
        letter-spacing: 1px;
        margin-left: auto;
        margin-right: auto;
        left: 50%;
        .make-responsive-translateX(-50%,-50%,-50%,-50%);

        position: absolute;
        .hidden-xs;
        .hidden-sm;
        .make-responsive-margin-top(-6px, -6px, 0px, 0px);
      }
      @media (max-width: 479px) {
        width: auto;
        float: none;
        padding:0!important;
      }
    }
    .user-container {
      .make-responsive-columns(5,5,4,4);
      padding-right: 0px;
      .nav {
        float: right;
        li {
          .hidden-xs;
          .hidden-sm;
          .make-responsive-padding-left(15px, 10px, 8px, 8px);
          a {
            .make-responsive-letter-spacing(0.1em, 0.04em, 0.04em, 0);
          }
          &:first-child {
            padding-left: 0px;
          }
          &.cart  {
            display: block !important;

            .shopping-bag-icon {
              display: inline;
              @media (max-width: @screen-sm-max) {
                display: inline-block;
              }
              position: relative;
              .number {
                width: 17px;
                height: 17px;
                letter-spacing: 0px;
                background-color: @brand-main-color;
                border-radius: 16px;
                text-align: center;
                .font-sans-serif(@font-size-x-small);
                color: #ffffff;
                line-height: 16px;
                display: inline-block;
                position: absolute;
                top:-4px;
                left:11px;
              }

              .icon {
                .flaticon-solid-cart;
                &:after {
                  font-size: 20px;
                  @media (max-width: @screen-sm-max) {
                    font-size: 22px;
                    line-height: 2;
                  }
                }
               display: inline-block;
              }
            }
          }
          &.the-mile-logo {
            display: block !important;
            img {
              .make-responsive-width(55px, 55px, 40px, 40px);
            }
          }
        }
        li.flag-orchard {
          li {
            display: block!important;
          }
        }
      }
      @media (max-width: 479px) {
        width: auto;
        float: none;
        padding:0!important;
        .nav.consolidate-links {
          padding-top: 0;
        }
      }
    }
    .search-box-mobile {
      .flaticon-stroke-zoom-2;
      .hidden-lg;
      .hidden-md;
      .make-xs-column(1);
      .make-sm-column(1);
      .make-responsive-navbar-vertical-align(16px);
      z-index: 3;
      display: inline-block;
      margin-left: 10px;
      font-size: @font-size-medium;
      text-decoration: none;
      &.active {
        color:@brand-main-color !important;
      }
      &::before {
        font-size: 20px;
      }
    }

    .search-box-in-header{
      display: flex;
      align-items: center;
      margin-top: 0;
      z-index: 3;
    }
    .search-box {
      margin-left: 0;
      .flaticon-stroke-zoom-2;
      cursor: pointer;
      &.active {
        color:@brand-main-color !important;
      }
      &:before{
        font-size: 16px;
      }
    }

    .nav {
      list-style: none;
      li {
        float: left;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;

        a {
          .font-sans-serif(@font-size-base);
          padding-top: 10px;
          .make-responsive-line-height(@navbar-height,@navbar-height,60px,45px);

          padding: 0;
          text-decoration: none;

          &:hover, &.open {
            color: @brand-main-color;
          }
        }
      }
      ul.flag {
        margin-top: 12px;
        li {
          a {
            font-size: 1.2rem;
          }
        }
      }
      .user.btn-group.dropdown {
        button {
          padding: 1rem;
          margin: 0;
          min-width: 0;
          background-color: transparent;
          @media (max-width: 1199px) { padding: 0;}
        }
      }
    }
  }
  &.the-mile {
    box-shadow: 1px 1px 5px #00000021;
  }
}
.breadcrumbs-container {
  // .container;
  .breadcrumbs {
    margin-top:16px;
    margin-bottom:10px;
  }
  z-index: 1;
  position: relative;
}
.sticky-header-rail {
  z-index:26;
}

.dragging-mode {
  .sticky-header-rail {
    visibility: hidden;
  }
}

button .sweepstakes {
  color: @brand-main-color;
}

.the-mile .header-inner {
  align-items: center;
  display: flex!important;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 479px) {
    height: 60px;
    .the-mile-header a img {
      height: inherit;
      width: 80px;
    }
  }
}
.the-mile-header {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 4px;
  a img{
    position: relative;
    // height: 70px;
    width: 110px;
    padding-top: 5px;
  }
}
@media (max-width: 767px) {
  header.site-header .header-container .user-container .nav li.cart .shopping-bag-icon .number {
    top: 0!important;
    left: 10px!important;
    margin-top: -6px;
  }
  header.site-header.the-mile {
    z-index: 100!important;
    position: fixed!important;
  }
  .row {
    margin-right: 0!important;
    margin-left: 0!important;
    padding: 0 5px!important;
  }
}
@media (max-width: 1199px) {
  .header-inner .the-mile-nav {
    display: flex;
    a img {
      width: 130px!important;
      margin-left: 10px;
    }
  }
}
.OM {
  position: relative;
  z-index: 3;
}
.the-mile-nav {
  position: relative;
  z-index: 4;
}
.search-container {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  background: #ffffffd1;
  box-shadow: 0 4px 4px #0000001c;
  z-index: 4;
}
.reel-play {
  &:hover{ color: @brand-main-color-dark;}
  img {
    width: 12px;
    margin-top: -5px;
    margin-right: 3px;
    color: white;
  }
}