@facebook-blue: #3b5998;

.facebook-login {
  .line-separator {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    span {
      .font-serif-italic;
      padding: 0 10px;
      font-size: 18px;
      color: #999;
      @media (max-width: @screen-sm-max) {
        top: -10px;
      }
    }
    .separator {
      height: 0;
      flex: 1 auto;
      position: relative;
      overflow: visible;
      border-bottom: 1px solid #ccc;
      margin: 20px 0;
    }    
  }
  button {
    position: relative;
    background-color: @facebook-blue;
    &:disabled {
      background-color: @button-color-disabled;
      color: #999;
      &:before {
        border-right: 1px solid rgba(153,153,153,0.3);
      }
    }
    @media (max-width: @screen-sm-max) {
      padding-left: 59px;
      padding-right: 20px;
    }
    .flaticon-social-facebook;
    &:before {
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 50px;
      left: 0;
      top: 0;
      border-right: 1px solid rgba(255,255,255,0.3);
      font-size: 22px;
      line-height: 50px;
      @media (max-width: @screen-sm-max) {
        width: 39px;
        font-size: 16px;
        line-height: 39px;
      }
    }
  }
}
