.stories-view {
  .stories-category-filter {
    .font-sans-serif(@font-size-small);
    text-align: center;
    margin-bottom: @margin-default*4;
    margin-top: @margin-default*3;
    a {
      display:inline-block;
      margin: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid #f9f9f9;
      letter-spacing: 1px;
      &:hover {
        border-bottom: 1px solid @brand-main-color;
      }
      &.active {
        color: @brand-main-color;
        border-bottom: 1px solid @brand-main-color;
      }
    }
    .separator {
      padding-left:@margin-default*2;
      &:hover {
        border-color: #f9f9f9;
        cursor: auto;
      }
      &:after{
        content:"\00a0"
      }
    }
  }

}
