.sweepstakes-view {
  min-height: 990px;
  .container();
  .margin-bottom-medium;
  .text-center;

  h1 {
    .margin-bottom-medium;

    .title-alternative-word {
      .font-serif-italic(@font-size-xx-large);
    }
  }

  .loyalty-content {
    .make-row();
    .make-responsive-columns(10, 10, 9, 12);
    .make-responsive-columns-offset(2, 2, 3, 0);

    &.guest {
      .make-responsive-columns-offset(1, 1, 1, 0);
    }

    .anchor {
      display: block;
      height: 126px; /*same height as header*/
      margin-top: -126px; /*same height as header*/
      visibility: hidden;
    }

    h2.title-loyalty {
      .make-responsive-font-size(@font-size-xl-m-large; @font-size-xl-large; @font-size-x-large; @font-size-x-big-large);
    }

    .tier-banner {
      background: no-repeat center top;
      background-size: cover;

      .make-responsive-min-height(300px, 300px, 183px, 183px);

      .text-banner {
        background-color: rgba(255, 255, 255, 0.7);
        display: inline-block;
        vertical-align: middle;
        padding-top: 7px;
        padding-bottom: 7px;
        .make-responsive-padding-left(21px, 21px, 18px, 8px);
        .make-responsive-padding-right(21px, 21px, 18px, 8px);
        .text-center;

        h1 {
          margin-bottom: 15px;
          margin-top: 10px;
          .make-responsive-font-size(@font-size-xl-large; @font-size-x-large; @font-size-x-large; @font-size-x-big-large);
          .font-sans-serif-bold;
          .make-responsive-letter-spacing(5px, 5px, 4px, 3px);
        }
      }
    }

    .loading {
      .font-serif-italic(@font-size-large);
      .text-center;
      margin: 0 0 5em 0;
    }

    .how-works, .member, .terms {
      margin: 5em 0;
    }
  }

  hr {
    border-top: 1px solid #a5a5a5;
  }

  .referrer-friends .share-form {
    button {
      @media (max-width: @screen-xs-max) {
        width: auto;
      }
    }

    form {
      margin-top: 14px;
    }
  }

  input {
    border-radius: 0;
    -webkit-border-radius: 0;
    .make-responsive-padding-top(12px; 12px; 9px; 9px);
    .make-responsive-padding-bottom(12px; 12px; 8px; 8px);
    .make-responsive-columns(4, 4, 8, 8);
    .make-responsive-columns-offset(4, 4, 2, 2);
    .font-sans-serif();
    .make-responsive-font-size(@font-size-medium, @font-size-medium, @font-size-medium, @font-size-medium-large);

  }

  .required-message {
    margin-bottom: 0;
    .make-responsive-padding-top(12px; 12px; 9px; 9px);
    .make-responsive-padding-bottom(12px; 12px; 8px; 8px);
    .make-responsive-columns(4, 4, 8, 8);
    .make-responsive-columns-offset(4, 4, 2, 2);
    padding-left: 0;
  }

  .active {
    top: 144px !important;
  }
}

.full-width {
  width: 100% !important;
  margin-left: 0 !important;
  margin-top: 15px !important;
}

.text-om {
  color: @brand-main-color;
}

.close-btn {
  float: right;
  background-color: transparent;
  .flaticon-solid-x;
  color: black;
  border: none;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 12px;
}

.subscribe-button {
  background: #ABA59B;
}

.peach-button {
  background-color: #EEE5DC;
  color: #000;
  min-width: 242px;
  margin: 5px;
}

.error-summary {
  .make-responsive-columns(4, 4, 8, 8);
  .make-responsive-columns-offset(4, 4, 2, 2);
}
