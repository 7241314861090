.admin-product-rankings {
  .table {
    tr:nth-child(even) { background: #f4f4f4 }
    button {
      padding: 3px 10px 3px 10px;
    }
    .turned-on {
      color: green;
    }
    .turned-off {
      color: #ccc;
    }
  }
}
.product-ranking-modal {
  .modal-body {
    max-height:400px;
    overflow:auto;
    padding:30px;
  }
  h3 {
    text-transform: capitalize;
    .turn-on-off {
      cursor: pointer;
      text-transform: uppercase;
      margin-left: 20px;
      .font-serif-italic(12);
      .on {
        color:green;
      }
    }
  }
  ul {
    list-style: none;
    li {
      padding-top:10px;
      margin-top:5px;
      border-top: solid 1px #eee;
    }
  }
  .weights {
    .attribute {
      font-weight: bolder;
      &.light {
        color: #ccc;
      }
    }
    .value {
      float:right;
      text-align: right;
    }
    .help {
      margin-top:10px;
      .font-serif-italic(12);
    }
  }
  button {
    padding: 10px;
    margin-left: 20px;
    &.cancel {
      background: #888;
    }
  }
  .progress-bar {
    padding:0;
    height:2px;
    width:50%;
    background:#eee;
    div {
      background:#000;
      margin:0;
      padding:0;
      height:2px;
    }
  }
}