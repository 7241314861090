
.overlay(@color: #000, @opacity: .3) {
  position: relative;
  &::before {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: @color;
    opacity: @opacity;
    content: "";
  }
}
