.content-popup {
  max-height: 100%;
  .modal-dialog {
    .make-responsive-left(calc(50% - 480px), calc(50% - 480px), 0%, 0%);
    position: absolute;
    top: calc(50% - 300px);
    margin: 0px auto;
    max-height: 100%;
    .make-responsive-width(960px, 960px, 100%, 100%);
    .modal-content {
      height: auto;
      border-radius: 0;
      position: relative;
      @media (min-width: @screen-md-min) {
        width: 960px;
        .body-wrapper {
          .body {
            text-align: center;
          }
        }
      }
      @media (max-width: @screen-sm-max)  {
        .body-wrapper {
          .body {
            width: 90%;
          }
        }
      }
      &>div {
        position: relative;
        background-position-x: center !important;
        height: auto;
        background-size: cover!important;
        padding: 2.5em 1.5em 2em 1.5em;
        width: 100%;
      }
      .close-btn {
        position: absolute;
        top: 30px;
        .make-responsive-top(30px, 30px, 30px, 5px);
        .make-responsive-right(30px, 30px, 30px, 5px);
        display: inline-block;
        border: 0;
        .flaticon-solid-x-rotate;
        font-size: 20px;
        background: transparent;
        &:hover {
          opacity: 0.5;
        }
      }

      .body-wrapper {
        .body {
          margin: 0 auto!important;
          .make-responsive-margin-top(50px, 50px, 50px, 160px);
          width: 100%;
          text-align: center;
          h3 {
            .font-family-serif(22px);
            text-transform: uppercase;
            margin: 20px 0 30px 0;
          }
          .brand-stich {
            margin: 27px auto;
          }
          .popup-brand {
            .make-responsive-padding(45px,45px,45px,15px);
            .make-responsive-width(660px,660px,660px,100%);
            background-color: rgba(255,255,255,0.6);
            margin-left: auto;
            margin-right: auto;
            .close-btn {
              color: white;
            }
            .referer-form{
               input {
                 .make-responsive-width(60%,60%,60%,60%);
               }
               button {
                 .make-responsive-width(40%,40%,40%,40%);
               }
            }
            h6 {
              .font-serif-italic();
              .make-responsive-font-size(@font-size-x-large,@font-size-x-large,@font-size-x-large,@font-size-medium-large);
            }
            .brand-logo {
              .make-responsive-margin-left(0, 0, auto, auto);
              .make-responsive-margin-right(0, 0, auto, auto);
              svg {
                .make-responsive-margin-top(0px, 0px, 0px, 0px);
                .make-responsive-width(95%, 95%, 50%, 50%);
                .make-responsive-height(40px, 40px, 40px, 25px);
              }
              &.white {
                svg {
                  .svg-color(#FFF);
                  @media (max-width: @screen-sm-max) {
                    .svg-color(#000);
                  }
                }
              }
            }
            p.line-height-xs {
              line-height: 1;
            }
          }
          .anonymous-subscription, email-subscribe {
            .footer-copy {
              display: none;
            }
            p {
              .font-sans-serif(@font-size-base);
              text-transform: uppercase;
            }
            .referer-form {
              margin-top: 30px;
              width: 100%;
            }
            .form-success-message {
              display: none;
            }
          }
        }
      }
    }
  }
  &.popup-marketing, &.popup-general, &.content-popup.popup-4thjuly2019 {
    .modal-dialog {
      .body-wrapper {
        .body {
          margin: 0px;
          .make-responsive-margin-top(0px,0px, 0px, 0px);
          .popup-marketing, .popup-general {
            .make-responsive-padding(45px,45px,45px,15px);
            .make-responsive-width(660px,660px,660px,95%);
            background-color: white;
            margin-left: auto;
            margin-right: auto;
            .close-btn {
              color: white;
            }
            .close-btn {
              color: black;
            }

            h6 {
              .font-serif-italic();
              .make-responsive-font-size(@font-size-x-large,@font-size-x-large,@font-size-x-large,@font-size-medium-large);
            }

          }
        }
      }
    }
  }
}
