.nav-tabs>li>a, .nav-tabs>li>a:hover, .nav-tabs>li>a:focus{
  border: transparent;
  border-bottom: 1px solid #010101;
  .font-family-sans-serif-bold(@font-size-x-small);
  text-align: center;
  color: #999999;
  height:30px;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus{
  border: transparent;
  border-bottom: 3px solid #010101;
  font-weight:bold;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
  color: #000;
}
.nav-tabs>li{
  cursor: pointer;
}
.tab-pane{
  .font-family-sans-serif-light(@font-size-base);
  text-transform:none;
}
.nav-tabs>li>a{
  margin-right:0;
}
.tab-content>.active{
  padding-top:30px;
}
.nav-tabs{
  border:transparent;
}
.nav>li>a:hover, .nav>li>a:focus {
  background:transparent;
  color: #000;
}