.admin-modal {
  .modal-header {
    h3 {
      font-size: @font-size-large;
    }
  }
  .modal-content {
    .border-radius(0);

    .alert {
      .font-sans-serif(@font-size-small);
      margin-bottom: 5px;
      &.alert-info {
        border-left: 5px solid #31708f;
      }
    }

    .tracking-url {
      a {
        .font-sans-serif(@font-size-small);
        text-decoration: underline;
      }
    }

    .total-reference {
      .font-sans-serif(@font-size-medium);
      margin-top: 40px;
    }

    button {
      min-width: 100px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 7px;
      &.secondary {
        background-color: #666;
        margin-left: @margin-default;
      }
    }
  }
}
