.contact-us-view {
  .container();
  header {
    .margin-top-large;
    .text-center;
    .brand-stitch {
      margin-top: 27px;
      margin-bottom: 27px;
    }
  }
  form {
    .margin-top-medium;
    button {
      display: block;
      margin: 20px auto 90px auto;
    }
  }
}
