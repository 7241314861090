.static-page {
  .margin-bottom-large;

  .page-nav-container {
    right: 10px;
    width: 225px;
    z-index: 1;
    .page-summary-nav {
      background: rgba(255, 255, 255, 0.7);
    }
  }

  a {
    margin: 20px 0 0 0;
  }

  &.use-content-width {
    .container;
    .page-content {
      .make-md-column(10);
      .make-md-column-offset(1);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    .text-center;
  }

  h1 {
    .font-size(50px, 50px, 32px, 32px);
  }

  h2 {
    .font-size(30px, 30px, 24px, 24px);
  }

  h3 {
    .font-size(20px, 20px, 18px, 18px);
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  .brand-stitch {
    margin-top: 24px;
    margin-bottom: 27px;
  }
  .hero-image-150 {
    height: 150px;
    background-repeat: no-repeat;
    background-position-x: center;
    .make-responsive-height(150px, 150px, 150px, 60px);
    @media (max-width: @screen-xs-max) {
      background-size: 200%;
    }
  }
  .layout-two {
    .right {
      .make-responsive-padding-left(15px, 15px, 30px, 30px);
      .make-responsive-padding-right(60px, 50px, 30px, 30px);
    }
  }


  ul, ol {
    -webkit-padding-start: 20px;
  }
  .hero-image {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .hero-image-panel {
    height: 300px;
  }

  .shop-view.brand-products {
    .hero-image-panel {
         .make-hero-image-panel;
    }
  }

  .product-item-container {
    .make-md-column(3);
    .make-sm-column(6);
    .product-comment {
      text-align: center;
    }
  }

  .browser-not-supported {
    text-align: center;
    .logo {
      margin-top: 30px;
      svg {
        width: 220px;
      }
    }
    .outer {
      display: table;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      .middle {
        display: table-cell;
        vertical-align: middle;
        .inner {
          margin-left: auto;
          margin-right: auto;
          width: 95%;
          h1 {
            .font-serif-italic(24px);
          }
          p {
            line-height: 1.5em;
          }
          a {
            text-transform: none;
            text-decoration: underline;
          }
          @media (max-width: @screen-xs-max) {
            p, a {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .page-list {
    background: rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;

    .btn-close {
      position: absolute;
      top: 10px;
      right: 15px;

      .square(30px);
      .flaticon-solid-x;
      color: #000;
      font-size: 24px;
    }

    ul {
      margin: 0 0 0 110px;
      list-style-type: none;
       a {
        .link();
      }
    }
  }

  .page-content.loading-editor {
    opacity: 0.5;
  }
  .page-content{
    .shop-view{
      .number-of-products{
        padding-left: 2px;
      }
    }
  }
  .live-edit-panel {
    padding-top: 20px;
    margin-top: 20px;
    border-top: solid black 2px;
    background: rgba(0, 0, 0, 0.1);
    .container;
    .make-md-column(10);
    .make-md-column-offset(1);
    .edit-top-toolbar {
      text-align: right;
      a {
        .link(@font-size-large);
      }
      .buttons {
        a {
          .btn();
        }
      }
      .uploaded-files {
        padding: 0 30px 0 0;
        display: inline-block;
        vertical-align: top;
        list-style-type: none;
        max-height: 100px;
        overflow: scroll;
      }
      img {
        max-width: 64px;
        max-height: 30px;
      }
    }
    textarea {
      font-family: monospace;
      font-size: 14px;
      width: 100%;
      height: 500px;
    }
    .buttons {
      text-align: right;
      margin-right: 10px;
    }
    .CodeMirror {
      border: 1px solid #eee;
      height: auto;
    }
  }

  .inspiration-hero {
    background-size: cover;
    .make-responsive-padding(55px;55px;25px;25px);
    .margin-bottom-medium;
    background-position: top;
    height:auto;
    @media (max-width: @screen-xs-max) {
      height: 500px;
    }
    .container-inspiration {
      .make-responsive-padding(55px;55px;25px;25px);
      .text-center;
      h2 {
        .margin-bottom-small;
        .font-serif-italic-bold;
      }
      .brand-stitch {
        margin: 15px auto;
      }
      .make-responsive-width(42%;50%;100%;100%);
      background-color: rgba(255,255,255,0.8);
      @media (max-width: @screen-sm-max) {
          bottom: 0;
          right: 0;
          position: absolute;
      }
      div {
        .text-uppercase;
        .font-sans-serif-bold(@font-size-large);
      }
      p {
        .font-sans-serif-bold(@font-size-large-medium);
      }
    }
  }

  .influencer-hero {
    background-size: cover;
    display: -moz-inline-stack;
    display: inline-block;
    width: 100%;
    position: relative;
    .make-responsive-padding(55px;55px;25px;25px);
    .margin-bottom-medium;
    @media (max-width: @screen-sm-max) {
      height: 700px;
      background-position: 50%;
    }
    .container-influencer {
      .make-responsive-padding(55px;55px;25px;25px);
      .text-center;
      h2 {
        .margin-bottom-small;
        .font-serif-italic-bold;
      }
      .brand-stitch {
        margin: 15px auto;
      }
      .make-responsive-width(42%;50%;100%;100%);
      background-color: rgba(255,255,255,0.8);
      @media (max-width: @screen-sm-max) {
          bottom: 0;
          right: 0;
          position: absolute;
      }
      div {
        .text-uppercase;
        .font-sans-serif-bold(@font-size-large);
      }
      p {
        .font-sans-serif-bold(@font-size-large-medium);
      }
    }
  }

  @media (min-width: 768px) {
    .container-inspiration-hero {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  }

  .large-container {
    h4, h3{
      line-height: 1.3;
    }
    span.title-inspiration {
      font-size: @font-size-xx-large;
      line-height: 1;
      display: block;
    }
    p.detail {
      line-height: 1.15;
      margin-top: 10px;
    }
    .detail a {
      text-decoration: none;
    }
    .social-icons a {
      padding: 0 10px;
    }
    button.inspiration-button {
      font-size: @font-size-base;
      padding: 0.6rem 62px;
    }
    .hero-link-inspiration {
      cursor: pointer;
    }
    @media (min-width: 1281px) and (max-width: 1440px) {
      h2 {
        font-size: @font-size-x-big-large;
      }
      h3 {
        font-size: @font-size-big-large;
      }
      h4 {
        font-size: @font-size-large;
      }
      small {
        font-size: 90%;
        line-height: 1;
      }
      span.title-inspiration {
        font-size: 62px;
      }
    }
    @media (min-width: 1441px) {
      h1 {
        font-size: 64px;
      }
      h2 {
        font-size: @font-size-x-large;
      }
      h3 {
        font-size: @font-size-x-big-large;
      }
      h4 {
        font-size: @font-size-big-large;
      }
      small {
        font-size: 100%;
        line-height: 1.1;
      }
      span.title-inspiration {
        font-size: 65px;
      }
      button.inspiration-button {
        font-size: @font-size-large;
      }
    }
    @media (max-width: 767px) {
      p.detail {
        height: 50px;
      }
    }
  }

}
