.admin-mode {
  display:none !important;
}

.user-has-role-admin {
  .admin-mode {
    display:inherit !important;
    position: relative;
    z-index: 10;
    .admin-tools {
      position:absolute;
      z-index: 10;
      @media (max-width: @screen-sm-max) {
        display: none;
      }
      &.vertical {
        button, a, .flag {
          clear: both;
          margin-bottom: 5px;
        }
      }
      button, a {
        float:left;
        margin-right: 3px;
        .font-family-sans-serif-light(@font-size-x-small);
        color: #fff;
        background-color: #333;
        padding: 5px 10px;
        text-decoration: none;
        &:hover {
          background-color: #555;
        }
      }
      a.curations-hotness {
        float: left;
        padding: 3px 2px;
        background-color: transparent;
        display: none;
        &:hover {
          background-color: transparent;
        }
        .svg-color(#ddd);
        &.curations-loading {
          .svg-color(#f1f1f1);
        }
        &.curations-hot {
          .svg-color(#e25822);
        }
        &.curations-nothot {
          .svg-color(#bbb);
        }
      }
      .flag {
        float:left;
        .font-sans-serif(@font-size-x-small);
        color: #fff;
        white-space: nowrap;
        background-color: #aaa;
        padding: 2px 5px;
        margin-right: 5px;
      }
      .product-scores-indicator {
        float:left;
        margin-top:3px;
        opacity: .5;
        margin-left: 5px;
        &:hover {
          opacity: 1;
        }
      }
      .item-selector {
        display:none;
      }
      .sold-out-warning {
        float:left;
        display:inline-block;
        margin:0 5px 0 5px;
        padding: 5px;
        background: #f00;
        color: #fff;
        font-weight: bolder;
      }
      .clipboard-selector {
        float: left;
        display: inline-block;
        margin-left:25px;
      }
      .product-user-tagged-warning {
        float: left;
        display: inline-block;
        margin: 2px 5px 0 10px;
        .collision {
          color:red;
        }
      }
    }
  }

  .selectable-items {
    .admin-mode {
      .admin-tools {
        .item-selector {
          display: inline;
        }
      }
    }
  }
}


