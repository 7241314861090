.category-tree {
  padding: 0;
  border: 0;
  width: 84%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: @screen-xs-max) {
    background-color: @brand-gray-light;
  }
  a {
    display:block;
    @media (min-width: @screen-sm-min) {
      .font-family-sans-serif(@font-size-small);
      line-height: @font-size-small;
    }
    @media (max-width: @screen-xs-max) {
      .font-family-sans-serif(@font-size-medium);
      color: #333;
    }
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 5px 0;
    margin:0;
    text-transform: capitalize;
    @media (min-width: @screen-sm-min) {
      &:link,&:visited{
        color:#000;
        text-decoration:none;
      }
      &:focus {
        color: @brand-main-color;
        text-decoration:none;
      }
      &.active {
        .font-family-sans-serif-bold(@font-size-small);
      }
    }
    @media (max-width: @screen-xs-max) {
      background-color: @brand-gray-light;
      &:link,&:visited,&:focus,&:hover{
        color: #333;
        text-decoration:none;
      }
    }
  }
  .category-heading {
    color: #333;
    @media (min-width: @screen-sm-min) {
      background-color: transparent;
      border: 0;
      margin:0;
      a {
        padding: 20px 0 10px 0;
        margin:0;
      }
    }
    @media (max-width: @screen-xs-max) {
      background-color: @color-peach;
      border-bottom: 1px solid @brand-gray;
      a {
        padding: 15px 0;
        margin:0;
      }
      .lvl0-icon{
        float:right;
        .font-family-sans-serif(@font-size-medium);
      }
    }
    padding: 0;
  }
  @media (max-width: @screen-xs-max) {
    .lvl0 {
      .category-heading {
        a {
          text-transform: uppercase;
        }
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .lvl0 {
      .category-heading {
        border-bottom: 1px solid #f1f1f1;
        &.active {
          // remove border from active
          border-bottom:0;
        }
      }
      &:last-child .category-heading {
        // remove border from last
        border-bottom:0;
      }
      .active{
        .lvl0-icon{
          .font-family-sans-serif-bold(@font-size-small);
        }
      }
      .lvl0-icon{
        float:right;
        .font-family-sans-serif(@font-size-x-small);
      }
    }
  }
  .category-body {
    @media (min-width: @screen-sm-min) {
      .font-family-sans-serif-light(@font-size-x-small);
    }
    padding-left: 10px;
    border:0;
    height:auto;
    overflow:hidden;
    max-height:2000px;
    display:inherit;
    @media (max-width: @screen-xs-max) {
      background-color: @brand-gray-light;
    }
    -webkit-transition: max-height 3s ease;
    -moz-transition: max-height 3s ease;
    transition: max-height 3s ease;
    .checkmark-container {
      @media (min-width: @screen-sm-min) {
        display: inline-block;
        border: solid #999 1px;
        border-radius: 2px;
      }
      @media (max-width: @screen-xs-max) {
        .font-family-sans-serif-bold(@font-size-large);
        border: none;
        position: relative;
        float: right;
      }
    }
    .flaticon.solid.checkmark {
      visibility: hidden;
    }
    .active {
      .flaticon.solid.checkmark {
        visibility: visible;
      }
    }
    &.collapsed {
      display: none;
      max-height:0;
      margin:0 !important;
      padding:0 !important;
      -webkit-transition: max-height .3s ease;
      -moz-transition: max-height .3s ease;
      transition: max-height .3s ease;
    }
    &.lvl1 {
      @media (min-width: @screen-sm-min) {
        .category-heading {
          border-bottom: 0;
        }
        &.active {
          border-bottom: 1px solid #f1f1f1;
          padding-bottom: 10px;
        }
      }
      a {
        @media (min-width: @screen-sm-min) {
          padding: 10px 0;
        }
        @media (max-width: @screen-xs-max) {
          padding: 15px 0;
          text-transform: capitalize;
        }
      }
      .space {
        width:10px;
        display:inline-block;
      }
    }
    &.lvl2 {
      @media (min-width: @screen-sm-min) {
        padding-top: 4px;
        padding-bottom: 4px;
        .category-heading {
          border-bottom: 0;
        }
        a {
          padding: 6px 0;
        }
      }
      @media (max-width: @screen-xs-max) {
        div {
          border-bottom: 1px solid @brand-gray;
        }
        a {
          padding: 15px 0;
        }
        span {
          .font-family-sans-serif(@font-size-medium);
        }
      }
    }
  }
}
