.stories-view {
  .tiles {
    .container-fluid();
    max-width: 1800px;
    margin: -10px auto 30px auto;
    padding: 0;
    .box-sizing-border-box;
    border: 3px solid #fff;
    .tile {
      position: relative;
      width: 100%;
      min-height: 1px;
      float:left;
      .box-sizing-border-box;
      border: 3px solid #fff;
      background-color: #333;
      color: #fff;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.tile-hidden{
        display:none;
      }

      .defer-image {
        position: relative;
        background: #fff;
        &.tile-one-across > .ph {
          padding-top: 43.33333333%; // 1800x780
        }
        &.tile-two-across > .ph {
          padding-top: 87.542087542%; // 891x780
        }
        &.tile-two-across-left-offset > .ph {
          padding-top: 131.31313131%; // 594x780
        }
        &.tile-two-across-right-offset > .ph {
          padding-top: 65.656565657%; // 1188x780
        }
        &.tile-four-across > .ph {
          padding-top: 179.7235023%; // 434x780
        }
        &.tile-mobile-one-across-tall > .ph {
          padding-top: 70.37037037% // 756x532
        }
        &.tile-mobile-one-across-short > .ph {
          padding-top: 43.915343915% // 756x332
        }
        &.tile-one-across > .ph-header {
          padding-top: 33.33333333%;
        }
        .ph {
          position: relative;
          top: 0;
          left: 0;
          .ph-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        > img {
          opacity: 0;
          min-width: 100%;
          max-width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          .transition(opacity 0.2s);
          &.ready {
            opacity: 1;
          }
        }
      }

      &.tile-first {
        &.no-overlay {
          .first-inner {
            background-color: transparent;
          }
        }
        &.tile-no-content {
          .first-inner {
            display: none;
          }
        }
        .first-inner {
          position: absolute;
          text-align: center;
          padding: 10px;
          h3 {
            text-align: center;
            .font-size(60px, 50px, 48px, 26px);
            .make-responsive-color(#FFF, #FFF, #000, #000);
            margin-top: 10px;
          }
          p {
            text-align: center;
            .make-responsive-color(#FFF, #FFF, #000, #000);
          }
          .discover {
            display: inline-block;
            .font-sans-serif(16px);
            letter-spacing: 1px;
            text-transform: uppercase;
            padding-bottom: 7px;
            margin-bottom: 15px;
            .make-responsive-color(#FFF, #FFF, #000, #000);
            .make-transition-effect;
            &:hover{
              background-color: #ffffff;
              color: @new-light-gray;
            }
          }
          @media (min-width: @screen-md-min) {
            padding: 0;
            color: #fff;
            p {
              margin-top: 40px;
              margin-bottom: 0;
              font-size: 18px;
              padding-left: 5px;
              padding-right: 5px;
            }
            .discover {
              color: #FFF;
              border: 2px solid #FFF;
              padding: 12px 30px 12px 30px;
            }
          }
          @media (max-width: @screen-sm-max) {
            background-color: #FFF;
            position: relative;
            color: #000;
            padding: 10px;
            .brand-stitch {
              margin-bottom: 0;
            }
            p {
              display: none;
              margin-top: 15px;
              margin-bottom: 0;
              font-size: 14px;
            }
            .discover {
              margin-top: 15px;
              color: #000;
              border: 2px solid #000;
              padding: 12px 30px 12px 30px;
              font-size: 16px;
              &:hover{
                border: 2px solid @new-light-gray;
                background-color: @new-light-gray;
                color: #ffffff;
              }
            }
          }
        }
        &.tile-one-across {
          .first-inner {
            @media (min-width: @screen-md-min) {
              width: 70%;
              top: 50%;
              left: 85%;
              .transform(translate(-50%, -50%));
            }
          }
          &.tile-lg-overlay-width-small {
            .first-inner {
              @media (min-width: @screen-lg-min) {
                width: 35%;
              }
            }
          }
        }
        &.tile-two-across {
          .first-inner {
            @media (min-width: @screen-md-min) {
              bottom: 0;
              width: 80%;
              left: 50%;
              .transform(translateX(-50%));
            }
            @media (min-width: @screen-lg-min) {
              bottom: 0;
              width: 60%;
              left: 50%;
              .transform(translateX(-50%));
            }
          }
        }
        @media (min-width: @screen-md-min) {
          &.content-left {
            .first-inner {
              -webkit-transform: translate(-100%, -50%);
              -ms-transform: translate(-100%, -50%);
              transform: translate(-100%, -50%);
              width: 70%;
            }
          }
          &.content-right {
            .first-inner {
              -webkit-transform: translate(0, -50%);
              -ms-transform: translate(0, -50%);
              transform: translate(0, -50%);
              width: 70%;
            }
          }
          &.content-black {
            .first-inner {
              h3 {
                color: #FFF;
              }
              p {
                color: #FFF;
              }
              .discover {
                text-transform: uppercase;
                color: #FFF;
                padding: 12px 30px 12px 30px;
                border: 2px solid #FFF;
              }
            }
          }
        }
      }

      &.tile-brand {
        &.tile-two-across-right-offset, &.tile-two-across {
          .brand-inner{
            .brand-logo {
              width: 35%;
              .transform(translateX(95%));
              @media (max-width: @screen-sm-max) {
                width: 60%;

                .transform(translateX(33%));

              }
            }
          }
        }
        &.tile-four-across {
          .brand-logo {
            .make-responsive-margin-top(35px, 35px, 0px, 0px);
            padding: 0px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        .brand-inner {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          .message {
            position: absolute;
            width: 100%;
            top: 0;
            background-color: black;
            opacity: .6;
            .font-sans-serif-bold(@font-size-base);
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            padding: 5px 20px;
            @media (max-width: @screen-xs-max) {
              padding: 5px 10px;
              font-size: @font-size-small;
            }
            &.message-white {
              background-color: #fff;
              opacity: .6;
              color: #000;
            }
            .small {
              display: none;
            }
          }
          .brand-logo {
            position: absolute;
            bottom: 0px;
            width: 60%;

            margin-left: auto;
            margin-right: auto;
            .transform(translateX(33%));
            padding: 20px;

            padding-top: 10px;
            background-color: rgba(255,255,255,0.6);
            .make-responsive-height(30%,40%,60%,65%);
            svg {
              display: block;
              width: 100%;
              max-width: 90%;
              margin-left: auto;
              margin-right: auto;
              height: 100%;
              max-height: 70%;
            }

            &.black {
              svg {
                .svg-color(#000);
              }
            }
          }
          .brand-bottom {
            position: absolute;
            width: 100%;
            .make-responsive-bottom(15px, 15px, 30px, 10px);
            text-align: center;
            .discover {
              display: inline-block;
              .font-sans-serif(12px);
              letter-spacing: 1px;
              color: #fff;
              text-transform: uppercase;
              padding-bottom: 7px;
              border-bottom: 1px solid #000;
              color: #000;
            }
          }
        }
      }

      &.tile-story {
        &.no-overlay {
          .story-inner {
            background-color: transparent;
          }
        }
        &.tile-no-content {
          .story-inner {
            display: none;
          }
        }
        .story-inner {
          position: absolute;
          text-align: center;
          background-color: rgba(255,255,255, 0.8);
          padding: 10px;
          h3 {
            text-align: center;
            margin-top: 0;
            .font-size(60px, 50px, 30px, 18px);
            color: #000;
            margin-top: 10px;
          }
          p {
            text-align: center;
            color: #000;
          }
          .discover {
            display: inline-block;
            .font-sans-serif(12px);
            letter-spacing: 1px;
            text-transform: uppercase;
            padding-bottom: 7px;
            margin-bottom: 15px;
            color: #000;
          }
          @media (min-width: @screen-md-min) {
            padding: 5px 30px;
            color: #fff;
            p {
              margin-top: 40px;
              margin-bottom: 0;
              font-size: 18px;
              padding-left: 5px;
              padding-right: 5px;
            }
            .discover {
              margin-top: 40px;
              color: #000;
              border-bottom: 1px solid #000;
            }
          }
          @media (max-width: @screen-sm-max) {
            left: 10px;
            right: 10px;
            .make-responsive-bottom(10px, 10px, 10px, 10px);
            background-color: #fff;
            opacity: .6;
            color: #000;
            padding: 10px;
            .brand-stitch {
              margin-bottom: 0;
            }
            p {
              display: none;
              margin-top: 15px;
              margin-bottom: 0;
              font-size: 14px;
            }
            .discover {
              margin-top: 15px;
              color: #000;
              border-bottom: 1px solid #000;
              font-size: 10px;
            }
          }
        }
        &.tile-one-across {
          .story-inner {
            @media (min-width: @screen-md-min) {
              width: 40%;
              top: 50%;
              left: 50%;
              .transform(translate(-50%, -50%));
            }
          }
          &.tile-lg-overlay-width-small {
            .story-inner {
              @media (min-width: @screen-lg-min) {
                width: 35%;
              }
            }
          }
        }
        &.tile-two-across {
          .story-inner {
            @media (min-width: @screen-md-min) {
              bottom: 0;
              width: 80%;
              left: 50%;
              .transform(translateX(-50%));
            }
            @media (min-width: @screen-lg-min) {
              bottom: 0;
              width: 60%;
              left: 50%;
              .transform(translateX(-50%));
            }
          }
        }
        @media (min-width: @screen-md-min) {
          &.content-left {
            .story-inner {
              -webkit-transform: translate(-100%, -50%);
              -ms-transform: translate(-100%, -50%);
              transform: translate(-100%, -50%);
              width: 40%;
            }
          }
          &.content-right {
            .story-inner {
              -webkit-transform: translate(0, -50%);
              -ms-transform: translate(0, -50%);
              transform: translate(0, -50%);
              width: 40%;
            }
          }
          &.content-black {
            .story-inner {
              h3 {
                color: #000;
              }
              p {
                color: #000;
              }
              .discover {
                color: #000;
                border-bottom: 1px solid #000;
              }
            }
          }
          &.content-black-overlay {
            &.no-overlay {
              .story-inner {
                background-color: transparent;
              }
            }
            .story-inner {
              width: 65%;
              background-color: #fff;
              opacity: .85;
              padding: 50px;
              h3 {
                color: #000;
              }
              p {
                color: #000;
              }
              .discover {
                color: #000;
                border-bottom: 1px solid #000;
              }
            }
          }
        }
      }
      &.tile-video {
        .video-inner {
          position: absolute;

          padding: 0;
          color: #000;
          background-color: rgba(255,255,255,0.6);
          text-align: center;

          h3 {
            margin-top: 10px;
            text-align: center;
            margin-top: 0;
            .font-size(60px, 50px, 30px, 30px);
          }
          .brand-stitch {
            display: none;
            margin-bottom: 16px;
          }
          .play {
            .flaticon-solid-play;
            font-size: 60px;

            @media (max-width: @screen-md-max) {
              font-size: 60px;
            }
          }
          .video-bottom {
            text-align: center;
            margin-top: 9px;
            .font-sans-serif(12px);
            .discover {
              display: inline-block;
              letter-spacing: 1px;
              color: #000;
              text-transform: uppercase;
              padding: 0 0 7px 0;
              margin: 0 20px 15px 20px;
              border-bottom: 1px solid #000;
            }
          }
        }
        @media (min-width: @screen-md-min) {
          .video-inner {
            width: 70%;
            top: 50%;
            left: 50%;
            .transform(translate(-50%, -50%));
            padding: 20px;
          }
          &.content-left {
            .video-inner {
              -webkit-transform: translate(-100%, -50%);
              -ms-transform: translate(-100%, -50%);
              transform: translate(-100%, -50%);
              width: 40%;
            }
          }
          &.content-right {
            .video-inner {
              -webkit-transform: translate(0, -50%);
              -ms-transform: translate(0, -50%);
              transform: translate(0, -50%);
              width: 40%;
            }
          }
        }
        // accommodate to dual link in large desktop
        @media (min-width: @screen-lg-min) {
          .video-inner {
            .video-bottom.with-alternate {
              .link-container {
                float: left;
                width: 50%;
                &:first-child {
                  text-align: right;
                  .discover {
                    margin-right: 20%;
                    margin-left: 0;
                  }
                }
                &:last-child {
                  text-align: left;
                  .discover {
                    margin-left: 20%;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
        @media (max-width: @screen-sm-max) {
          .video-inner {
            left: 10px;
            right: 10px;
            bottom: 10px;
            padding: 10px;
            p {
              display: none;
            }
          }
        }
      }

      &.sales-season {
        .brand-inner {
          .message {
            background-color: @brand-main-color;
          }
        }
      }

      @media (min-width: @screen-md-min) {
        &.tile-one-across {
        }
        &.tile-two-across {
          width: 50%;
        }
        &.tile-two-across-left-offset {
          width: 33.333334%
        }
        &.tile-two-across-right-offset {
          width: 66.666666%
        }
        &.tile-four-across {
          width: 25%;
        }
        img.mobile {
          display: none;
        }
      }
      @media (max-width: @screen-sm-max) {
        img.desktop {
          display: none;
        }
      }

      .shop-instagram {
        .make-responsive-padding(20px, 20px, 12px, 12px);
        .make-responsive-font-size(18px, 18px, 15px, 15px);
        .font-sans-serif;
        background-color: @color-peach;
        color: @new-light-gray;

        div {
          margin: auto;
          text-align: center;
          display: table;

          div {
            .make-responsive-line-height(24px, 24px, 18px, 18px);
            display: inline;
            margin-left: 8px;
          }

          .flaticon-social-instagram {
            .make-responsive-font-size(24px, 24px, 18px, 18px);
            vertical-align: middle;
            display: table-cell;
          }

          .right-2 {
            vertical-align: middle;
          }
        }


      }
    }
    .carousel {
      .box-sizing-border-box;
      border: 3px solid #fff;
      .carousel-inner > .item {
        .transition(1.2s ease-in-out left);
      }
      .tile {
        border: 0;
      }
      .carousel-control {
        background: none;
      }
      .carousel-indicators {
        @media (max-width: @screen-sm-max) {
          bottom: 5px;
          li {
            width: 5px;
            height: 5px;
            &.active {
              width: 7px;
              height: 7px;
            }
          }
        }
        li {
          border-color: #000;
          margin-right: 5px;
          &.active {
            margin-right: 4px;
            background-color: #000;
          }
        }
      }
    }
  }
}

body.live-mode {
  .tiles {
    .tile.tile-hidden-production {
      display: none;
    }
  }
}
