
.page-nav-container {
  position: absolute;
  top: 90px;
  .page-summary-nav {
    width: 225px;
    .nav {
      li>a {
        padding:5px 15px;
      }
      .nav {
        margin-left: 15px;
        li>a {
          text-transform: none;
        }
      }
    }
  }
}

// this is a hack for the nav not to overlap headings

h2,h3,h4,h5,h6 {
  &[id]:before {
    display: block;
    content: " ";
    margin-top: -80px;
    height: 80px;
    visibility: hidden;
  }
}

.modal-panel {
  h2,h3,h4,h5,h6 {
    &[id]:before {
      display: none;
    }
  }
}
