@font-face {
  font-family: 'Flaticons Social';
  src: url('flaticons-social.eot');
  src: url('flaticons-social.eot?#iefix') format("embedded-opentype"),
    url('flaticons-social.woff2') format('woff2'),
    url('flaticons-social.woff') format('woff'),
    url('flaticons-social.ttf') format('truetype'),
    url('flaticons-social.svg#flaticons-social') format('svg');
  font-weight: normal;
  font-display: swap;
  font-style: normal; }

.flaticon.social:before {
  font-family: 'Flaticons Social';
  speak: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.flaticon-social {
  &:before{
    font-family: 'Flaticons Social';
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
  }
}

.flaticon-social-facebook {
  .flaticon-social();
  &:before { content: "\e7d0"; }
}
.flaticon-social-twitter {
  .flaticon-social();
  &:before { content: "\e7d1"; }
}
.flaticon-social-instagram {
  .flaticon-social();
  &:before { content: "\e7dd"; }
}
.flaticon-social-youtube {
  .flaticon-social();
  &:before { content: "\e7f6"; }
}
.flaticon-social-pinterest {
  .flaticon-social();
  &:before { content: "\e7d5"; }
}
.flaticon-social-linkedin {
  .flaticon-social();
  &:before { content: "\e7da"; }
}

.flaticon.social.facebook-1:before,
.flaticon.social.facebook:before,
.flaticon.social.A1:before { content: "\e7d0"; }

.flaticon.social.twitter-1:before,
.flaticon.social.twitter:before,
.flaticon.social.A2:before { content: "\e7d1"; }

.flaticon.social.kippt-1:before,
.flaticon.social.kippt:before,
.flaticon.social.A3:before { content: "\e7d2"; }

.flaticon.social.vimeo-1:before,
.flaticon.social.vimeo:before,
.flaticon.social.A4:before { content: "\e7d3"; }

.flaticon.social.flickr-1:before,
.flaticon.social.flickr:before,
.flaticon.social.A5:before { content: "\e7d4"; }

.flaticon.social.pinterest-1:before,
.flaticon.social.pinterest:before,
.flaticon.social.A6:before { content: "\e7d5"; }

.flaticon.social.skype-1:before,
.flaticon.social.skype:before,
.flaticon.social.A7:before { content: "\e7d6"; }

.flaticon.social.dribbble-1:before,
.flaticon.social.dribbble:before,
.flaticon.social.A8:before { content: "\e7d7"; }

.flaticon.social.tumblr-1:before,
.flaticon.social.tumblr:before,
.flaticon.social.A9:before { content: "\e7d8"; }

.flaticon.social.google-plus-1:before,
.flaticon.social.google-plus:before,
.flaticon.social.A10:before { content: "\e7d9"; }

.flaticon.social.linkedin-1:before,
.flaticon.social.linkedin:before,
.flaticon.social.A11:before { content: "\e7da"; }

.flaticon.social.github-1:before,
.flaticon.social.github:before,
.flaticon.social.A12:before { content: "\e7db"; }

.flaticon.social.vine-1:before,
.flaticon.social.vine:before,
.flaticon.social.A13:before { content: "\e7dc"; }

.flaticon.social.instagram-1:before,
.flaticon.social.instagram:before,
.flaticon.social.B1:before { content: "\e7dd"; }

.flaticon.social.rdio-1:before,
.flaticon.social.rdio:before,
.flaticon.social.B2:before { content: "\e7de"; }

.flaticon.social.dropbox-1:before,
.flaticon.social.dropbox:before,
.flaticon.social.B3:before { content: "\e7df"; }

.flaticon.social.rss-1:before,
.flaticon.social.rss:before,
.flaticon.social.B4:before { content: "\e7e0"; }

.flaticon.social.cargo-1:before,
.flaticon.social.cargo:before,
.flaticon.social.B5:before { content: "\e7e1"; }

.flaticon.social.stumbleupon-1:before,
.flaticon.social.stumbleupon:before,
.flaticon.social.B6:before { content: "\e7e2"; }

.flaticon.social.paypal-1:before,
.flaticon.social.paypal:before,
.flaticon.social.B7:before { content: "\e7e3"; }

.flaticon.social.behance-1:before,
.flaticon.social.behance:before,
.flaticon.social.B8:before { content: "\e7e4"; }

.flaticon.social.zootool-1:before,
.flaticon.social.zootool:before,
.flaticon.social.B9:before { content: "\e7e5"; }

.flaticon.social.etsy-1:before,
.flaticon.social.etsy:before,
.flaticon.social.B10:before { content: "\e7e6"; }

.flaticon.social.foursquare-1:before,
.flaticon.social.foursquare:before,
.flaticon.social.B11:before { content: "\e7e7"; }

.flaticon.social.facebook-2:before,
.flaticon.social.B12:before { content: "\e7e8"; }

.flaticon.social.twitter-2:before,
.flaticon.social.B13:before { content: "\e7e9"; }

.flaticon.social.kippt-2:before,
.flaticon.social.C1:before { content: "\e7ea"; }

.flaticon.social.vimeo-2:before,
.flaticon.social.C2:before { content: "\e7eb"; }

.flaticon.social.flickr-2:before,
.flaticon.social.C3:before { content: "\e7ec"; }

.flaticon.social.linkedin-2:before,
.flaticon.social.C4:before { content: "\e7ed"; }

.flaticon.social.facebook-3:before,
.flaticon.social.C5:before { content: "\e7ee"; }

.flaticon.social.twitter-3:before,
.flaticon.social.C6:before { content: "\e7ef"; }

.flaticon.social.kippt-3:before,
.flaticon.social.C7:before { content: "\e7f0"; }

.flaticon.social.vimeo-3:before,
.flaticon.social.C8:before { content: "\e7f1"; }

.flaticon.social.flickr-3:before,
.flaticon.social.C9:before { content: "\e7f2"; }

.flaticon.social.pinterest-2:before,
.flaticon.social.C10:before { content: "\e7f3"; }

.flaticon.social.skype-2:before,
.flaticon.social.C11:before { content: "\e7f4"; }

.flaticon.social.dribbble-2:before,
.flaticon.social.C12:before { content: "\e7f5"; }

.flaticon.social.youtube-1:before,
.flaticon.social.youtube:before,
.flaticon.social.C13:before { content: "\e7f6"; }

.flaticon.social.facebook-4:before,
.flaticon.social.D1:before { content: "\e7f7"; }

.flaticon.social.twitter-4:before,
.flaticon.social.D2:before { content: "\e7f8"; }

.flaticon.social.kippt-4:before,
.flaticon.social.D3:before { content: "\e7f9"; }

.flaticon.social.vimeo-4:before,
.flaticon.social.D4:before { content: "\e7fa"; }

.flaticon.social.flickr-4:before,
.flaticon.social.D5:before { content: "\e7fb"; }

.flaticon.social.pinterest-3:before,
.flaticon.social.D6:before { content: "\e7fc"; }

.flaticon.social.skype-3:before,
.flaticon.social.D7:before { content: "\e7fd"; }

.flaticon.social.dribbble-3:before,
.flaticon.social.D8:before { content: "\e7fe"; }

.flaticon.social.tumblr-2:before,
.flaticon.social.D9:before { content: "\e7ff"; }

.flaticon.social.google-plus-2:before,
.flaticon.social.D10:before { content: "\e800"; }

.flaticon.social.linkedin-3:before,
.flaticon.social.D11:before { content: "\e801"; }

.flaticon.social.rss-2:before,
.flaticon.social.D12:before { content: "\e802"; }

.flaticon.social.stumbleupon-2:before,
.flaticon.social.D13:before { content: "\e803"; }
