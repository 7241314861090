.the-mile-dashboard {
  margin-bottom: 80px;
  text-rendering: geometricPrecision;
  :focus-visible {
    outline-color: white!important;
    outline: none!important;
    box-shadow: none!important;
    border: 0px solid white!important;
  }
  :focus {
    outline-color: white!important;
    outline: none!important;
    box-shadow: none!important;
    border: 0px solid white!important;
  }
  h1 {
    .font-serif-bold;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 0.41px;
    color: white;
    flex: none;
    flex-grow: 0;
  }

  h5 {
    font-family: 'Sabon LT Std';
    font-style: italic;
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    text-align: center;
  }

  h2 {
    padding-top: 20px;
    font-family: 'BrownPro';
    font-style: normal;
    font-weight: 700;
    font-size: 15.2063px;
    line-height: 18px;
    text-align: left;
    letter-spacing: 0.15em;
    text-transform: uppercase;

    a span {
      float: right;
      .font-sans-serif(@font-size-small);
      color: @brand-main-color;
      text-decoration: underline;
    }
  }
  #myCarousel {
    margin-bottom: 40px;
  }
  @media (max-width: @screen-xs-max) {
    .list-influencer-container {
      margin-top: 70px;
    }    
    .the-mile-shows-carousel {
      h3 {
        font-size: 20px;
        margin-top: 28px;
      }
    }
  }
}

@media (max-width: @screen-xs-max) {
  margin-top: 15px;
}
.container-main-img {
  position: relative;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
  }
}

@media (max-width: @screen-sm-max) {
  .container-main-img img {
    width: 100%;
    object-fit: cover;
  }
  .tiles {
    flex-direction: column;
  }
  .the-mile-rounded {
    padding: 25px 0;
    &.brands-carousel {
      padding: 25px 0;
    }
  }

  .wrapper-main-img {
    background-color: transparent;
    padding: 10px;
  }
  .container-main-img {
    min-height: 500px;
  }
}
.carousel-control {
  color: #ffffff54;
}
.tiles {
  display: flex;
  box-shadow: 0px 0px 4px #0000005c;
  z-index: 2;
  position: relative;
  span {
    flex: auto;
  }
}

.ambassador-btn {
  display: flex;
  background-color: @brand-main-color;
  position: absolute;
  left: 0;
  align-items: center;
  margin-top: 14px;
  box-shadow: 1px 1px 3px #00000040;
  z-index: 3;
  .wrapper-btn {
    background-color: @brand-gray-light;
    .font-serif-italic;
    color: @brand-gray-dark;
    padding: 10px 18px;
    span {
      text-transform: uppercase;
      .font-sans-serif-bold;
      color: black;
    }
  }
}

.the-mile-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 24px;
  gap: 11px;
  color: white;
  flex: 1 auto;
  img {
    width: 95px;
    @media (max-width: 480px) {
      margin-top: 13rem;
    }  
  }
}

.brand-stitch-the-mile {
  margin: 5px !important;
}

#live .status {
  background: @brand-main-color;
}

.status {
  width: auto;
  z-index: 1;
  background: @button-color;
  position: absolute;
  top: 10px;
  left: 17px;
  color: white;
  padding: 0 10px 3px;
  .font-sans-serif;
}

.influencer-list {
  max-width: 700px;
  margin: 0 auto;
}
.carousel-occasion{
  margin-top: -50px;
}

.influencer-shows {
  padding-top: 50px;
  &.mobile {
    margin: 0 -20px;
  }
  h2 {
    padding-top: 35px;
    margin-top: 0;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      margin: 15px;
    }
  }
  @media (max-width: 480px) {
    padding-top: 30px;
  }
}

#results.influencer-shows {
  padding-top: 0;
  h2 {
    margin-top: 80px;
  }
}

.slick-list {
  box-sizing: border-box;
  a:focus {
    outline: 0 auto transparent!important;
  }
  .slick-slide .influencer-check img {
    width: 24px;
  }
  .slick-slide .influencer-vip img {
    width: 24px;
  }
}
@media (min-width: 768px) {
  .list-influencer-container, .brands-carousel-container {
    margin: 50px 0;
    position: relative;
    .influencer-shows {
      padding-top: 170px;
    }
  }
}

.the-mile-rounded {

  @media (min-width: 768px) {
    position: relative;
  }

  .influencer-on-click {
    position: absolute;
    z-index: 2;
    background-color: transparent;
    width: 130px;
    height: 130px;
    margin-left: 2.5px;
    box-sizing: border-box;

    @media (max-width: 1024px) {
      margin-left: 22.5px;
    }

    @media (max-width: 768px) {
      margin-left: 9px;
    }

    @media (max-width: 480px) {
      margin-left: 0;
      width: 124px;
    }
  }

  div.rounded {
    border-radius: 50%;
    margin: 0 auto;
    background-size: cover;
    width: 100px;
    height: 100px;

    .influencer-check, .influencer-vip {
      display: flex;
      justify-content: flex-end;
    }
  }

  h6 {
    margin: 10px auto;
    font-family: 'BrownPro';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: lowercase;
  }

  a {
    outline: none;
  }

  #prevArrow, #nextArrow {
    top: 39%;
  }

}

.the-mile-carousel {
  width: 100%;
  margin: 0 auto;

  .slick-slide:not(.slick-center) {
    opacity: 0.75;
  }

  a {
    outline: none;
  }

  .slick-slide.slick-center {
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

//CAROUSEL SHOWS
.the-mile-container-show .the-mile-shows-carousel {
  width: 100%;
}

.the-mile-shows-carousel {
  width: 90%;
  margin: 0 auto;
  .carousel-item {
    position: relative;
    img {
      aspect-ratio: 9/16;
      width: 100%;
      object-fit: cover;
    }
  }

  a {
    outline: none;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
    .make-responsive-min-height(325px, 305px, 305px, 500px);
  }

  .show-on-click {
    position: absolute;
    z-index: 2;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }

  .carousel-item {
    margin-left: 8px;
    margin-right: 8px;

    .product-info {
      .product-brand {
        .font-serif(@font-size-medium-large);
      }

      &>* {
        color: @new-light-gray;
      }

      .currency-symbol {
        font-size: @font-size-base;
      }
    }

    a:hover,
    a:focus {
      .product-info {
        .product-brand {
          color: @brand-main-color;
        }

        color: @brand-main-color;
      }
    }
  }

  .prevArrowCarouselItems,
  .nextArrowCarouselItems {
    color: @brand-watermark;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    text-shadow: none;
    width: 15px;
    height: 15px;
    .make-responsive-font-size(57px, 57px, 40px, 40px);
    opacity: .7;
    text-align: center;

    &::before {
      border-top: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      position: absolute;
      width: 100%;
      left: 0px;
    }

    &::after {
      border-left: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      height: 100%;
      width: 0px;
      position: absolute;
      top: 0px;
    }

    &:hover {
      color: #b8b8b8;
      opacity: .9;
    }

  }

  .prevArrowCarouselItems {
    right: auto;
    left: -60px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    @media (max-width: @screen-sm-max) {
      left: -6px;
    }
  }

  .nextArrowCarouselItems {
    left: auto;
    right: -15px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    @media (max-width: @screen-sm-max) {
      right: -6px;
    }
  }

  .show {
    cursor: pointer;
    position: relative;

    .carousel-influencer {
      width: calc(100% - 16px);
      margin: 0 8px;
      padding: 12px;
      position: absolute;
      bottom: 0px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.528470763305322) 0%, rgba(0, 0, 0, 0.4892550770308123) 50%, rgba(0, 0, 0, 0) 100%);

      div.rounded {
        border-radius: 50%;
        .make-responsive-width(50px, 40px, 40px, 35px);
        .make-responsive-height(50px, 40px, 40px, 35px);
        margin-right: 5px;
        background-size: cover;
        border: 1px solid white;
      }

      h6 {
        .font-sans-serif-bold;
        font-size: 13px;
        line-height: 15px;
        text-align: left;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: white;

        @media (max-width: @screen-sm-max) {
          font-size: 80%;
        }
      }

      .influencer-name {
        display: flex;
        align-items: center;
        flex-direction: row;
      }

      .carousel-item {
        margin-top: @grid-gutter-width*1.2;
        margin-bottom: @grid-gutter-width*1.01;
        margin-left: 15px;
        margin-right: 15px;

        .product-info {
          .product-brand {
            .font-serif(@font-size-medium-large);
          }

          &>* {
            color: @new-light-gray;
          }

          .currency-symbol {
            font-size: @font-size-base;
          }
        }

        a:hover,
        a:focus {
          .product-info {
            .product-brand {
              color: @brand-main-color;
            }

            color: @brand-main-color;
          }
        }
      }

    }

    small {
      color: white;
      font-style: italic;
      .font-sans-serif;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 65%;

      @media (max-width: @screen-sm-max) {
        font-size: 68%;
      }
    }
  }

  .overflow-ellipsis {
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
    transition: text-overflow 0.3s linear;
    &:hover {
      overflow: visible;
      width: 100%;
      position: relative;
      height: auto;
      white-space: inherit;
    }
  }

}

//SLIDER ARROWS
#prevArrow,
#nextArrow {
  color: @brand-gray;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  text-shadow: none;
  width: 15px;
  height: 15px;
  .make-responsive-font-size(57px, 57px, 40px, 40px);
  opacity: .7;
  text-align: center;

  &::before {
    border-top: 3px solid;
    content: "";
    display: block;
    border-radius: 5px;
    position: absolute;
    width: 100%;
    left: 0px;
  }

  &::after {
    border-left: 3px solid;
    content: "";
    display: block;
    border-radius: 5px;
    height: 100%;
    width: 0px;
    position: absolute;
    top: 0px;
  }

  &:hover {
    color: #b8b8b8;
    opacity: .9;
  }
}

#prevArrow {
  right: auto;
  left: -15px;
  -webkit-transform: rotate(-45deg);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  transform: rotate(-45deg);

  /* Firefox 16+, IE 10+, Opera */
  @media (max-width: @screen-xs-max) {
    left: -30px;
  }
}

#nextArrow {
  left: auto;
  right: -60px;
  -webkit-transform: rotate(135deg);
  /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: rotate(135deg);
  /* IE 9 */
  transform: rotate(135deg);

  /* Firefox 16+, IE 10+, Opera */
  @media (max-width: @screen-xs-max) {
    right: -30px;
  }
}


//SEARCH
.search-box-the-mile {
  display: flex;
  align-items: center;
  margin: 20px auto auto auto;
  padding-left: 10px;
  border-radius: 20px;
  width: 400px;
  height: 36px;
  margin-bottom: -50px;
  background-color: #F9F9F9;
  box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.15);
  @media (max-width: @screen-xs-max) {
    width: 320px;
  }

  &&::before {
    color: @brand-watermark;
  }

  .search {
    border-bottom: none;
  }

  .search-box {
    color: #AAAAAA;
  }

  .search-term {
    background-color: #F9F9F9;
  }

  .flaticon-stroke-zoom-2;
  background-color: #F9F9F9;
  cursor: pointer;

  &.active {
    color: @brand-main-color  !important;
  }

  &:before {
    font-size: 16px;
  }
}

@media (max-width: @screen-sm-max) {
  .nav .search .criteria .search-term {
    margin-top: 0 !important;
  }
}

.show-quick-view-modal .modal-dialog {
  width: 100vw;
  height: 100% !important;
  margin: 0;
}

#occasions .slick-slide, .carousel-item, .slick-slide .play-wrapper {
  cursor: pointer;
}

.show {
  .play-icon {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    background: #00000038;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    bottom: 0;
    margin: auto;
    z-index: 1;
    img.play {
      width: 20px;
      height: 20px;
      min-height: auto;
      object-fit: contain;
      justify-content: center;
      padding: 0;
      opacity: .8;
      display: flex;
    }
  }
}
.carousel-control {
  width: 5%;
}
.the-mile-dashboard .carousel-inner {
  box-shadow: 0px 0px 7px #00000075;
  .item span {
    flex: auto;
    img{
      width: 100%;
    }
  }
}

.the-mile-dashboard #myCarousel .carousel-inner .item.active {
  display: flex;
}
.back-to-top {
  right: -3px;
  display: flex;
  bottom: 13rem;
  background-color: #000000ba;
  width: 50px;
  padding: 0;
  height: 50px;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.hidden {
  display: none;
}
.list-influencer-container {
  margin-top: 50px;
  .brands-carousel-container {
    padding-top: 3rem;
    width: 95vw;
    margin: 0 auto;
    @media (max-width: @screen-xs-max) {
      padding-top: 0;
    }
  }
}
.brands-carousel {
  .brand-logo {
    .make-responsive-margin-left(0, 0, auto, auto);
    .make-responsive-margin-right(0, 0, auto, auto);
    svg {
      margin-top: 0;
      width: 95%;
      height: 40px;
    }
    &.white {
      svg {
        .svg-color(#FFF);
        @media (max-width: @screen-sm-max) {
          .svg-color(#000);
        }
      }
    }
  }
  .brand-logo {
    .make-responsive-margin-top(0, 0, 0px, 0px);
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.the-mile-rounded {
    position: relative;
    left: auto;
    transform: none;
  }
  svg {
    g, path{
      fill: #00000055!important;
      color: #00000055!important;
    }
  }
  .prevArrowCarouselItems {
    right: auto;
    left: -60px;
    -webkit-transform: rotate(-45deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(-45deg);
    /* IE 9 */
    transform: rotate(-45deg);

    /* Firefox 16+, IE 10+, Opera */
    @media (max-width: @screen-xs-max) {
      display: none !important;
    }
  }
  .prevArrowCarouselItems,
  .nextArrowCarouselItems {
    color: @brand-gray;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    text-shadow: none;
    width: 15px;
    height: 15px;
    .make-responsive-font-size(57px, 57px, 40px, 40px);
    opacity: .7;
    text-align: center;

    &::before {
      border-top: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      position: absolute;
      width: 100%;
      left: 0px;
    }

    &::after {
      border-left: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      height: 100%;
      width: 0px;
      position: absolute;
      top: 0px;
    }

    &:hover {
      color: #b8b8b8;
      opacity: .9;
    }
  }

  .prevArrowCarouselItems {
    right: auto;
    left: -60px;
    -webkit-transform: rotate(-45deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(-45deg);
    /* IE 9 */
    transform: rotate(-45deg);

    /* Firefox 16+, IE 10+, Opera */
    @media (max-width: @screen-xs-max) {
      display: none !important;
    }
  }

  .nextArrowCarouselItems {
    left: auto;
    right: -60px;
    -webkit-transform: rotate(135deg);
    /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(135deg);
    /* IE 9 */
    transform: rotate(135deg);

    /* Firefox 16+, IE 10+, Opera */
    @media (max-width: @screen-xs-max) {
      display: none !important;
    }
  }
}
#occasions {
  padding-bottom: 30px;
  .slick-slide {
    position: relative;
    img {
      width: 100%;
    }
  }
  .text-wrapper {
    position: absolute;
    z-index: 2;
    width: 48%;
    align-items: center;
    justify-content: unset;
    padding: 2rem;
    flex-direction: column;
    display: flex;
    &.right {
      right: 0;
    }
    &.white {
      color: white;
      .occasion-btn {
        color: white;
      }
    }
    h6 {
      .font-sans-serif-bold;
    }
    p {
      text-align: center;
      .font-serif-italic;
    }
    .occasion-btn {
      background: #ffffffbf;
      padding: 0 20px;
      border-radius: 17px;
      font-size: 75%;
      box-shadow: 1px 1px 4px 1px #00000017;
      text-transform: uppercase;
      color: @brand-gray-dark!important;
      .font-sans-serif;
    }
  }
}
// new section with videos
.the-mile-container-show {
  .wrapper-main-img {
    background-image: url(../img/the-mile/final.gif);
    background-repeat: no-repeat;
    background-size: cover;
    align-items: inherit;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    padding: 0;
    @media (max-width:768px) {
      background-image: url(../img/the-mile/final-mobile.gif);
      align-items: flex-end;
    }
    .promo-code {
      position: absolute;
      left: 0;
      top: 0;
    }
    .the-mile-title {
      background-color: #00000073;
      justify-content: flex-end;
      height: auto;
    }
  }
  .brand-creator-show {
    .make-responsive-max-width(24%,30%,40%,50%);
    .influencer-shows {
      padding-top: 0;
      .show .carousel-influencer {
        width: 100%;
        margin: 0;
        padding: 18px;
        .status {
          top: -15px;
          left: 15px;
        }
      }
      .carousel-item {
        margin-left: 0;
        margin-right: 0;
        img {
          min-height: 474px;
          @media (min-width: @screen-md-min) {
            min-height: 600px;
          }
        }
      }
      .prevArrowCarouselItems {
        left: 15px;
      }
      .nextArrowCarouselItems {
        right: 15px;
      }
    }
    box-shadow: -1px -1px 3px #00000080;
  }
  @media (min-width: 1400px) {
    .the-mile-title {
      justify-content: flex-end;
      height: 100%;
    }
  }
}
