.admin-earnings {
  table {
    .font-family-sans-serif-light(@font-size-small);
    display: table;
    a {
      text-transform: none;
    }
  }

  .custom-filters {
    .font-family-sans-serif-light(@font-size-small);
    display: flex;
    margin-top: 10px;
    font-size: 17px;
    input {
      font-size: 20px;
    }
  }

  .select-filter {
    .font-family-sans-serif-light(@font-size-small);
    width: 130px;
    height: 25px;
    font-size: 16px;
  }

  .search-button {
    font-family: initial;
    font-size: 20px;
    padding: 1px 1px 1px 1px;
    line-height: 1;
    margin-left: 5px;
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }

  .search-box {
    .flaticon-stroke-zoom-2;
  }

  .select-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .select-ranking {
    font-family: 'OMBR',Arial,sans-serif;
    margin-top: 20px;
    width: 250px;
    height: 40px;
    font-size: large;
  }

  .pagination {
    font-family: initial;
    padding: 1px 1px 1px 1px;
    line-height: 1;
    margin-left: 5px;
    margin-right: 10px;
    width: 70px;
    height: 20px;
  }

  .buttons {
    margin-top: 20px;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
  }

  .section-title {
    font-family: 'OMBR',Arial,sans-serif;
    text-transform: uppercase;
    margin-top: 25px;
    margin-left: 10px;
  }

  .card {
    font-family: 'OMBR',Arial,sans-serif;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    padding: 20px;
    margin: 5px;
    display: flex;
    flex-direction: column;

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .card-content {
      margin-bottom: 20px;
      text-align: center;
    }

    .card-footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 44px;
    }
  }

  .card-primary {
    background-color: #1D84B5; /* Verde claro */
    .icon-color {
      color: #3edcf7
    }
  }

  .card-data {
    background-color: #858585; /* Verde claro */
    .icon-color {
      color: #d3d3d3
    }
  }

  .card-secondary {
    background-color: #227e0d; /* Azul claro */
    .icon-color {
      color: #45d726
    }
  }

  .card-warn {
    background-color: #c32626; /* Rojo claro */
    .icon-color {
      color: #E07A5F
    }
  }

  .icon {
    font-size: 24px;
  }

  .title {
    flex-grow: 1;
    text-align: left;
    color: #FFF;
    margin-left: 10px;
    font-size: 16px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    opacity: 1;
    font-weight: bold;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -5px;
    right: 105%;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }



  .price {
    font-size: 32px;
    font-weight: bold;
    color: #FFF;
  }



  .icon-money {
    .flaticon-solid-money;
  }

  .icon-wallet {
    &:after {
      .flaticon-solid("\e0eb");
    }
  }

  .icon-net {
    &:after {
      .flaticon-solid("\e0db");
    }
  }

  .icon-refresh {
    .flaticon-solid-refresh;
  }

  .icon-profile {
    .flaticon-solid-profile;
  }

  .icon-minus {
    .flaticon-solid-minus;
  }

  .icon-coffee {
    .flaticon-solid-coffee;
  }

  .icon-tic {
    .flaticon-solid-checkmark-circle;
  }

  .card-footer .property {
    color: #FFF;
    font-size: 14px;
    display: flex;
    height: 17px;
  }

  .card-footer .label {
    color: #FFF;
    line-height: 2;
  }
}
