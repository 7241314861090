.modal-panel .anonymous-checkout {
  .options {
    .make-row;
    .option {
      padding-left: 0;
      padding-right: 0;
      .margin-top-medium;
      a {
        .btn-wide;
        .make-responsive-columns(6, 8, 8, 8);
        .make-responsive-columns-offset(3,2,2,2);
      }
    }
  }
}
