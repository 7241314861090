.admin {
  .nav-tabs > li > a, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
    border: transparent;
    border-bottom: 1px solid #010101;
    .font-family-sans-serif-bold(@font-size-small);
    text-align: center;
    color: #000;
    padding: 0px 20px;
    height: auto;
  }
  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    border: transparent;
    border-bottom: 3px solid #010101;
  }
  .nav-tabs > li {
    cursor: pointer;
  }
  .tab-pane {
    .font-family-sans-serif-light(@font-size-base);
    text-transform: none;
    padding:0px;
    margin-top:5px;
    position:relative;
  }
  .disabled-overlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 10;
    background-color:#000;
    opacity:.1;
  }
  .overlay-container {
    position: relative;
    padding:10px;
  }
  .over-overlay {
    position:relative;
    z-index: 20;
  }
  .nav-tabs > li > a {
    margin-right: 0;
  }
  .tab-content > .active {
    padding-top: 30px;
  }
  .nav-tabs {
    border: transparent;
  }
  .nav > li > a:hover, .nav > li > a:focus {
    background: transparent;
  }
}