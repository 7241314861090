@product-price-color: #000;
@product-price-color-secondary: #737373;

.product-price {
  margin-top: 5px;
  .font-serif(@font-size-large);
  color: @product-price-color;
  letter-spacing: 0.5px;
  font-weight: 600;
  .currency {
    margin-right: 3px;
  }
  .sale {
    color: @brand-main-color;
    span.currency-value {
      color: @tertiary-color-dark;
    }
  }
  .secondary {
    text-decoration: line-through;
    color: @product-price-color-secondary;
    margin-right: 5px;
  }

  &.small {
    .font-serif(@font-size-small);
    margin: @grid-gutter-width/3 0;
  }
}
