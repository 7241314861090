.authentication-modal {
  .modal-dialog {
    .make-responsive-width(680px, 680px, 680px, auto);
  }
  .modal-content {
    .border-radius(0);
    .modal-panel {
      padding-bottom: 0;
      .logo {
        margin-top: @margin-default;
      }
      h3 {
        .font-family-serif(22px);
        .make-responsive-font-size(22px, 22px, 15px, 15px);
        margin: 20px 0 5px 0;
      }
      .row {
        .make-responsive-padding-bottom(10px, 10px, 10px, 10px);
      }
      h2 {
          font-weight: 200;
          .make-responsive-font-size(40px, 40px, 22px, 22px);
          font-style: italic;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
      }
      h4 {
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-weight: 200;
          .font-sans-serif(20px);
          .make-responsive-font-size(20px, 20px, 11px, 11px);
          margin-bottom: 5px;
      }
      label {
        .make-responsive-font-size(12px, 12px, 11px, 11px);
      }
      .close-btn {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0 0 0 0;
        background-color: transparent;
        .flaticon-solid-x;
        color: black;
        border: none;
        font-size: 16px;
        z-index: 30;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
          background-color: transparent;
        }
      }
      .modal-body{
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
      }
      .modal-header {
        margin-top: @margin-default;
      }
      .footer {
        background-color: #eee;
        margin-left: -@margin-default;
        margin-right: -@margin-default;
        padding: @margin-default;
        p {
          text-align: center;
          margin: 0;
          .font-serif-italic(@font-size-large);
        }
      }
    }
  }

  &.no-close .close-btn{
    display: none;
  }
}
