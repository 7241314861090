.search-view {
  .search {
    .criteria {
      border-bottom: solid 1px #000;
    }
  }
  margin-top:7px;
  .brands-found {
    &.invisible {
      visibility: hidden;
    }
    margin-top:0;
    .make-responsive-height(90px, 90px, 90px, 60px);
    .make-responsive-font-size(20px, 20px, 18px, 15px);
    h4 {
      .make-responsive-font-size(11px, 11px, 11px, 9px);
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 5px;
      color: #999;
    }
    a {
      display:inline-block;
      margin-left: 15px;
      white-space: nowrap;
      &:first-of-type {
        margin-left:0;
      }
    }
    text-align:center;
  }
  .items-found {
    &.invisible {
      visibility: hidden;
    }
    margin-top:0;
    .make-responsive-font-size(12px, 12px, 12px, 10px) !important;
    text-align:center;
  }
  .drop-down-filter {
    .filter-btn {
      left:30px;
      // border: solid #eee 1px;
    }
  }
  .search-result {
    padding: 0;
    margin-top:22px;
    margin-right: 15px ;
    margin-left: 15px ;

    .filter-panel {
      .make-responsive-columns(3,3,4,0);
      top:15px;
      z-index:3;
      .sidebar-rail {
        .top-offset {
          height:115px;
        }
        .bottom-offset {
          height:450px;
        }
      }
      .filter-container {
        .make-responsive-width(240px, 230px, 230px, 0);
        .hidden-xs;
        z-index: 3;
        border: 1px solid @brand-gray-light;
        background-color: #fff;
        opacity: .8;
      }
      .category-tree {
        width:100%;
        padding: 0 @grid-gutter-width/2;
      }
    }
    .product-panel {
      .product-list {
        margin-left: 0!important;
        margin-right: 0!important;
        .product-list();
        .product-list-item .product-image {
          .make-responsive-height(414px,339px,350px,268px);
        }
      }
      .product-item-container {
        .make-responsive-columns(3, 3, 6, 6);
      }
      .product-pagination {
        clear: both;
        float: right;
      }
      .clipboard{
        position: fixed;
        font-size: 30px;
        bottom: 15px;
        left: 100px;
        z-index:999;
        .delete {
          color: red;
          font-weight: bolder;
          cursor: pointer;
          margin-left:@margin-default*2;
        }
      }
      .clipboard-ux {
        display:block;
        position: fixed;
        right: 5px;
        bottom:5px;
        button {
          padding: 6px;
          z-index: 15;
        }
      }
    }
  }

  .no-results-message {
    .font-serif(24px);
    line-height: 23px;
    .make-responsive-margin(200px; 150px; 100px; 50px);
    text-align: center;
  }

  .sort-selector-container {
    .hidden-xs;
    position:relative;
    .product-sort-selector {
      position:absolute;
      top:-38px;
      right:2px;
      z-index:2;
    }
  }
  .no-results {
    visibility:hidden;
  }
  .number-of-products {
    color: #4a4a4a;
    margin-left: 30px;

    h1 {
      text-transform: uppercase;
      display: inline-block;
      .make-responsive-font-size(@font-size-x-big-large, @font-size-x-big-large, @font-size-x-big-large, @font-size-large-medium);
      .font-serif;

      margin-top: 0;
      margin-bottom: 0;
      margin-right: 20px;
    }

    font-size: @font-size-small;
    .font-sans-serif;
    @media (max-width: @screen-xs-max) {
      h1 {
        float: left;
      }

      text-align: right;
      padding-right: 10px;
      width: 90%;
    }
  }
  .drop-down-filter .filter-btn {
    padding: 0;
  }
}
.search-preview-mobile.drop-down-filter {
  .criteria {
    input {
      border-bottom: 2px solid black;
      border-radius: 0px;
      background: transparent;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      margin-left: -30px;
      margin-top: 10px;
      width: 103%;
    }
  }
   .drop-down-content{
     overflow-y: hidden;
     .filter-footer {
       background-color: #fff;
       height: 75px;
       margin: 0;
       a {
         background-color: transparent;
         border: 1px solid black;
         margin-bottom: 20px;
         padding: 15px;
       }
     }
     .close-btn {
       position: absolute;
       right: 0;
       .make-responsive-top(-4px,-4px,9px,-4px);
       margin: 0 0 0 0;
       background-color: transparent;
       .flaticon-solid-x;
       color: black;
       border: none;
       font-size: 16px;
       &:hover {
         opacity: 0.5;
         background-color: transparent;
       }
     }
     .panel-heading {
       border-bottom: 1px solid lightgrey;
     }
     .no-results {
       border-bottom: 1px solid lightgrey;
       padding-bottom: 5px;
     }
     .accordion-group{
       background-color: @color-peach;
     }
     .results-wrapper {
       height: calc(100% - 75px);
       overflow-y: auto;
     }
     .filters{
        background-color: transparent;
        height: calc(100% - 75px);
        margin: 0 auto;
        &.with-results {
          background-color: #fff;
          overflow-x: hidden;
          overflow-y: hidden;
        }
        .panel-heading {
          a {
            text-transform: uppercase;
            font-weight: bold;
            color: black !important;
            &:focus, &:active {
                color: black;
            }
            span {
              float: right;
              margin-left: 5px;
              margin-bottom: 5px;
            }
          }
        }
     }
     .view-more {
       padding-top: 35px;
     }
     .results {
       list-style: none;
       padding: 5px;
       li {
         height: 35px;
         line-height: 35px;
         &:last-child {
          padding-bottom: 25px;
         }
       }
       .name {
         width: 83%;
         text-align: left;
         float: left;
         display: inline-block;
         white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
       }
       .price {
         width: 15%;
         float: right;
         text-align: right;
         display: inline-block;
       }
     }

  }
}
.search-preview {
  top: 143px!important;
  width: 100%;
  left: 0;
  margin: 0!important;
  height: auto;
  // width: 970px;
  position: absolute;
  top: @header-height - 80px;
  // .make-responsive-left(17%, 5%, 5%, 5%);
  z-index: 30;
  background-color: @brand-main-color-light;
  padding: 35px 20px 20px 20px;
  box-shadow: 0px 5px 4px 1px #00000036;
  .product-list();
  .product-info {
    margin-bottom: 20px;
  }
  .less {
    &.results {
      width: 80%;
      display: inline-block;
      float: right;
      background-color: transparent;
      .product-list-item {
        .make-responsive-width(20%,20%,20%,20%);
      }
    }
  }
  .product-list-items {
    padding-left: 20px;
    background-color: white;
    padding-right: 20px;
    height: auto;
    .wishlist {
      right: 0;
      top: -0.6rem;
    }
  }
  .product-list-item {
    background-color: transparent;
    .make-responsive-columns(2, 2, 2, 2);
    .product-image {
      height: 303px;
    }
    .more-colors {
      display: none;
    }
    .low-in-stock {
      display: none;
    }
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  .search-wrapper {
    // background-color: rgba(255, 255, 255, 0.3);
  }
  .keywords {
    display: inline-block;
    width: 20%;
    float: left;
    .font-serif;
    text-transform: uppercase;
    .font-serif-bold;
    padding-top: 20px;
    div {
      padding-bottom: 20px;
    }
    ul {
      li {
        list-style: none;
        padding-bottom: 10px;
        a {
          font-size: @font-size-base;
          font-weight: normal;
        }
      }
    }
  }
  .view-more {
    display: inline-block;
    float: right;
    padding: 20px;

    text-transform: uppercase;
    .font-serif;
    .glyphicon-chevron-right {
      font-size: 10px;
    }
  }
  .results-text{
    display:inline-block;
    text-transform: uppercase;
    padding: 20px;
    .font-serif;
    span {
      color: @brand-main-color;
      a {
        color: @brand-main-color;
      }
    }
  }
}
.nav{
  .search {
    border-bottom: solid 1px @brand-gray-dark;
    width: 270px;
    margin-left: -20px;
    text-indent: 10px;
    .criteria {
      width: 255px;
      .search-term {
        text-align: left;
        background-color: transparent!important;
        .font-sans-serif();
        font-size: @font-size-small !important;
        border-radius:0;
        .make-responsive-margin-top(0,0,60px,60px) !important;
        .make-responsive-border-bottom-width(0,0,0,1px);
        .make-responsive-font-size(16px, 16px, 14px, 14px) !important;
        font-weight: 500;
      }
      :-ms-input-placeholder, ::-webkit-input-placeholder, input::placeholder, .placeholder {
        color: #ddd!important;
      }
  }
  }
}

.mobile{
  .search{
    border-bottom: solid 1px #000;
    height: 50px;
    border-bottom: solid 2px;
    padding: 0px;
    margin: auto;
    width: 90%;
  }
}
