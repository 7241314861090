.product-sort-selector-mobile {
  .panel-group {
    border-bottom: 1px solid @brand-gray;
  }
  .font-family-sans-serif(@font-size-medium);
  h4 {
    .font-family-sans-serif(@font-size-medium);
    color: #333;
    text-transform: uppercase;
    padding: 15px 0;
    margin: 0px;
  }
  div:hover {
    color: #333;
  }
  span {
    color: #333;
  }
  ul {
    list-style: none;
    margin-left: @margin-default;
    li {
      margin:0;
      padding-top: @margin-default/2;
      padding-bottom: @margin-default/2;
    }
  }
  .lvl0-icon, .criteria {
    position: relative;
    display: inline-block;
    float: right;
  }
}
