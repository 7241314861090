.admin {
  .navbar.navbar-default {
    background-color: #fff;
    border-color: transparent;
    border-bottom: 1px solid #f5f5f5;
    min-height: 0;
    margin-bottom: @margin-default;

    .navbar-header {
      float: left;
      margin: @margin-default @margin-default*2 @margin-default 0;
      img {
        width: 110px;
      }
    }

    // all links
    .navbar-nav {
      li a {
        .font-sans-serif(@font-size-small);
        color:#000;
      }
      // first level
      > li > a {
        padding-top: 22px;
        padding-bottom: 22px;
      }
    }
  }

  .fixed-nav {
    position:fixed;
    z-index: 5;
    background-color: #fff;
  }
}
