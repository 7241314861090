.lowercase {
  text-transform: lowercase;
}

.admin-promos, .admin-promo {

  select {
    .font-family-sans-serif(@font-size-small);
    line-height: 1em;
    border: 1px solid #e6e6e6;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 15px 13px 15px 20px;
    width: 100%;
  }

  .alert {
    .font-sans-serif(@font-size-small);
    padding: @margin-default/2 @margin-default;
  }

  .promo-remove {
    .font-sans-serif(@font-size-small);
    line-height: 20px;
    padding: 0 15px;
    background-color: red;
    color: white;
  }

  .om-input-category {
    .font-sans-serif();

    border: 1px #ddd solid;
    border-radius: 4px;
    padding: 15px;
    margin: 10px 0;

    .input-category-label, .input-group-label {
      text-transform: uppercase;
      font-size: 15px;
      color: black;
    }

    .om-input-group {
      padding: 15px;

      .input-group-label {
        color: #959595;
      }
    }
  }

  .restrictions {
    .font-sans-serif(@font-size-small);

    .restriction {
      padding-left: 20px;
      margin: 15px 0;
      vertical-align: middle;
      input[type=checkbox] {
        float: left;
        margin: 5px 0 0 -20px;
        vertical-align: middle;
      }
      label {
        margin: 0;
      }
      p.note {
        .font-sans-serif(@font-size-small);
        margin: 0;
        color: #666;
        input[type=number] {
          width: 100px;
          margin-left: 5px;
          margin-right: 5px;
          padding: 5px 10px;
        }
        input[type=text] {
          width: 200px;
          margin-left: 5px;
          margin-right: 5px;
          padding: 5px 10px;
        }
      }
    }
  }

}
