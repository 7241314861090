.block-article-container {

  &.desktop {
    .make-responsive-display(block, none, none, none);
  }

  &.mobile {
    .make-responsive-display(none, block, block, block);
  }

  &.background-image {
    margin: 0;
    padding: 0;
    background-repeat: no-repeat;

    & > div {
      background: transparent;
      .make-responsive-padding(60px, 30px, 30px, 15px);

      h1 {
        margin: 10px 0;
      }
    }
  }
}

.overlapped-article {
  margin: -15px 0 0 0;
}

.block-article {
  background-color: white;
  .make-responsive-padding(60px, 30px, 30px, 15px);

  &.overlapped {
    .overlapped-article;
  }

  h2 {
    margin-top: 0;
  }
}

.block-article-container {
  & > div {
    background-color: white;
    .make-responsive-padding(60px, 30px, 30px, 15px);
  }

  &.overlapped {
    & > div {
      .overlapped-article;
    }
  }
  .hero-image-panel {
    .make-hero-image-panel;
    background-size: cover;
    text-align: center;
    h1 {
      .make-responsive-margin-top(65px, 95px, 95px, 120px);
      &.overlay{
        background-color: rgba(255,255,255,0.6);
        display: inline-block;
        padding: 15px;
      }
    }
  }
  h1 {
    margin-top: 0;
  }
}
