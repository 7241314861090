.checkout-base-view {
  .homepage-carousel {
    margin-top: 50px;
  }

  #loading-spinner {
    width: 50px;
    height: 10px;
    overflow: hidden;
    background-color: @brand-main-color;
    margin: auto;
  }

  .loading-spinner {
    background-color: white;
    margin-top: 0;
    margin-left: -50px;
    width: 50px;
    height: 10px;
    animation-name: bounce_loadingProgressG;
    -o-animation-name: bounce_loadingProgressG;
    -ms-animation-name: bounce_loadingProgressG;
    -webkit-animation-name: bounce_loadingProgressG;
    -moz-animation-name: bounce_loadingProgressG;
    animation-duration: 1.5s;
    -o-animation-duration: 1.5s;
    -ms-animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
  }

  @keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-o-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-ms-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-webkit-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-moz-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  .confirmation {
    width: 100%;
    margin-left:auto;
    margin-right:auto;
    text-align: center;
    margin-bottom: 10%;

    .confirmation-box {
      border: 1px solid #f1f1f1;
      margin-top: 57px;
      @media (max-width: @screen-xs-max) {
       margin-top: 0px;
      }
      margin-bottom: 30px;
      @media (max-width: @screen-sm-max)  {
        border: 0;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    h1 {
      .make-responsive-font-size(30px; 30px; 26px; 23px);
    }
    p {
      .font-size(14px, 14px, 16px, 16px);
    }
    .order-number {
      margin-bottom: @grid-gutter-width/2;
    }

    .friend-referer {
      opacity: 1;
      .translate3d(0, 0, 0);
      .transition(all 0.8s);
      &.disappear {
        opacity: 0;
        .translate3d(0, 20px, 0);
        .transition(none);
      }
    }

    .create-account {
      p {
        font-size: 16px;
      }
      .ng-submitted, .submitted, .visited {
        input.ng-invalid {
          border: 1px solid @error-color;
          .make-responsive-padding-top(12px;12px; 9px; 9px);
          .make-responsive-padding-bottom(12px;12px; 8px; 8px);
        }
      }
      .ng-submitted {
        .error-summary {
          &.has-errors {
            display: inline-block;
          }
        }
      }
      .form-failure {
        .form-failure-message {
          display: block;
        }
      }
      .form-success-message, .form-failure-message{
        display: none;
      }
      .error-summary {
        float: left;
        overflow: auto;
        display: none;
      }
      header {
        h1 {
          @media (max-width: @screen-xs-max) {
            font-size: 18px;
          }
        }
        p {
          @media (max-width: @screen-xs-max) {
            font-size: @font-size-base;
          }
        }
      }
      .form-inputs {
        .make-responsive-width(55%,55%, 55%,100%);
        margin-left: auto;
        margin-right: auto;
        overflow: auto;
        input {
          float: left;
          width: 60%;
          .make-responsive-padding-top(11px;11px; 9px; 9px);
          .make-responsive-padding-bottom(11px;11px; 8px; 8px);
          padding-left: 10px;
          .font-sans-serif(@font-size-small);
          @media (max-width: @screen-xs-max) {
           width: 60%;
          }
        }
        button {
          float: left;
          width: 40%;
          .make-responsive-padding-top(12px,12px,10px,10px);
          .make-responsive-padding-bottom(12px,12px,10px,10px);
          @media (max-width: @screen-xs-max) {
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 0px;
            min-width: auto;
            width: 40%;
            padding-bottom: 8px;
            padding-top: 8px;
          }
        }
      }
    }
  }
}
