.validate-email {

  .message {
    a {
      font-weight: 800;
      text-decoration: underline;
    }
    &.failure {
      background-color: @error-color-light;
      border: 1px solid @error-color;
      .font-sans-serif(@font-size-small);
      text-align: center;
      .margin-top-medium;
      .make-responsive-padding(22px, 22px, 15px, 15px);
      word-break: break-all;
    }
  }
}