.sale-filter {
  @media (max-width: @screen-xs-max) {
    border-top: 1px solid @brand-gray;
  }
  @media (min-width: @screen-sm-min) {
    border-top: 1px solid #f1f1f1;
  }
  margin-left: @margin-default;
  margin-right: @margin-default;
  .panel-title {
    @media (max-width: @screen-xs-max) {
      padding-top: 15px;
    }
    @media (min-width: @screen-sm-min) {
      padding-top: 8px;
    }
    .font-sans-serif(@font-size-small);
    height:45px;
    label {
      cursor: pointer;
      display: inline-block;
      width: 100%;
      @media (max-width: @screen-xs-max) {
        .text {
          .font-family-sans-serif(@font-size-medium);
          color: #333;
          text-transform: uppercase;
          margin:0;
        }
        i {
          .font-family-sans-serif(@font-size-medium);
          float:right;
          color: #333;
        }
      }
      @media (min-width: @screen-sm-min) {
        .text {
          color: #000;
          font-weight: 400;
          margin-right:8px;
        }
        &:hover{
          .text {
            color: @brand-main-color;
          }
        }
        &.sale-on {
          .text {
            color:@brand-main-color;
            font-weight:700;
          }
        }
      }
      input {
        display:none;
      }
    }
  }
}
