.checkout-v2-view {
  .shipping-options {
    margin-top: 30px;
    margin-left: 15px;
    p {
      font-size: @font-size-small;
    }
    .subscribe-optin {
      label{
          span {
            .font-sans-serif(@font-size-small);
          }
      }
    }
   .fulfiller {
      margin-top: 15px;
      margin-bottom: 30px;

      .orderProcessing {
        color: #999;
      }
      .preOrderProcessing{
        color: #999;
        margin-bottom: 0px;
      }
      .international-shipping {
        font-size: @font-size-small;
      }

      .shipping-from {
        .font-serif-italic(18px);
      }

      .shipping-methods {
        margin-top: 15px;
        margin-left: 30px;
        ul {
          list-style: none;
          li {
            label {
              cursor: pointer;
            }
            .shipping-method-message {
              .font-serif-italic(12px);
              margin-left: @margin-default*2;
            }
          }
        }
      }

      .fulfiller-items {
        .make-row();
        margin-top: 15px;

        .fulfiller-item {
          .make-responsive-columns(4, 4, 4, 6);

          .image-placeholder {
            width: 30%;
            float: left;
            img {
              border: 1px solid #f5f5f5;
              max-width: 100%;
            }
          }

          .product-data {
            width: 70%;
            float: left;
            padding-left: 15px;
            .font-sans-serif(@font-size-small);
            overflow: hidden;
            .brand {
              .font-sans-serif-bold(@font-size-small);
            }
            .name {
              .text-truncate-lines(3);
            }
            .order-from {
              color: #999;
              font-size: 10px;
              font-family: 'OMBR',Arial,sans-serif;
              font-weight: 400;
              font-style: normal;
              letter-spacing: -1px;
            }
            a {
              text-decoration: underline;
            }
            p {
              margin: 5px 0px 0px 0px;
            }
          }
        }
      }
    }
  }
}
