.admin {
  .order-events, .admin-return {
    @media (min-width: 1281px) {
      margin: @margin-default -40px;
    }
    .order-details {
      li {
        margin-left: 20px;
      }
    }
    header {
      h2 {
        text-align: center;
      }
    }
    .admin-form {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .actions {
      margin: @margin-default 0;
      .main-actions {
        float: left;
      }
      button {
        padding: 3px 10px;
        margin-right: 5px;
      }
    }
    ul.events {
      li {
        list-style: none;
        border-bottom: 1px solid #eee;
        .font-sans-serif(@font-size-small);
        .date {
          font-size: @font-size-small;
        }
        h4 {
          font-size: @font-size-medium;
        }
        a {
          text-decoration: underline;
        }
      }
    }

    table {
      width: 100%;
      .font-sans-serif(@font-size-small);
      th {
        background-color: #f2f2f2;
        padding: @margin-default / 2;
      }
      td {
        border-bottom: 1px solid #ddd;
        width: 158px;
        padding: @margin-default / 2;
        white-space: normal;
        text-transform: capitalize;
        &.no-border {
          border-color: transparent;
        }
        .order-details {
          line-height: @font-size-base;
        }
        a {
          text-decoration: underline;
        }
      }
    }

    table.details {
      width: 100%;
      .font-sans-serif(@font-size-small);
      th {
        padding-top: 0;
        padding-bottom: 0;
      }
      td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .order-event {
      h4 {
        .font-sans-serif(@font-size-medium);
      }
      p {
        .font-sans-serif(@font-size-small);
      }
      table.small {
        .font-sans-serif(@font-size-small);
        width: 300px;
        tr.number td:last-child {
          text-align: right;
        }
        tr.total td {
          font-weight: bold;
        }
        td {
          border-bottom: 0;
          padding: 0;
        }
      }
    }
  }
}

.event-details {
  pre {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: scroll;
    font-size: @font-size-small;
  }
}
