.press{
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  article{
    width: 30%;
    @media (max-width: @screen-sm-max) {
      width: 100%;
    }
    float: left;
    padding: 0;
    margin: 0px 15px 40px 15px;
    blockquote{
      border-left: none;
      margin: 0px;
      padding: 0px;
      font-size: @font-size-medium-large;
    }
    header{
      .font-serif-italic;
      width: 100%;
      min-height: 55px;
      border-bottom: solid 2px #cccccc;
      margin: 0;
      padding: 0;
      .helper{
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        height: 55px;
      }
      img{
        max-height: 55px;
      }
    }
    section{
      text-align: left;
      width: 100%;
      p{
        margin-bottom: 3px;
        font-size: @font-size-large;
      }
      img{
        width: 100%;
      }
      .link{
        color: black;
        .font-sans-serif-bold(@font-size-medium-large);
        margin-top: 20px;
        line-height: 50px;
        .arrow-right{
          .flaticon-solid-right-2;
          &::before{
            position: absolute;
            line-height: 55px;
            font-size: @font-size-xx-small;
            margin-left: 5px;
          }
        }
      }
      .quote{
        width: 38px;
        height: 27px;
        margin-bottom: 12.5px;
        margin-top: 24px;
      }
    }
  }
}
