@cart-placeholder-image-width-lg: 130px;
@cart-placeholder-image-height-lg: 195px;

@cart-placeholder-image-width-md: 130px;
@cart-placeholder-image-height-md: 195px;

@cart-placeholder-image-width-sm: 130px;
@cart-placeholder-image-height-sm: 195px;

@cart-placeholder-image-width-xs: 78px;
@cart-placeholder-image-height-xs: 117px;
.cart {
  height: 100%;
  line-height: 1.1em;

  .sr-banner {
    position: relative;
    float: left;
    width: 100%;
    padding-top: 0 !important;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
  }

  .modal-header {
    padding: 0;
    margin: 46px 10px 0 20px;
    .make-responsive-margin-top(40px, 40px, 40px, 30px);
    .make-responsive-height(122px, 122px, 122px, 80px);
    overflow: hidden;

    .continue {
      display: none;
    }

    @media screen and (max-width: 768px) {
      .continue {
        position: absolute;
        font-size: 1rem;
        font-family: sans-serif;
        color: @brand-main-color;
        margin-bottom: 10px;
        text-decoration: underline;
        top: 15px;
        z-index: 222222;
        padding-left: 15px;
        text-align: left;
        display: block;
        cursor: pointer;

        span {
          padding-right: 5px;
        }
      }
    }
  }

  .modal-footer {
    .switch-bag {
      cursor: pointer;

      :hover {
        color: @brand-main-color;
      }

      div:first-child h5 {
        text-decoration: underline;
      }
    }

    .footer-empty {
      margin-bottom: 8px;
      .hidden-xs;
    }

    position: relative;
    z-index: 100;
    margin: 0;
    padding: 0 15px 15px 15px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    .make-responsive-width(500px, 500px, 500px, 100%);
    .font-family-responsive-serif(@font-size-medium, @font-size-medium, @font-size-medium, @font-size-base);

    .pay-with-buttons {
      display: inline-block;
      width: 100%;
    }

    .subtotal {
      .make-responsive-columns(12, 12, 12, 12);
      .make-responsive-margin-bottom(10px, 10px, 10px, 0);
      .make-responsive-margin-top(10px, 10px, 10px, 0);
      margin: 10px 0;
      text-align: center;

      .price {
        display: inline;
      }

      .discount {
        color: @brand-main-color;
      }
    }

    hr {
      width: 100%;
    }

    .checkout-copy {
      .make-responsive-columns(12, 12, 12, 12);
      text-align: center;

    }

    .btn-checkout {
      .make-responsive-columns(12, 12, 12, 12);
      margin: 15px 0;
    }
  }

  .item-list {
    position: relative;
    list-style-type: none;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 99;
    right: 0;
    top: 20px;
    position: relative;
    .make-responsive-margin-left(30px, 30px, 30px, 15px);
    .make-responsive-margin-right(30px, 30px, 30px, 15px);
    .make-responsive-width(440px, 440px, 440px, 100%);
    @media screen and(max-width: 768px) {
      padding-left: 30px;
    }

    .item {
      .make-row();
      background: white;
      height: auto;

      padding-top: 0;
      margin: 0;
      .make-responsive-padding-bottom(5px, 5px, 5px, 15px);

      .image-column {
        .make-responsive-columns(4, 4, 4, 4);
        padding: 0 !important;
      }

      .info-column {
        .make-responsive-columns(8, 8, 8, 8);
        text-transform: uppercase;
        line-height: 1;
        .make-responsive-height(@cart-placeholder-image-height-lg, @cart-placeholder-image-height-md, @cart-placeholder-image-height-sm, 100%);
      }

      .top-sold-out-warning {
        .make-responsive-columns(12, 12, 12, 12);
        text-align: center;
        border: solid 1px #f1f1f1;
        padding: 15px;
        margin-bottom: 20px;

        a {
          .font-sans-serif(@font-size-x-small);
          color: @error-color;
        }
      }

      &.sold-out-message-row {
        height: auto;
      }

      .sold-out-warning {
        color: @error-color;
        .font-sans-serif(@font-size-x-small);
        margin-bottom: 6px;
      }

      &.sold-out {
        .price {
          color: @error-color;
          text-decoration: line-through;
        }

        .quantity {
          color: @error-color;
          text-decoration: line-through;

          select {
            display: none;
          }

          .number {
            display: inline-block;
            text-decoration: line-through;
          }
        }

        .sold-out-warning {
          .small {
            display: none;
          }

          @media (max-width: @screen-sm-max) {
            span {
              display: none;

              &.small {
                display: block;
              }
            }
          }
        }
      }

      .brand {
        .font-family-sans-serif(@font-size-small);
        margin: 0;
        padding: 0;
      }

      .final-sale, .pre-order {
        .font-family-sans-serif(@font-size-x-small);
        .make-responsive-margin-top(-15px, -15px, -15px, -10px);
        .make-responsive-margin-bottom(15px, 15px, 15px, 10px);
        color: @tertiary-color;
      }

      .ships-international {
        .font-family-sans-serif(@font-size-x-small);
        .make-responsive-margin-top(-15px, -15px, -15px, -10px);
        .make-responsive-margin-bottom(15px, 15px, 15px, 10px);
        text-transform: none;
      }

      .name {
        .font-family-sans-serif-light(@font-size-x-small);
        .make-responsive-line-height(17px, 17px, 17px, 15px);
        color: #4c4c4c;
        .text-truncate-lines(2);
        margin: 0;
        .make-responsive-margin-top(5px, 5px, 5px, 10px);
      }

      .price {
        .font-family-serif(@font-size-small);
        color: #4c4c4c;
        margin: 0;
        .make-responsive-margin-top(15px, 15px, 15px, 10px);
        .make-responsive-margin-bottom(15px, 15px, 15px, 10px);
      }

      .size {
        .font-family-sans-serif-bold(@font-size-x-small);
        padding: 0;

      }

      .quantity, .gift-card-data, .max-qty {
        .font-family-sans-serif-bold(@font-size-x-small);
        margin: 0;
        .make-responsive-margin-top(5px, 5px, 5px, 3px);

        .number {
          display: none;
        }

        select {
          .make-select;
          margin-left: 6px;
        }
      }

      .max-qty {
        color: @tertiary-color;
      }

      .color {
        .font-family-sans-serif-bold(@font-size-x-small);
        .make-responsive-margin-top(5px, 5px, 5px, 3px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .remove {
        position: relative;
        bottom: -3px;
        line-height: 1;

        button {
          .font-sans-serif(@font-size-x-small);
          color: #999999;
          text-decoration: underline;
          background-color: transparent;
          border: 0;
          padding: 0;
          margin: 0;
        }

        @media (max-width: @screen-xs-max) {
          position: static;
          margin-top: 3px;
          button {
            padding-top: 7px;
          }
        }
      }

      .image-placeholder {
        background: white;
        cursor: pointer;
        .make-responsive-height(@cart-placeholder-image-height-lg; @cart-placeholder-image-height-md; @cart-placeholder-image-height-sm; @cart-placeholder-image-height-xs);
        .make-responsive-width(@cart-placeholder-image-width-lg; @cart-placeholder-image-width-md; @cart-placeholder-image-width-sm; @cart-placeholder-image-width-xs);

        border: 1px solid #f5f5f5;
        margin: 0;
        text-align: center;
        position: relative;
        vertical-align: middle;

        img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      position: relative;
      transition: transform 0.2s ease-out;
      -webkit-transition: -webkit-transform 0.2s ease-out;

      &.just-added {
        transform: translateX(-40px);
        transition: transform 0.1s;
        -webkit-transform: translateX(-40px);
        -webkit-transition: -webkit-transform 0.1s;
      }
    }
  }

  &.cart-is-empty {
    .cart-is-empty-message {
      text-align: center;
      padding: 80px 40px 150px 40px;
    }

    .item-list {
      .make-responsive-bottom(70px, 70px, 68px, 60px);
    }

    .modal-footer {
      height: auto;

      .footer-empty {
        margin-bottom: 8px;
        padding-top: 20px;
      }
    }
  }

  &.footer-small {
    .modal-footer {
      height: 75px;
      padding: 10px 0;
    }

    .item-list {
      bottom: 60px;
    }
  }

  &.footer-medium {
    .modal-footer {
      &.active {
        padding-bottom: 100px;
      }
    }
  }

  #payment-request-button {
    margin-bottom: 12px;
    display: inline-block;
    width: 100%;
  }

  .loyalty-message-guest {
    img {
      position: relative;
      float: left;
      padding-top: 7px;
      width: 45px;
    }

    p {
      position: relative;
      float: right;
      width: calc(100% - 45px);
      padding-left: 5px;
      text-align: left;
    }
  }

  .loyalty-message-registered {
    img {
      padding-right: 3px;
      width: 45px;
    }
  }
}
.loyalty-message {
  position: relative;
  float: left;
  z-index: 2;
}
.modal-cart .cart {
  .gift-card-promo-links {
    display: none;
  }
}
form.cart-promo{
  .gift-card-promo-code {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 1200px) {
      .gift-card-container, .promo-code-container {
          width: 100%;
          .promo-code-input-panel {
            width: inherit;
        }
      }
    }
    .promo-code-container {
      align-items: center;
      display: flex;
      justify-content: center;
      .promo-code-input-panel {
        .form-group {
          display: flex;
          width: 100%;
          input#promoCode {
            height: 46px;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      input {
        padding: 10px 13px 10px 20px;
        height: 46px;
        margin-bottom: 22px;
        &::placeholder {
          font-size: 12px;
        }
      }
      button {
        position: relative;
        margin-bottom: 18px;
        min-width: 67px;
        height: 47px;
        top: 0;
      }
    }
  }
}
.promo-code-input-panel .promo-code-applied-message {
  min-width: 110px;
  marging: 0;
  .form-failure-message {
    padding: 9px;
    margin-top: 0!important;
    line-height: 1;
  }
}
