.ui-view-container {
  min-height: 500px;
  position: relative;
  z-index: 2;
}
.ui-view-container {
  z-index: 3!important;
  top: -49px;
  &:has(> .product-details-view) {top: 0;}
  &:has(> .the-mile-dashboard) {top: 0;}
  &:has(> .influencer-page) {top: 0;}
  &:has(> .checkout-base-view) {top: 0;}
  &:has(> .search-view) {top: 0;}
  &:has(> .loyalty-view) {top: -133px;}
  @media (max-width: @screen-xs-max) {top: 0;}
  .brands {
    margin-right: 30px;
    margin-left: 30px;
    .brands--filter {
      display: block!important;
      margin-left: 30px;
      @media (max-width:768px) {
        display: none;
      }
      .sidebar-rail {
        left: 0!important;
        position: relative!important;
        .top-gap, .top-offset{
          display: none;
        }
      }
    }
  }
}
.header-the-mile .sticky-header-shadow,
.header-the-mile .sticky-header-replacement {
  display: none !important;
}

.header-the-mile {
  height: 111px;
}

@media only screen and (max-width: 480px){
  .header-the-mile {
    height: 90px;
  }
}

.kr {
  @font-family-alt: 'KR', Arial, sans-serif;
  body, h1, h2, h3, h4, h5, h6, p, div, em {
    font-family: 'KR', Arial, sans-serif!important;
  }
}
