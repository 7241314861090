.collapsible {
  width: 100%;
  .panel {
    border-top: #b8b8b8 solid 1px;
    letter-spacing: 0.1pt;
    line-height: 30px;
    min-height: 50px;
    &:last-child {
      border-bottom: #b8b8b8 solid 1px;
    }
    .panel-heading {
      a {
        cursor: pointer;
        .panel-title {
          text-align: left;
          .font-serif-bold(@font-size-large-medium);
          .margin-top-bottom-medium;
          .plus {
            .pull-right();
            .flaticon-solid-minus;
          }
        }
        &.collapsed {
          .panel-title {
            .font-serif-bold(@font-size-large-medium);
            .plus {
              .flaticon-solid-plus;
            }
          }
        }
      }
    }
  }
}
