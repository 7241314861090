
img[lazy-src] {
  opacity:0;
  -webkit-transition: opacity .7s;
  transition: opacity .7s;
}
img.ready[lazy-src][src] {
  opacity:1;
}

img[image-zoom] {
  cursor: zoom-in;
}
