.discount-filter {
  @media (max-width: @screen-xs-max) {
    .font-family-sans-serif(@font-size-medium);
    color: #333;
    border-bottom: 1px solid @brand-gray;
    margin-left:auto;
    margin-right:auto;
    .panel-title {
      .font-sans-serif(@font-size-medium);
      text-transform: uppercase;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .discount-list {
      list-style: none;
      li {
        margin-left:@margin-default;
        margin-bottom: 5px;
        cursor: pointer;
        &.active {
          font-weight:bolder;
          cursor: default;
        }
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .font-sans-serif(@font-size-small);
    border-top: 1px solid #f1f1f1;
    margin-left:@margin-default;
    margin-right:@margin-default;
    .panel-title {
      .font-sans-serif(@font-size-small);
      height:35px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .discount-list {
      list-style: none;
      li {
        margin-left:@margin-default;
        cursor: pointer;
        &.active {
          font-weight:bolder;
          cursor: default;
        }
      }
    }
  }
}
