.header-message-container {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 27;
  height:@header-message-height;
}
.header-message {
  text-align: center;
  vertical-align: middle;
  background: black;
  color: white;
  p {
    display: inline-block;
    padding: 6px;
    margin: 0;
    vertical-align: middle;
    .font-serif-italic(@font-size-small);
    .make-responsive-font-size(@font-size-small,@font-size-small,@font-size-small,@font-size-x-small);
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}
