.giftcard-view {
  display: flex;
  flex-direction: row;
  @media (max-width:768px) {
    flex-direction: column; 
  }  
  .product-price {
    display: none;
  }
  .main-info {
    .brand {
      display: none;
    }
    h1.name {
      .make-responsive-font-size(30px, 30px, 30px, 30px);
    }
  }
  .panel-group{
    border-top: solid 1px #eeeeee;
    h2.panel-title{
      .font-serif-bold;
      .make-responsive-font-size(14px, 14px, 14px, 14px);
    }
    .panel-body, p {
      .make-responsive-font-size(14px, 14px, 14px, 14px);
    }
    .plus {
      .flaticon-solid-plus;
      float: right;
    }
    .collapsed{
      .plus {
        .flaticon-solid-minus;
      }
    }
  }
  .general-form {
    max-width: 100%;
    textarea {
      min-height: 100px;
    }
    button {
      width: 100%;
    }
    .required-indicator{
      color: #9b9b9b;
      .font-sans-serif;
      .margin-top-small;
      .make-responsive-font-size(14px, 14px, 14px, 14px);
    }
  }
  & >.product-details-image-container {
    align-self: flex-start;
    justify-content: center;
    img {
      width: 500px;
      padding: 30px;
    }
  }
  .product-details-info-container {
    padding: 0 30px;
  }
}
