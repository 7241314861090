.iframe-mode {
  .sticky-header-replacement{
    display: none;
  }
  header {
    display: none;
  }
  .home-banner {
    display: none;
  }
  footer {
    display: none;
  }
  .product-details-view {
    .more-products {
      display: none;
    }
    .product-details-info-container {
      .buttons {
        .btn-add-to-cart {
          display:none;
        }
        .btn-shop-now{
          display:block;
        }
      }
      .help {
        display: none;
      }
    }
    .tabs {
      padding-bottom: 30px;
    }
  }
  .subscription-newsletter{
    display: none;
  }
  .shopping-bag-icon {
    display: inline;
    @media (max-width: @screen-sm-max) {
      display: inline-block;
    }
    position: relative;
    .number {
      width: 16px;
      height: 16px;
      background-color: @brand-main-color;
      border-radius: 16px;
      text-align: center;
      .font-sans-serif(@font-size-small);
      color: #ffffff;
      line-height: 16px;
      display: inline-block;
      position: absolute;
      top: -4px;
      .make-responsive-top(-4px, -4px, 15px, 7px);
      left: 15px;
    }

    .icon {
      .flaticon-solid-cart;
      &:after {
        font-size: 20px;
      }
      display: inline-block;
    }
  }
}
.sr-banner {
  padding-top: 18px!important;
  padding-left: 5px!important;
  z-index: 1;
}