.invite-redeem-view {
  .container();
  header {
    margin-top: 50px;
    text-align: center;
    p {
      .make-responsive-width(50%, 50%, 70%, 80%);
      margin: 0 auto;
      .font-family-sans-serif-light(@font-size-small);
    }
  }
  form {
    .margin-top-medium;
    .margin-bottom-large;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    .form-group {
      .make-responsive-columns(12, 12, 12, 12);
      &.half {
        .make-responsive-columns(6, 6, 6, 12);
      }
    }
    .messages {
      .make-responsive-columns-offset(2, 2, 1, 0);
      .make-responsive-columns(8, 8, 10, 12);
    }
    .buttons {
      .make-responsive-columns-offset(2, 2, 1, 0);
      .make-responsive-columns(8, 8, 10, 12);
      button {
        .make-responsive-width(auto, auto, auto, 100%);
        display: block;
        margin: 20px auto 90px auto;
      }
    }
  }
}
