.product-details-view, .giftcard-view {
  // .container();
  .margin-top-small;
  .margin-bottom-small;
  @media (max-width: @screen-xs-max) {margin-top: 0;}
  display: flex;
  flex-direction: column;
  .new-wrapper {
    @media (min-width: 992px) {
      display: flex;
    }
    .new-image-wrapper, .new-info-wrapper {
      display: flex;
    }
    .new-image-wrapper {
      .make-responsive-min-width(50%, 50%, 100%, 100%);
      .make-responsive-min-height(auto, auto, 500px, 345px);
    }
  }
  .new-image-wrapper {
    flex: 1 auto;
  }
  .new-info-wrapper {
    flex-direction: column;
    flex: 1 auto;
  }

  .thumbnails {
    .clearfix();
    .hidden-xs;
    .hidden-sm;
    text-align: left;
    .single-thumbnail {
      display: inline-block;
      position: relative;
      width: auto;
      height: 200px;
      margin-top: 0!important;
      border: none!important;
      .margin-top-small;
      @media (min-width: 992px) {
        position: relative;
        width: 100px!important; /* Puede ser cualquier valor, como 300px, 50%, etc. */
        padding-bottom: calc((3 / 2) * 100%)!important; /* 3/2 representa el aspect ratio 2:3 */
        /* background-image: url('path/to/your/image.webp'); */
        background-size: cover;
        background-position: center;
        height: initial;
      }

      &:first-child {
        margin-top: 0px;
      }

      padding: 0;
      border: 1px solid @brand-gray-light;
      background-color: #fff;
      cursor: pointer;

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        // max-width: 100%;
        max-height: 100%;
        display: block;
        @media (min-width: 992px) {
          padding: 0;
          margin: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100px;
          height: 100%;
          object-fit: cover;
        }
      }

      .curations-default-image {
        float: none;
      }

      .curations-default-image {
        a {
          .selected, .hover, .selecting {
            display: none;
          }

          .unselected {
            display: inline;
          }
        }

        a:hover {
          .unselected {
            display: none;
          }

          .hover {
            display: inline;
          }
        }

        a.selecting {
          .selected, .unselected, .hover {
            display: none;
          }

          .selecting {
            display: inline;
          }
        }

        a.selected {
          .selected {
            display: inline;
          }

          .unselected, .hover {
            display: none;
          }
        }
      }
    }
  }
  .slider.multiple-items.thumbnails {
    display: flex;
    // flex: 1 auto;
    width: 97px;
  }

  .product-details-image-container {
    display: flex;
    flex-grow: 1;
    position: relative;
    // flex-direction: column;
    @media (max-width: 992px) {
      display: block;
      float: left;
      width: 100%;
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }


    .carousel-inner {
      @gutter-and-border: @grid-gutter-width + 2px;
    }

    .wishlist {
      background: url('/img/icons/heart.svg') no-repeat;
      background-origin: content-box !important;
      padding: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      position: absolute;
      z-index: 3;
      right: 2.5rem;
      top: 1rem;
      @media (max-width: @screen-xs-max) {
        right: 10rem;
      }

      &:hover {
        background: url('/img/icons/heart-hover.svg') no-repeat;
      }

      &.active {
        background: url('/img/icons/heart-active.svg') no-repeat;
      }
    }

    &:hover {
      .wishlist {
        background: url('/img/icons/heart-hover.svg') no-repeat;

        &.active {
          background: url('/img/icons/heart-active.svg') no-repeat;
        }
      }
    }
  }

  .product-details-info-container {
    margin-bottom: 2em;
    &.new-info-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
    .make-responsive-columns(6, 6, 12, 12);

    .main-info {
      .low-of-stock {
        color: @tertiary-color;
        .font-sans-serif();
      }
    }

    .brand-discount-messages-mobile {
      .make-responsive-padding-right(33.33%, 33.33%, 0, 0);

      display: flex;
      // flex-grow: 2;

      .sale-banner {
        .clearfix();
        .font-sans-serif-bold();
        .make-responsive-text-align(left, left, center, center);
        font-size: @font-size-base;
        flex-grow: 2;
        color: @new-light-gray;
        line-height: 14px;
        padding: 10px;
        background-color: #fcf5ee;

        .small {
          .clearfix();
          font-size: @font-size-small;
          color: #9b9b9b;
          line-height: 12px;
        }
      }
    }

    .promo-messages-mobile {
      .sale-banner {
        .clearfix();
        .make-responsive-line-height(14px, 14px, inherit, inherit);
        font-size: @font-size-small;
        .make-responsive-text-align(left, left, center, center);
        .font-sans-serif-bold();

        display: block;
        color: @brand-main-color;
        padding: 20px 0;
        @media (max-width: @screen-xs-max) {
          font-size: @font-size-base;
        }

        .small {
          .clearfix();
        }
      }
    }

    .special-message {
      img {
        padding: 5px 5px 0 0;
      }

      p {
        line-height: 1;
      }
    }
  }

  .main-info {
    .brand {
      clear: both;

      .brand-name {
        .font-serif;
        .font-size(@font-size-x-large; @font-size-x-large; @font-size-big-large; @font-size-big-large);
        margin-top: 0;
        .partner-shipping {
          .font-sans-serif;
          font-size: 75%;
        }
      }
    }

    .name {
      .font-sans-serif(@font-size-large);
      margin-top: 12px;
    }

    .product-price {
      .margin-top-small;
      .margin-bottom-small;

      .sale {
        font-size: 19px;
      }
    }

    .final-sale, .pre-order {
      .font-sans-serif();
      color: @tertiary-color;
      div {
        color: @brand-watermark;
        p {
          color: @brand-gray-dark;
        }
      }
    }

    .from-partner {
      .font-size(@font-size-small; @font-size-small; @font-size-x-small; @font-size-x-small);
      color: @brand-watermark;
    }

    .AfterpayMessage {
      .font-serif;
      font-size: 15px;
    }

    .AfterpayMessage-logoSvg {
      width: 85px;
      height: auto;
    }

    .payment-message {
      img {
        padding-left: 9px;
        padding-bottom: 9px;
        .make-responsive-padding-bottom(9px, 9px, 9px, 2px);
        @media (max-width: @screen-xs-max) {
          padding-top: 9px;
        }
      }

      @media (max-width: @screen-xs-max) {
        margin: 0;
      }
    }

    @media (max-width: @screen-xs-max) {
      .text-center;
      margin-top: 0;
      .brand {
        margin: 5px 0 0 0;

        .name {
          margin-top: 0;
        }
      }

      .product-price {
        margin: 10px 0 10px 0;
      }
    }
  }

  .dropdowns {
    .clearfix();
    .margin-top-small;
    .margin-bottom-small;
    @media (max-width: @screen-xs-max) {
      margin-top: 0;
    }
    .font-family-sans-serif-bold(@font-size-x-small);

    .dropdown {
      .make-responsive-columns(4, 4, 12, 12);
      padding: 0;

      p {
        margin-bottom: 0;
      }

      .uniqueColor {
        .font-family-sans-serif-bold(@font-size-x-small);
        @media (max-width: @screen-sm-max) {
          .font-family-sans-serif(@font-size-medium);
        }
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: solid 1px #b1b1b1;
        padding-left: 15px;
        height: 38px;
        text-transform: uppercase;
        line-height: 33px;
        margin-top: 5px;
      }
    }

    &.both .dropdown {
      .make-responsive-columns(4, 4, 6, 6);

      &.dropdown-size {
        .make-responsive-padding-right(7px, 7px, 3px, 3px);
        padding-left: 0;
      }

      &.dropdown-color {
        .make-responsive-padding-left(7px, 7px, 3px, 3px);
        padding-right: 0;
      }
    }

    label {
      display: none;
    }

    .quantity {
      width: 40px;
      text-align: right;
      margin-left: 8px;
      display: none;
    }

    select, input {
      .font-family-sans-serif-bold(@font-size-x-small);
      @media (max-width: @screen-sm-max) {
        .font-family-sans-serif(@font-size-medium);
      }
      height: 38px;
      .text-center;
      border: 1px solid #b1b1b1;
      .transition(background-color 0.5s);
    }

    select {
      width: 100%;
      margin-top: 5px;
      text-transform: uppercase;
      padding: 0 35px 0 15px;
      .border-radius(0);
      .text-left;
      .flaticon-solid-select-arrow;

      &::-ms-expand {
        display: none;
      }
    }

    // make dropdown expand if there is only one
    @media (max-width: @screen-xs-max) {
      &.one select {
        .make-xs-column(12);
      }
    }
  }

  .messages {
    .clearfix();
    margin-top: 5px;
    @media (max-width: @screen-xs-max) {
      margin: 0 0 10px 0;
    }

    &.back-in-stock {
      .font-sans-serif(@font-size-base);
    }

    .add-to-cart-wrapper {
      .cant-add-message {
        opacity: 0.5;
        .transition(opacity 0.2s);
        .font-sans-serif(@font-size-base);
        @media (max-width: @screen-xs-max) {
          text-align: center;
        }

        &.not-available {
          .font-sans-serif(@font-size-base);
          color: #333;
          text-transform: lowercase;
        }
      }
    }
  }

  .buttons {
    @media (min-width: @screen-md-min) {
      display: flex;
      justify-content: space-between;

      .add-to-cart-buttons {
        display: flex;
        flex-direction: column;

        width: 100%;

        .btn-add-to-cart, .btn-shop-now {
          .make-responsive-columns(8, 8, 12, 12);
        }
      }
    }
    margin-top: 0 !important;

    .clearfix();

    .promo-banners {
      display: flex;
      flex-grow: 2;
      flex-direction: column;

      @media (max-width: @screen-sm-max) {
        max-width: 65%;
        flex-grow: 2;
      }
    }

    .margin-top-small;

    .btn-shop-now {
      display: none;
    }

    .btn-add-to-cart, .btn-shop-now {

      @media (max-width: @screen-sm-max) {
        .make-responsive-columns(8, 8, 12, 12);
      }

      color: white;
      background-color: @themile-primary;
      line-height: 18px;
      padding-top: 15px;
      padding-bottom: 15px;
      .make-responsive-padding-left-right(35px, 19px, auto, auto);
      .font-sans-serif(@font-size-base);
    }
  }

  .additional-notes {
    .clearfix();
    .margin-top-small;

    & > div {
      .make-responsive-columns(8, 8, 12, 12);
      text-align: center;
      .font-sans-serif(@font-size-small);
    }

    .eta-from, .eta-to, .eta-expedited-within, .eta-method-name {
      font-weight: bold;
    }
  }

  .add-to-cart-just-failed, .subscribe-just-failed {
    .messages {
      .cant-add-message {
        visibility: visible;
        opacity: 1;
      }
    }

    &.cant-add-because-size-is-required, &.cant-add-because-variant-is-max-qty {
      .dropdowns {
        .size-select {
          border: 1px solid @error-color;
          background-color: @error-color-light;
        }
      }
    }

    &.cant-add-because-variant-out-of-stock {
      .dropdowns {
        select {
          border: 1px solid @error-color;
          background-color: @error-color-light;
        }
      }
    }
  }

  .tabs {
    .clearfix();
    .margin-top-medium;

    #tab-sizes {
      .make-responsive-overflow-x(inherit, inherit, scroll, scroll);
    }
  }

  .help {
    .clearfix();
    .margin-top-small;
    .font-sans-serif(@font-size-x-small);

    a {
      text-decoration: underline;
    }
  }

  .more-products {
    .clearfix();
    .margin-top-large;

    h3 {
      .font-serif-italic;
      color: @themile-primary-dark;
      .text-center;
    }

    @media (max-width: @screen-xs-max) {
      .brand-stitch {
        .margin-top-bottom-large;
      }
    }

    .more-products-list {
      .product-list();
      margin: 0;
      &.shop-content {
        width: calc(100% - 60px);
        margin: 0 auto;
      }

      .product-item-container {
        .make-responsive-columns(3, 3, 3, 6);
      }
    }
  }
  .trending-shows.product-detail {
    margin: 0 auto;
    width: 100%;

  }
  .the-mile-shows-carousel {
    .make-responsive-margin-top(80px, 80px, 30px, 30px);
    margin:  0 auto;
    & > h3 {
      text-align: center;
      text-transform: inherit;
      margin:  30px auto;
      .font-serif-italic;
      color: @themile-primary-dark;
    }
  }
}

.zoomContainer {
  z-index: 2;
}

.UnifiedMessage {
  .UnifiedMessage-text {
    font-family: 'OMSN',Times,serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 16px !important;
    line-height: 1.5em !important;
    color: #333 !important;
  }
}
.reviews {
  margin-top: 30px;
  .brand-stitch.center {
    margin-bottom: 15px!important;
    margin-top: 15px!important;
    @media (min-width: 1200px) {
      margin-bottom: 15px!important;
      margin-top: 15px!important;
    }
  }
  .review-btn {
    border: 1px solid @button-color;
    color: @button-color;
    background-color: transparent;
  }
  p.comment {
      margin-top: 10px;
      line-height: 1;
  }
  @media screen and (max-width:768px) {
    small, .small {
      font-size: 75%;
    }
  }
  .review-container {
    margin: 16px auto;
    background: @brand-gray-light;
    display: flex;
    justify-content: space-between;
    padding: 7px 12px;
    cursor: pointer;
    .count {
      color: @brand-main-color;
      text-decoration: underline;
    }
  }
  &.pdp-detail {
    @media (min-width: 768px) and (max-width: 991px){
      width: 100%;
    }
    @media screen and (min-width:992px) {
      width: 66.66666667%;
    }
  }
}
@media (min-width: 992px) {
  .slick-list {
    left: 0!important;
    max-height: 1000px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 10px;
      border: 2px solid transparent;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a1a1a1;
    }


    &::-webkit-scrollbar {
      display: none;
    }

    // &:hover::-webkit-scrollbar {
    //   display: block;
    // }
  }
}
