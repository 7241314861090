.style-guide-view {
  @style-guide-line-color: #f5f5f5;
  @style-guide-text-color: #999;

  .section {
    .make-row(0);
    .example {
      position: relative;
      border: 1px solid @style-guide-line-color;
      padding: 45px 15px 15px;
      margin: 0 0 30px 0;
      &:extend(.clearfix all);
      &:before {
        position: absolute;
        top: 10px;
        left: 10px;
        content: 'EXAMPLE';
        .font-sans-serif(14);
        color: @style-guide-text-color;
      }
    }
  }

  .fonts {
    td {
      vertical-align: middle;
      padding: 10px 0;
      &:first-child code{
        .font-size(12px);
      }
      &:last-child code{
        padding-left: 10px;
        .font-size(20px);
      }
    }
  }

  .less-info {
    .row {
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid @style-guide-line-color;
      &:last-child {
        border-bottom: 0;
      }
    }
    .color-square{
      width: 15px;
      height: 15px;
      display: inline;
      float: left;
      margin-right: 20px;
    }
    .font-detail, .color-detail {
      .font-family-sans-serif-light(@font-size-small);
      vertical-align: middle;
      display: inline-block;
      color: @style-guide-text-color;
    }
    .variable{
      color:#069;
    }
  }

  .product-image {
    background-color: #eff2f7;
    border: 1px solid #f5f5f5;
    width: 200px;
    img {
      width: 100%;
    }
  }

  .brand-list {
    .brand{
      border-bottom: 1px solid #DDD;
      .logo {
        color: black;
        .brand-logo {
          svg {
            width: 214px;
            height: 140px;
          }

          &.black {
            svg {
              .svg-color(#000);
            }
          }
        }
      }
      .products {
        overflow: hidden;
        max-height: 300px;
        margin-bottom: 40px;
        margin-top: 10px;
        ul {
          float:left;
          width:100%;
          padding-left:1px;
          li {
            list-style: none;
            margin: 0 14px 0 0;
            background: #fff;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .product-images-container {
    .product-image {
      float:left;
      margin-right:14px;
      width: 200px;
      img {
        width: 100%;
      }
      &.sample{
        position: relative;
        background-color: #fbfbfb;
        height:300px;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 30%;
          width: 50%;
          margin: -15% 0 0 -25%;
          text-align: center;
          .font-family-sans-serif(@font-size-small);
        }
      }
    }
  }

  .responsive-product-list {
    .product-item-container {
      .make-responsive-columns(4, 4, 4, 6);
    }
  }

  .alternative {
    background-color: #666;
  }

  .page-nav-container {
    .sidebar-rail {
      .top-offset {
        height:60px;
      }
      .bottom-offset {
        height:250px;
      }
    }
  }
}
