.admin-onboardings {
  .table-brands {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
  }

  h4 {
    font-family: 'OMBR', Arial, sans-serif;
  }

  h5 {
    font-family: 'OMBR', Arial, sans-serif;
    font-size: 18px;
  }

  .section {
    width: 100%;
    background-color: beige;
    border-top: 1px solid;
    border-bottom: 1px solid;
    margin-top: 20px;
  }

  .onboarding-input {
    font-weight: bold;
    border: 1px solid #ccc;
    font-size: 14px;
    height: 30px;
    width: 100%;
  }

  .onboarding-checkbox {
    font-weight: bold;
    border: 1px solid #ccc;
    font-size: 14px;
    margin-top: 5px;
  }

  .table-brands th, .table-brands td {
    padding: 1px 5px;
    text-align: left;
    font-family: 'OMBR', Arial, sans-serif;
    border-bottom: 1px solid #ddd;
  }

  .table-brands th {
    background-color: #8B9FA8;
    color: #FFF;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .table-brands tbody tr:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }

  .filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .filter-container input[type="text"] {
    width: 250px;
    padding: 10px;
    font-family: 'OMBR', Arial, sans-serif;
    border: 1px solid #ccc;
    font-size: 14px;
  }

  .filter-container .btn-add-new {
    padding: 10px 20px;
    color: white;
    border: none;
    font-family: 'OMBR', Arial, sans-serif;
    font-size: 14px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .table-brands th, .table-brands td {
      padding: 8px;
      font-size: 14px;
    }

    .filter-container input[type="text"] {
      width: 100%;
      margin-bottom: 10px;
    }

    .filter-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .filter-container .btn-add-new {
      width: 100%;
    }
  }
}
