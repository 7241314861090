.confirmation-modal .modal-dialog {
  margin-top:120px;
  .modal-content {
    box-shadow: none;
    overflow:auto;
    border-radius:0;
    text-align: center;
    .modal-header {
      border:0;
      padding-bottom: 0;
      .close {
        background-color: transparent;
        color:#000;
        font-size: @font-size-big-large;
        padding:0;
        float:right;
        z-index: 2;
        overflow: hidden;
        line-height: @font-size-big-large;
      }
      .modal-title {
        text-align: center;
        margin-top:0;
        padding-top:38px;
        .font-serif(@font-size-x-large);
      }
    }
    .modal-body {
      padding-top:13px;
      .font-family-sans-serif;
      b {
        .text-uppercase;
      }
      &.alert {
        max-width: 80%;
        margin: 0 auto;
      }
    }
    .modal-footer {
      padding-top: 8px;
      padding-bottom:60px;
      border:0;
      text-align:center;
      button {
        padding-top:7px !important;
        padding-bottom:7px !important;
        padding-left: 10px;
        padding-right: 10px;
        min-width: 100px;
        .font-family-sans-serif-bold(@font-size-small);
        &.cancel {
          .make-button.disabled;
          padding-left:0;
          padding-right:0;
          margin-right:22px;
        }
      }
      .deleting-feedback {
        text-align:center;
        .text-uppercase;
      }
      &.alert{
        button {
          width: 80%;
        }
      }
    }
  }
}