.password-reset-view{
  .container();
  header {
    margin-top: 50px;
    text-align: center;
    .brand-stitch {
      margin-top: 24px;
      margin-bottom: 27px;
    }
    p {
      .font-family-sans-serif-light(@font-size-small);
    }
  }
  form {
    margin-top:38px;
    button {
      display: block;
      margin: 20px auto 90px auto;
    }
  }
}
