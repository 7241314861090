@hamburger-menu-width: 100vw;
@hamburger-menu-width-outer: 100vw;
@hamburger-animation-duration: .2s;
@hamburger-animation-function: cubic-bezier(0.4, 0, 1, 1);
hamburger {
  .visible-xs;
  .visible-sm;
}

.hamburger-nav {
  .make-xs-column(2);
  .make-sm-column(1);
  .visible-xs;
  .visible-sm;
  .make-responsive-navbar-vertical-align(16px);
  z-index: 3;
  padding-left: 0;
  &:focus{
    .lines {
      background-color: @brand-main-color;
      &:before { background-color: @brand-main-color; }
      &:after { background-color: @brand-main-color; }
    }
  }
  //line mixin
  .line() {
    display: inline-block;
    width: 20px;
    height: 3px;
    background: #000;
    border-radius: 1px;
    transition: @hamburger-animation-duration;
  }

  .lines {
    //create middle line
    .line();
    position: relative;
    top:-3px;

    /*create the upper and lower lines as pseudo-elements of the middle line*/
    &:before, &:after {
      .line();
      position: absolute;
      left: 0;
      content: '';
    }
    &:before { top: 5px; }
    &:after { top: -5px; }
  }
}

.hamburger-content {
  .hidden-md;
  .hidden-lg;
  header {
    background-color: white;
    .container;
    .make-responsive-width(290px,290px, 290px, 290px);
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    .hamburger-close, .search-box-mobile-hamburguer{
      .make-xs-column(2);
      .make-sm-column(2);
      text-decoration: none;
      display: inline-block;
    }
    .search-box-mobile-hamburguer{
      .flaticon-stroke-zoom-2;
      font-size:@font-size-medium-large;
      padding-top: 10px;
      padding-left: 0px;
    }
    .hamburger-close {
      .flaticon-solid-x;
      padding-right: 0px;
      padding-top: 10px;
      font-size: @font-size-medium;
    }
    .hamburguer-logo{
      .make-xs-column(8);
      .make-sm-column(8);
      padding-bottom: 10px;
    }
  }
  display: none;
  position:fixed;
  top: 0px;
  left: 0;
  bottom: 0;
  width:@hamburger-menu-width;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  color:#000;
  z-index: 100;
  background: #fafafa;
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);

  .translate3d(-@hamburger-menu-width-outer; 0; 0);
  .transition-transform(@hamburger-animation-duration @hamburger-animation-function);

  ul  {
    li {
      .font-sans-serif(@font-size-base);
      a {
        width: 95%;
        &.flaticon-stroke-right-1 {
          border-bottom: 1px solid @brand-gray;
          border-top: 1px solid @brand-gray;
          margin-top: -1px;
        }
        &.brand-main-color {
          color: @brand-main-color;
        }
        &.brand-gray-dark {
          color: white;
        }
      }
      &.option-sign {
        a {
          font-weight: 400;
          border-bottom: none;
          padding: 10px 30px;
          background-color: @brand-main-color;
          margin-top: 15px;
          margin-bottom: 15px;
          width: -moz-max-content;
          width: -webkit-max-content;
          width: max-content;
          &:hover {
            color: white;
            background-color: @error-color;
          }
        }
      }
    }
  }


  ul {
    list-style-type: none;
    margin:0 0 auto 0;
    padding:0;
    li {
      padding: 0;
      &>a {
        &:focus{
          color: @brand-main-color;
        }
        display: block;
        padding: 12px;
        text-transform: uppercase;
        text-decoration: none;
        color:#000;
        margin:0;
        cursor: pointer;
      }
      &:first-child {
        margin-top:0;
      }
      ul{>li{padding:0;}}
    }
  }
  .levels {
    padding-top: 20px;
    padding-left:15px;
    width:@hamburger-menu-width*4;
    .transition-transform(@hamburger-animation-duration @hamburger-animation-function);
    &>.ng-scope {
      width:100%;
      height:100%;
    }
    ul.hamburger-menu {
      vertical-align: top;
      display:inline-block;
      width:@hamburger-menu-width;
      margin:0 !important;
      padding:0 !important;
      overflow:auto;
      li.level-back a {
        .font-sans-serif-bold(@font-size-x-small);
        text-transform: uppercase;
        position:relative;
        padding-left: 25px;
        .back-icon {
          position:absolute;
          left:10px;
          .flaticon-solid-left;
        }
      }
      li.option-all a {
        color: @brand-main-color;
      }
      li.option-all.simple a {
        color: black;
      }
      li.option-space {
        height: @font-size-small;
      }
    }
    &.level-1 {
      .translate3d(-@hamburger-menu-width-outer; 0; 0);
    }
    &.level-2 {
      .translate3d(-@hamburger-menu-width-outer*2; 0; 0);
    }
  }

  .stitch {
    .make-stitch;
  }
  .sign-in-directive, .forgot-password-directive {
    padding:0;
    form {
      padding:0;
      width:@hamburger-menu-width*.88;
      .form-group {
        margin-top:10px;
      }
    }
  }
  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: lighter;
    margin: 0 0 0 0;
    background-color: transparent;
    color: gray;
    border: none;
    font-size: 25px;
    &:before {
      font-family: 'Flaticons Solid';
      speak: none;
      font-style: normal;
      font-weight: lighter;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      content: "\e1bb";
    }
  }
}

@media (max-width: @screen-sm-max)  {
  &.hamburger-menu-visible {
    .hamburger-content {
      .translate3d(0; 0; 0);
    }
    .hamburger-overlay {
      opacity: 0.3;
    }
  }
  .hamburger-menu-display {
    .hamburger-content {
      display: block;
    }
    .hamburger-overlay {
      display: block;
    }
  }
}

.hamburger-overlay {
  background-color: black;
  opacity: 0;
  position: fixed;
  display:none;
  left: 0;
  top: 0px;
  right: 0;
  bottom: 0;
  z-index: 90;
  cursor: pointer;
  .transition(opacity @hamburger-animation-duration);
}
