.make-select() {
  text-transform: uppercase;
  padding: 1px 5px 1px 5px;
  width: 35px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: left;
  background: url(/styles/flaticons/solid/png/16/down-2.png);
  background-repeat: no-repeat;
  background-color: white;
  background-position: 91% center;
  text-indent: 0.01px;
  text-overflow: '';
  border: 1px solid #e6e6e6;
  border-radius: 0;
  &:hover{
    background: url(/styles/flaticons/solid/png/16/down-2.png);
    background-repeat: no-repeat;
    background-color: white;
    background-position: 91% center;
    opacity:0.6;
  }
  &::-ms-expand {
    display: none;
  }
}
