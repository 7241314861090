.homepage-carousel {
  .make-responsive-margin-top(100px, 100px, 30px, 30px);

  & > h3 {
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
  }

  .make-responsive-width(80%, 70%, 45%, 70%);
  margin: 0 auto;
  @product-height-md: 300px;

  &.loading {
    opacity: .3;
  }

  .product-list-item {
    margin-top: @grid-gutter-width*1;
    margin-bottom: @grid-gutter-width*1;
    margin-left: 15px;
    margin-right: 15px;

    .product-info {
      .product-brand {
        .font-serif(@font-size-medium-large);
      }

      & > * {
        color: @new-light-gray;
      }

      .currency-symbol {
        font-size: @font-size-base;
      }
    }

    a:hover, a:focus {
      .product-info {
        .product-brand {
          color: @brand-main-color;
        }
        color: @brand-main-color;
      }
    }

    .product-image {
      position: relative;
      background-color: #fff;
      border: 1px solid #f5f5f5;
      .make-responsive-height(386px, 386px, 507px, 363px);

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
        background-color: #fff;
      }
    }
  }

  &.no-product-info {
    .product-info {
      display: none;
    }
  }

  .product-info {
    height: @product-height-md / 4;
    text-align: center;
    .font-sans-serif(@font-size-base);
    color: #737373;

    a {
      text-decoration: none;
    }

    span {
      display: block;
      text-align: center;
      .truncate();
    }

    .product-brand {
      margin-top: 10px;
      .font-serif(@font-size-medium-large);
      color: #000;
    }

    .product-price.small {
      font-size: @font-size-base;
      margin: 4px 0;
      font-weight: 600;

      span {
        display: inline;
      }
    }

    @media (max-width: @screen-xs-max) {
      text-align: center;
    }
  }

  @animation-function: cubic-bezier(0.4, 0, 1, 1);
  @animation-duration: 0.3s;

  .product-list-item {
    &.child-style {
      position: relative;
      background: white;
      .transition(all @animation-duration @animation-function);
      .translate3d(0; 0; 0);
      left: 0;
      top: 0;
      opacity: 1;

      &.appearing {
        .translate3d(-100px; 0; 0);
        opacity: 0;
      }
    }

    .product-info {
      position: relative;

      .more-colors {
        .font-sans-serif(@font-size-x-small);
        letter-spacing: .1em;
        text-transform: uppercase;
        text-align: left;
        z-index: 2;

        a {
          display: block;
          width: 100%;
          height: 100%;
        }

        span {
          padding: 2px 0;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .product-item-container {
    position: relative;

    .more-colors a span:after {
      content: ' ❯';
    }
  }

  .styles-expanded {
    .more-colors a span:after {
      content: ' ❮';
    }
  }

  .admin-product-list-params {
    .font-sans-serif(@font-size-base);
    position: absolute;
    top: -20px;
    text-overflow: ellipsis;
    color: #aaa;
    @media (max-width: @screen-sm-max) {
      display: none;
    }

    .product-list-param-values {
      display: none;
    }

    &:after {
      content: '?'
    }

    &:hover {
      .product-list-param-values {
        display: inline-block;
      }

      &:after {
        content: ''
      }
    }

    .product-list-param-value:hover {
      color: black;
    }
  }

  #prevArrow, #nextArrow {
    color: @brand-gray;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    text-shadow: none;
    width: 15px;
    height: 15px;
    .make-responsive-font-size(57px, 57px, 40px, 40px);
    opacity: .7;
    text-align: center;
  
    &::before {
      border-top: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      position: absolute;
      width: 100%;
      left: 0px;
    }
  
    &::after {
      border-left: 3px solid;
      content: "";
      display: block;
      border-radius: 5px;
      height: 100%;
      width: 0px;
      position: absolute;
      top: 0px;
    }
  
    &:hover {
      color: #b8b8b8;
      opacity: .9;
    }
  }

  #prevArrow {
    right: auto;
    left: -60px;
    -webkit-transform: rotate(-45deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(-45deg); /* IE 9 */
    transform: rotate(-45deg); /* Firefox 16+, IE 10+, Opera */
    @media (max-width: @screen-xs-max) {
      left: -15px;
    }
  }

  #nextArrow {
    left: auto;
    right: -60px;
    -webkit-transform: rotate(135deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(135deg); /* IE 9 */
    transform: rotate(135deg); /* Firefox 16+, IE 10+, Opera */
    @media (max-width: @screen-xs-max) {
      right: -15px;
    }
  }

  .buttons {
    text-align: center;

    .button {
      &.see-more-link {
        width: 100%;
        max-width: 400px;
        color: white;
        background-color: @themile-primary;
        line-height: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-family: 'OMBR', Arial, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        display: inline-block;
        // padding: 0 15px;
        // margin: 25px 0;
        // border: 2px solid @new-light-gray;
        // text-transform: uppercase;
        // line-height: 43px;
        // letter-spacing: 1px;
        .font-sans-serif;
        .make-transition-effect;

        &:hover {
          background-color: @new-light-gray;
          color: white;
        }
      }
    }
  }
}
