.cart-page-view {
  .cart {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .item-list {
      overflow-y: scroll;
      bottom: auto;
      top: auto;
      // bottom: 153px;
      // top: 100px;
      @media (min-width: 470px) {
        width: 440px;
      }
      @media (max-width: 767px) {
        width: 96%;
      }
      @media (max-width: 469px) {
        margin-right: 0;
      }
      .item .image-placeholder {
        cursor: default;
        @media (min-width: 470px) {
          width: 130px;
          height: 195px;
        }
      }
    }
    .modal-header {
      display: block;
      margin-top: 10px;
      h4 {
        font-size: @font-size-x-large;
        line-height: @line-height-large;
        @media (max-width: 469px) {
          font-size: @font-size-medium-large;
        }
      }
      h5 {
        font-size: @font-size-x-small;
      }
    }
    .modal-footer {
      height: auto;
      bottom: 30px;
      padding-top: 0;
      margin-bottom: 15px;
      .btn-checkout {
        font-size: @font-size-small;
        line-height: inherit;
        min-height: auto;
      }
    }
  }
  ol.item-list {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .modal-footer {
    hr {
      margin-top: 0;
    }
  }
}