.checkout-v2-view {
  .summary {

    .top-sold-out-warning {
      .margin-top-bottom-medium;
      .make-responsive-columns(10, 10, 10, 12);
      .make-responsive-columns-offset(1, 1, 1, 0);
      .make-responsive-padding(30px, 30px, 15px, 15px);
      text-align: center;
      border: solid 1px #f1f1f1;
      a {
        color: @error-color;
        cursor: pointer;
        .font-family-sans-serif-bold(@font-size-small);
      }
    }
    .make-md-column(4);
    .make-xs-column(12);
    border: 1px solid #f1f1f1;
    margin-bottom: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    .font-sans-serif(@font-size-small);

    .items {
      width: 100%;
      float: left;
      &.bottom {
        margin-bottom: 0;
      }
      .item {
        list-style: none;
        clear: both;
        &:last-child {
          .image-placeholder {
            margin-bottom: 0;
          }
        }
        .image-placeholder {
          .make-xs-column(3);
          margin-bottom: 10px;
          padding-left: 0;
          padding-right: 0;
          img {
            border: 1px solid #f5f5f5;
            max-width: 100%;
          }
        }
        .product-data {
          .make-xs-column(9);
          padding-right: 0;
          .font-sans-serif(@font-size-small);
          overflow: hidden;
          .brand {
            .font-sans-serif-bold(@font-size-small);
            float: left;
          }
          .remove {
            float: right;
            line-height: 1.2;
            button {
              .font-sans-serif(@font-size-x-small);
              color: #999999;
              text-decoration:underline;
              background-color: transparent;
              border:0;
              padding:0;
              margin:0;
            }
          }
          .final-sale, .pre-order {
            .font-family-sans-serif(@font-size-small);
            color: @tertiary-color;
          }
          .name {
            clear: both;
            .text-truncate-lines(3);
            line-height: 1.3em;
          }
          .sold-out-warning {
            color: @error-color;
            .sold-out-warning-icon {
              display: inline-block;
              .flaticon-solid-exclamation-sign();
              color: @error-color;
              font-size: 14px;
              padding-left: 4px;
              vertical-align: bottom;
            }
          }
          .disabled {
            color: #999999 !important;
          }
          select {
            .make-select;
          }
          table {
            width: 100%;
            margin-top: 5px;
            margin-bottom: 15px;
            .font-sans-serif(@font-size-small);
            line-height: 1.3em;
            tr td:first-child {
              width: 30%;
              .font-sans-serif(@font-size-small)
            }
            td {
              padding: 2px 0;
              vertical-align: sub;
              word-break: break-word;
            }
          }
        }
      }
    }

    .totals {
      width: 100%;
      float: left;
      table {
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        td {
          &:nth-child(odd) {
            .text-left;
            word-break: break-all;
            width: 75%;
          }
          &:nth-child(even) {
            .text-right;
            width: 25%;
          }
        }
        tr.title {
          text-transform: none;
        }
        tr.total td {
          .padding-top-small;
          .font-sans-serif-bold(@font-size-small);
        }
      }
    }

    .totals-top {
      .totals {
        margin-bottom: 15px;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 15px;
      }
    }

    .totals-bottom {
      .totals {
        margin-top: 15px;
        border-top: 1px solid #f1f1f1;
        padding-top: 15px;
      }
    }
  }
}
