.checkout-base-view {
  .cart-empty {
    text-align: center;
    margin-top: @grid-gutter-width * 4;
    h2 {
      .font-family-serif(@font-size-x-large);
      font-style: italic;
    }
    a {
      margin-top: @grid-gutter-width;
    }
  }
}
