.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: baseline;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  //-webkit-filter: blur(10px);
  //-moz-filter: blur(10px);
  //-o-filter: blur(10px);
  //-ms-filter: blur(10px);
  //filter: blur(10px);
  opacity: .9;
  z-index: 24;
  background-color: white;

  #loading-spinner {
    width: 50px;
    height: 10px;
    overflow: hidden;
    background-color:@brand-main-color;
    margin: auto;
  }

  .loading-spinner {
    background-color: white;
    margin-top: 0;
    margin-left: -50px;
    width: 50px;
    height: 10px;
    animation-name: bounce_loadingProgressG;
    -o-animation-name: bounce_loadingProgressG;
    -ms-animation-name: bounce_loadingProgressG;
    -webkit-animation-name: bounce_loadingProgressG;
    -moz-animation-name: bounce_loadingProgressG;
    animation-duration: 1.5s;
    -o-animation-duration: 1.5s;
    -ms-animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
  }

  @keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-o-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-ms-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-webkit-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }

  @-moz-keyframes bounce_loadingProgressG {
    0% {
      margin-left: -50px;
    }

    100% {
      margin-left: 50px;
    }
  }
}
