.shipping-and-returns {
  .margin-bottom-large;

  &.use-content-width {
    .container;
    .page-content {
      .make-md-column(10);
      .make-md-column-offset(1);
    }
  }

  .brand-stitch {
    margin-top: 24px;
    margin-bottom: 27px;
  }

  .return-policy-per-brand-table {
    tr {

      border-bottom: #b8b8b8 solid 1px;

      td:first-child {
        .font-serif-bold();
        .font-size(20px, 20px, 18px, 18px);
        .make-responsive-width(30%, 30%, 100%, 100%);
        .make-responsive-vertical-align(top, top, middle, middle);
      }

      td {
        .make-responsive-display(table-cell, table-cell, block, block);
        .make-responsive-line-height(24px, 24px, 20px, 20px);
        .make-responsive-width(70%, 70%, 100%, 100%);
        .font-text-align-xs-center;
        .font-text-align-sm-center;
        .font-size(14px, 14px, 12px, 12px);
        padding: 30px 0 30px 0;
      }

      td:last-child {
        .make-responsive-display(inherit, inherit, none, none);
        vertical-align: top;
        padding: 0;
      }

      td.detail {
        .make-responsive-display(table-cell, table-cell, contents, contents);
        div {
          .make-responsive-margin-bottom(0, 0, 25px, 25px);
        }
      }
    }

    #partner-orchard-mile {
      display: none;
    }

  }

  a {
    margin: 20px 0 0 0;
  }

  h1, h2, h3, h4, h5, h6 {
    .text-center;
  }

  h1 {
    .font-size(50px, 50px, 32px, 32px);
  }

  h2 {
    .font-size(30px, 30px, 24px, 24px);
  }

  h3 {
    .font-size(20px, 20px, 18px, 18px);
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }

  ul, ol {
    -webkit-padding-start: 20px;
  }
}
