.shop-view .hero-image-panel.with-assets.awakened-alchemy {
  .brand-logo.white svg {
    background-color: #000000b5;
    padding: 5px;
  }
}

.shop-view .hero-image-panel.with-assets.oscar-de-la-renta .brand-logo svg,
.shop-view .hero-image-panel.with-assets.janessa-leone .brand-logo svg,
.shop-view .hero-image-panel.with-assets.sea .brand-logo svg,
.shop-view .hero-image-panel.with-assets.vince .brand-logo svg,
.shop-view .hero-image-panel.with-assets.dolce-and-gabbana-kids .brand-logo svg,
.shop-view .hero-image-panel.with-assets.lapo-lounge .brand-logo svg,
.shop-view .hero-image-panel.with-assets.jupe .brand-logo svg {
  background-color: transparent;
}
.shop-view .hero-image-panel.with-assets.valentino-garavani .brand-logo {
  display: none;
}